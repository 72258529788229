import React from 'react';
import { Container, Col } from 'reactstrap';
import { If, Then, Else } from 'react-if';
import { useSelector } from 'react-redux';
import RebatesEmpty from './RebatesEmpty';
import RebatesLoading from './RebatesLoading';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from 'reactstrap';

const RebatesDesktop = (props) => {
  const { rebateList, getRebates, rebateMap, allRebateMap, getAllRebates, hasMore,
    searchRebate, manufacturerChecked, categoryChecked, hasMoreAllRebates, rebateLoadStatus, allRebateLoadStatus,
    tryReloadingAllRebates } = props;

  const viewType = useSelector(({ rebate }) => rebate.rebateViewType);


  const rebateBody =
    <InfiniteScroll
      initialLoad={rebateMap.length === 0}
      pageStart={0}
      loadMore={getRebates}
      hasMore={hasMore}
    >
      {rebateMap}
    </InfiniteScroll>

  const allRebateBodyHeight = window.screen.height - 225;

  const allRebateBody = <InfiniteScroll
    initialLoad={allRebateMap.length === 0}
    pageStart={0}
    height={allRebateBodyHeight}
    loadMore={() => getAllRebates(manufacturerChecked, categoryChecked, searchRebate)}
    hasMore={hasMoreAllRebates}
  >
    {allRebateMap}
  </InfiniteScroll>


  return (

    <Container fluid className='p-0 m-0' style={{ overflowY: 'hidden' }}>
      <If condition={viewType === 'yourRebates'}>
        <Then>
          <If condition={rebateLoadStatus === 'loading'}>
            <Col style={{ position: 'relative', top: '0px' }} className="px-0" > <RebatesLoading /></Col>
          </If>
          <If condition={rebateLoadStatus === 'complete' && rebateList.length !== 0}>
            {rebateBody}
          </If>
          <If condition={rebateLoadStatus === 'complete' && rebateList.length === 0} >
            <Col style={{ top: '0px' }} xs={12}>
              <RebatesEmpty reason={"No results found"} />
            </Col>
          </If>
          <If condition={rebateLoadStatus === 'failed'}>
            <Col style={{ top: '0px' }} xs={12}>
              <RebatesEmpty reason={"Couldn't load rebates, try refreshing"} />
            </Col>
          </If>
        </Then>
        <Else>
          {allRebateLoadStatus === 'failed' ?
            <Col style={{ top: '0px' }} xs={12}>
              <RebatesEmpty reason={"Couldn't load rebates, try refreshing"} />
            </Col>
            :
            allRebateBody
          }
          {allRebateLoadStatus === 'loading' ?  <div className='d-flex justify-content-center align-items-center p-4'><Spinner /></div> :
            allRebateLoadStatus === 'failed-to-load-more' ?
              <Col className="clickable" onClick={tryReloadingAllRebates} xs={12}>
                <RebatesEmpty reason={"Click to try again"} />
              </Col> : false}
        </Else>
      </If>
      <If condition={viewType==='yourRebates'}>
        <Col onClick={getRebates} className='mt-3 main-header text-center mt-4' style={{cursor: 'pointer'}} >Load More</Col>
      </If>
    </Container>

  );
};

export default RebatesDesktop;
