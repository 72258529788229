import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import RebateChecksList from './RebateChecksList';
import RebateCheckDetails from './RebateCheckDetails';
const RebateChecks = () => {

  return (
      <Switch>
        <Route exact path='/paymentinformation/list' component={RebateChecksList} />
        <Route exact path='/paymentinformation/:merchantid/:merchant' component={RebateCheckDetails} />

        <Route>
          <Redirect to='/paymentinformation/list' />
        </Route>
      </Switch>
  );
}

export default RebateChecks;