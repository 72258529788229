import {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import matchApi from '../../../../../api/matchApi';
import sortArrayByKey from '../../../../../sharedComponents/Helper/sortArrayByKey';
import { setDetailSortBy, setItemMatches, setFilteredAndSortedItemMatches, itemMatchesData, itemMatchesLoading } from '../../../../../reducers/IntelReducer/IntelActions';
import stringify from 'csv-stringify';
import fileDownload from 'js-file-download';
import numeral from 'numeral';
import {  useParams } from 'react-router-dom';

const useApi = () => {
  const itemMatchesForAllItems = useSelector(({intel}) => intel.itemMatchesForAllItems);
  const sortState = useSelector(({ intel }) => intel.detailOptions.competitiveInsightItemMatchesSort);
  const itemMatches = useSelector(({intel}) => intel.itemMatches);
  const dispatch = useDispatch();
  const fromDate = useSelector(({ intel }) => intel.fromDate);
  const toDate = useSelector(({ intel }) => intel.toDate);
  const [search, setSearch] = useState('');

  const getItemMatches = (min, platformId, merchantId) => {

    // if the matches are already shown for this item, hide them.
    // otherwise, fetch the matches and display them.
    const savedMatch = itemMatchesForAllItems.find((m) => m.min === min && m.manufacturerPlatformId === platformId);
    if (!savedMatch) {
      // make it no longer visible
      // Cupid can't search without both a min and a platform ID. Show no matches in this case.
      if (min === "" || platformId === "") {
        dispatch(itemMatchesData(min, platformId, []));
        return;
      }
      dispatch(itemMatchesLoading('loading'));

      matchApi.post({
        min,
        manufacturerPlatformId: platformId,
        merchantPlatformId: merchantId,
        startDate: fromDate,
        endDate: toDate
      }).then((res) => {
        let filteredAndSorted = processData(res.data);
        dispatch(setFilteredAndSortedItemMatches(filteredAndSorted));
        dispatch(setItemMatches(res.data))
        dispatch(itemMatchesData(min, platformId, res.data));
        dispatch(itemMatchesLoading('complete'));
      })
        .catch(err => {
          dispatch(itemMatchesLoading('failed'));
          console.error(err);
        });
    } else {
      let filteredAndSorted = processData(savedMatch.matches);
      dispatch(setItemMatches(savedMatch.matches));
      dispatch(setFilteredAndSortedItemMatches(filteredAndSorted));
      dispatch(itemMatchesLoading('complete'));
    }
  };


  const sortBy = (key) => {
    let order = key === sortState.sortBy ? !sortState.sortDesc : true;
    order = ((key === 'description' && key !== sortState.sortBy) || (key === 'manufacturerName' && key !== sortState.sortBy)) ? !order : order;
    dispatch(setDetailSortBy('competitiveInsightItemMatchesSort', key, order));
  };

  const processData = (itemMatches) => {
    let filtered = itemMatches.filter(item => {
      let min = item.min ? item.min.toLowerCase() : '';
      let description = item.description ? item.description.toLowerCase() : '';
      let manufacturer = item.manufacturer ? item.manufacturer.toLowerCase() : '';
      return min.includes(search.toLowerCase()) || description.includes(search.toLowerCase()) ||
        manufacturer.includes(search.toLowerCase());
    });
    
    let sorted = sortArrayByKey(
      filtered,
      sortState.sortBy,
      sortState.sortDesc
    );
    return sorted;
  }

  useEffect(() => {
    let filteredAndSortedMatches = processData(itemMatches);
    dispatch(setFilteredAndSortedItemMatches(filteredAndSortedMatches));
    // eslint-disable-next-line
  }, [sortState, search])

  return {
    sortBy,
    sortState,
    getItemMatches,
    setSearch,
    search
  }
}

const useCsvExport = () => {
  let { min } = useParams();
  let originalMin = min;
  const itemMatchesList = useSelector(({intel}) => intel.filteredAndSortedItemMatches);
  let prepareItemsList = (data) => {
    let csvData = [];
    csvData.push(['originalMin', 'min', 'description', 'manufacturer', 'pack', 'rebateType',
      'rebate', 'deviation', 'uom']);

    data.forEach(({ min, description, manufacturerName, pack,
      rebateType, rebateWithSymbol, deviationDollar, uom }) => {
      csvData.push([
        originalMin, min, description, manufacturerName, pack, rebateType,
        numeral(rebateWithSymbol).format('0,0.00'), numeral(deviationDollar).format('0,0.00'), uom
      ])
    });
    return csvData;
  }

  const exportCsv = (data, fileName) => {
    let preCsvArray = prepareItemsList(data);
    if (preCsvArray.length > 0) {
      stringify(preCsvArray, (err, output) => {
        fileDownload(output, fileName);
      });
    }
  }

  const exportItemMatchesList = () => {
    let data = itemMatchesList;
    if (data.length > 0) {
      exportCsv(data, 'ItemMatches - For internal use only.csv');
    }
  }
  return {
    exportItemMatchesList
  }
}

export default {
  useCsvExport,
  useApi
}