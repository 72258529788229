import Axios from "axios";
const url = '/dashboard';
const CancelToken = Axios.CancelToken;
let cancelDashboardApi;
const dashboardApi = {
  post: (params) => {
    return Axios.post(`${url}`, params, {
      cancelToken: new CancelToken(function executor(c) {
        cancelDashboardApi = c;
      })
    });
  }
}

export { cancelDashboardApi, dashboardApi }
