import React from 'react'
import { Button, Card, CardBody } from 'reactstrap';
import useNotification from '../useNotification/useNotification';

export default function Notification(props) {
  let { buttonLeftClick, buttonRightClick, buttonLeftText, buttonRightText, id } = props;
  let { remove } = useNotification(id);
  const onLeftClick = () => {
    if (buttonLeftClick) {
      buttonLeftClick();
    }
    remove();
  };

  const onRightClick = () => {
    if (buttonRightClick) {
      buttonRightClick();
    }
    remove();
  };
  return (
    <div style={{ right: '20', width: '350px', zIndex: '1200' }} className='text-size-14 op-slide-left mt-1'>
      <Card className="shadow border-2 bg-grayBg border-beplBlue">
        <CardBody className="p-2">
          <div className='font-weight-bold bg-white p-2 rounded'>{props.body}</div>
          <div className='d-flex w-full justify-content-end align-items-center mt-2'>
            <Button onClick={onLeftClick} className="text-size-14 mr-2 bg-beplBlue">{buttonLeftText}</Button>
            <Button onClick={onRightClick} className="text-size-14 bg-beplBlue">{buttonRightText}</Button>
          </div>
        </CardBody>
      </Card>
    </div>
  )

}
