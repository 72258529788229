import Axios from "axios";

const url = '/distributor';

const distributorApi = {
  get: () => {
    return Axios.get(`${url}`);
  },
  getAll: () => {
    return Axios.get(`${url}/all`);
  },
  getParents: () => {
    return Axios.get(`${url}/allParents`)
  },
  getChildren: (parent_id) => {
    return Axios.get(`${url}/children/${parent_id}`)
  },
  getUsersOfFamily: (platformId) => {
    return Axios.get(`${url}/users-of-family?platformId=${platformId}`)
  },
  getUsersOfChildDistributor: (platformId) => {
    return Axios.get(`${url}/users-of-child?platformId=${platformId}`)
  },
  houses: (userId) => {
    return Axios.get(`${url}?userId=${userId}`);
  }
};

export default distributorApi;