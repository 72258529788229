import React, { useState } from 'react';
import { Row, Col, CardBody, Tooltip } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { BsCheckCircleFill } from 'react-icons/bs';
import { MdOutlineError } from 'react-icons/md';
import { BsFillTrashFill } from 'react-icons/bs';
import { FaDownload } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import { If, Then, Else } from 'react-if'
import { showModal } from '../../../../reducers/ModalsReducer/ModalsActions';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import useInvoice from '../useInvoice';

const UploadedInvoiceRow = ({ description, merchant_name, createdAt, status, attachment_grouping_id, deleteUploadedInvoice }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const showDeleteConfirmation = () => {
    dispatch(showModal(DeleteConfirmationModal, { deleteInvoice: { invoiceId: attachment_grouping_id, deleteUploadedInvoice } }));
  }
  const pushToInvoiceView = () => {
    history.push('/invoice/' + attachment_grouping_id);
  }
  const { fetchAndExportInvoiceItemsList } = useInvoice.useCsvExport();

  const [tooltipOpen, setTooltipOpen] = useState({
    viewTooltip: false,
    downloadTooltip: false,
    deleteTooltip: false
  });
  const toggle = (type) => setTooltipOpen({ ...tooltipOpen, [type]: !tooltipOpen[type] });


  if (isMobile) {
    return (
      <CardBody className='border-bottom p-3'>
        <Row className='pb-3'>
          <Col className='font-weight-bold text-capitalize'>
            {description}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Location</Col>
          <Col className='text-right'>
            {merchant_name}
          </Col>
        </Row>

        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Upload Date</Col>
          <Col className='text-right'>
            {moment(createdAt).format("MMM Do YY")}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Status</Col>
          <Col className='text-right'>
            {status}
          </Col>
        </Row>


        <Col className={'px-0 pt-3 see-details-label text-right text-info clickable d-flex justify-content-end align-items-center'}>
          <AiFillEye onClick={pushToInvoiceView} className='mr-5' size={28} color="grey" />
          {status.toLowerCase() === 'done' && <FaDownload onClick={() => fetchAndExportInvoiceItemsList(attachment_grouping_id)} className='mr-5' size={21} color="grey" />}
          <BsFillTrashFill onClick={showDeleteConfirmation} className='' size={23} color="grey" />

        </Col>
      </CardBody>
    )
  } else {
    return (
      <CardBody className='border-top py-2 px-3 hover-row'>
        <Row className='align-items-center'>
          <Col xs={3}>
            {description}
          </Col>
          <Col xs={3}>
            {merchant_name}
          </Col>
          <Col xs={2}>
            {moment(createdAt).format("MMM Do YYYY")}
          </Col>
          <Col className="d-flex align-items-center" xs={2}>
            <If condition={status.toLowerCase() === 'done'}>
              <Then><BsCheckCircleFill style={{ marginLeft: '2px' }} className='mr-1' size={16} color="green" /></Then>
              <Else><MdOutlineError className='mr-1' size={19} color={`${status.toLowerCase() === 'problem' ? "#DD3444" : "#FFC007"}`} /></Else>
            </If>
            {status}
          </Col>

          <Col className={'see-details-label text-right text-info clickable d-flex justify-content-end align-items-center'}>
            <div id="tooltip-view-icon" className='invoice-action-btn-wrap'>
              <AiFillEye onClick={pushToInvoiceView} className='mr-4 invoice-action-btn' size={22} color="grey" />
              <Tooltip
                placement={'top'}
                isOpen={tooltipOpen.viewTooltip}
                target={'tooltip-view-icon'}
                toggle={() => toggle('viewTooltip')}>
                {'View'}
              </Tooltip>
            </div>
            {status.toLowerCase() === 'done' &&
              <div id="tooltip-download-icon" className='invoice-action-btn-wrap'>
                <FaDownload onClick={() => fetchAndExportInvoiceItemsList(attachment_grouping_id)} className='mr-4 invoice-action-btn' size={17} color="grey" />
                <Tooltip
                  placement={'top'}
                  isOpen={tooltipOpen.downloadTooltip}
                  target={'tooltip-download-icon'}
                  toggle={() => toggle('downloadTooltip')}>
                  {'Download'}
                </Tooltip>
              </div>}
            <div id="tooltip-delete-icon" className='invoice-action-btn-wrap'>
              <BsFillTrashFill onClick={showDeleteConfirmation} className='invoice-action-btn' size={17} color="grey"/>
              <Tooltip
                  placement={'top'}
                  isOpen={tooltipOpen.deleteTooltip}
                  target={'tooltip-delete-icon'}
                  toggle={() => toggle('deleteTooltip')}>
                  {'Delete'}
                </Tooltip>
            </div>
          </Col>
          <If condition={status.toLowerCase() === 'done'}>
            {/* <Then>
              <Col className={'see-details-label text-right text-info clickable'}>
                <VscDebugRestart className="mr-1" size={17} color={'#13A3B8'} />
                Reupload
              </Col>
            </Then> */}

          </If>

        </Row>
      </CardBody>
    );
  }

}

export default UploadedInvoiceRow;