import React, { Fragment } from 'react';
import { CardBody, Row, Col, Button } from 'reactstrap';
import { If, Then, Else } from 'react-if';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import stringify from 'csv-stringify';
import fileDownload from 'js-file-download';

const IntelDetailDistributorHeader = ({ sortState, sortBy, distributorVolume, distributorVolumePerc, distributorData, merchant }) => {
  const activeDistributor = useSelector(({ user }) => user.activeDistributorApply);
  const distributorShortName = activeDistributor ? activeDistributor.nickname.split(' ').slice(0, 1).join(' ') : '';
  const isInternalAdmin = useSelector(({ user }) => user.isInternalAdmin);
  const intelLoaded = useSelector(({ intel }) => intel.allLoaded);

  // Export the current view in CSV format and download it.
  // This functionality should only be available to admin accounts.
  const onExport = () => {
    const csvData = [];
    let fileName = '';

    if (!distributorData || distributorData.length === 0) {
      return;
    }

    // Header Row
    csvData.push([
      'Description',
      'Manufacturer',
      'Packsize',
      'Price',
      'Quantity',
      'Last Order Price',
      'Rebate',
      'Rebate Type',
      'Deviation',
      'UOM'
    ]);

    distributorData.forEach(e => {
      csvData.push([
        e.description, e.manufacturer, e.packsize,
        numeral(e.rebateValue).format('0,0.00'),
        e.rebateType, e.deviationValue, e.quantity, e.uom
      ]);
    });

    fileName = `${merchant.merchant} distributor - For internal use only.csv`;

    // Prepare the CSV data
    if (csvData.length > 0) {
      stringify(csvData, (err, output) => {
        fileDownload(output, fileName);
      });
    }
  }

  if (isMobile) {
    return (
      <CardBody className='py-2'>
        <Row className='p-3 text-size-18 label-text line-height-21'>
          <Col className='text-center'>
            {distributorShortName} Purchases
          </Col>
        </Row>
        <Row>
          <Col xs={3} className='border-right'>
            {numeral(distributorVolumePerc).format('0%')}
          </Col>
          <Col>
            {numeral(distributorVolume).format('$0,0.00')}
          </Col>
        </Row>
      </CardBody>
    );
  }
  return (
    <Fragment>
      <CardBody className='py-2 bg-white'>
        <Row className='text-size-16 py-2'>
          <Col>
            <Row className='main-header text-size-20 px-2'>
              {distributorShortName} Purchases
            </Row>
            <Row className='text-size-16 px-2'>
              Volume: {numeral(distributorVolume).format('$0,0.00')} | Total Volume: {numeral(distributorVolumePerc).format('0%')}
            </Row>
          </Col>
          <If condition={isInternalAdmin}>
            <Col className='w-fc'>
              <Button disabled={!intelLoaded} className='py-2 button-label' size='sm' onClick={onExport} color='beplMagenta' style={{ padding: '8px 20px' }}>Export Page Details</Button>
            </Col>
          </If>
        </Row>
      </CardBody>
      <CardBody className='py-2 border-top bg-white position-sticky' style={{ top: '0px', zIndex: 50 }}>
        <Row className='text-muted text-size-10 line-height-11 text-uppercase align-items-center'>
          <Col xs={2}>
            Description
          </Col>
          <Col xs={2}>
            Manufacturer
          </Col>
          <Col xs={1}>
            Packsize
          </Col>
          <Col className={'clickable' + (sortState.sortBy === 'quantity' ? ' text-primary' : '')} onClick={() => sortBy('quantity')} xs={1}>
            Price
            <If condition={sortState.sortBy === 'quantity' && !sortState.sortDesc}>
              <Then>
                <IoMdArrowRoundUp className='ml-2' />
              </Then>
              <Else>
                <IoMdArrowRoundDown className='ml-2' />
              </Else>
            </If>
          </Col>
          <Col className={'clickable' + (sortState.sortBy === 'quantity' ? ' text-primary' : '')} onClick={() => sortBy('quantity')} xs={1}>
            Quantity
            <If condition={sortState.sortBy === 'quantity' && !sortState.sortDesc}>
              <Then>
                <IoMdArrowRoundUp className='ml-2' />
              </Then>
              <Else>
                <IoMdArrowRoundDown className='ml-2' />
              </Else>
            </If>
          </Col>
          <Col className={'clickable' + (sortState.sortBy === 'purchase' ? ' text-primary' : '')} onClick={() => sortBy('purchase')} style={{ 'whiteSpace': 'nowrap' }} xs={1}>
            Last Order Price
            <If condition={sortState.sortBy === 'purchase' && !sortState.sortDesc}>
              <Then>
                <IoMdArrowRoundUp className='ml-2' />
              </Then>
              <Else>
                <IoMdArrowRoundDown className='ml-2' />
              </Else>
            </If>
          </Col>
          <Col className={'clickable' + (sortState.sortBy === 'rebateValue' ? ' text-primary' : '')} onClick={() => sortBy('rebateValue')} xs={1}>
            Rebate
            <If condition={sortState.sortBy === 'rebateValue' && !sortState.sortDesc}>
              <Then>
                <IoMdArrowRoundUp className='ml-2' />
              </Then>
              <Else>
                <IoMdArrowRoundDown className='ml-2' />
              </Else>
            </If>
          </Col>
          <Col className={'clickable' + (sortState.sortBy === 'rebateType' ? ' text-primary' : '')} onClick={() => sortBy('rebateType')} xs={1}>
            Rebate Type
            <If condition={sortState.sortBy === 'rebateType' && !sortState.sortDesc}>
              <Then>
                <IoMdArrowRoundUp className='ml-2' />
              </Then>
              <Else>
                <IoMdArrowRoundDown className='ml-2' />
              </Else>
            </If>
          </Col>
          <Col className={'clickable' + (sortState.sortBy === 'deviationValue' ? ' text-primary' : '')} onClick={() => sortBy('deviationValue')} xs={1}>
            Deviation
            <If condition={sortState.sortBy === 'deviationValue' && !sortState.sortDesc}>
              <Then>
                <IoMdArrowRoundUp className='ml-2' />
              </Then>
              <Else>
                <IoMdArrowRoundDown className='ml-2' />
              </Else>
            </If>
          </Col>
          <Col className={'clickable' + (sortState.sortBy === 'uom' ? ' text-primary' : '')} onClick={() => sortBy('uom')} xs={1}>
            UOM
            <If condition={sortState.sortBy === 'uom' && !sortState.sortDesc}>
              <Then>
                <IoMdArrowRoundUp className='ml-2' />
              </Then>
              <Else>
                <IoMdArrowRoundDown className='ml-2' />
              </Else>
            </If>
          </Col>
        </Row>
      </CardBody>
    </Fragment>
  );
};

export default IntelDetailDistributorHeader;