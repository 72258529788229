import axios from 'axios'
import { showModal, hideModal } from '../../reducers/ModalsReducer/ModalsActions';
import { showNotification, removeNotification } from '../../reducers/NotificationsReducer/NotificationsActions';
import Notification from '../Notifications/Notification';
import AlertModal from '../AlertModal/AlertModal';
import { isMobile } from 'react-device-detect';

export default (dispatch) => {
  let reloadPage = () => {
    window.location.reload();
  }
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
  );
  let env = process.env.REACT_APP_ENVIRONMENT;
  let url = env === 'development' ? 'https://dev.distributor.gpoportal.com/' :
    env === 'production' ? 'https://distributor.gpoportal.com/' : 'https://staging.distributor.gpoportal.com/';

  if (!isLocalhost) {
    setInterval(() => {
      axios.get(url + 'version.txt', { transformResponse: (res) => res })
        .then(data => {
          let updatedVersion = data.data.toString();
          let currentVersion = document.querySelector('meta[name="version"]').content.toString();

          if (updatedVersion !== currentVersion) {
            if (isMobile) {
              dispatch(showModal(AlertModal, {
                title: 'Update',
                showTwoButtons: true,
                buttonLeftText: "Close",
                type: 'ui-update',
                buttonLeftClick: hideModal,
                buttonRightClick: reloadPage,
                buttonRightText: "Update",
                message: 'New version of app is available. An update is recommended (This will cause your browser to refresh your tab).'
              }));
            } else {
              dispatch(showNotification(Notification, {
                buttonLeftText: 'Close',
                buttonRightText: 'Update',
                type: 'ui-update',
                buttonLeftClick: removeNotification,
                buttonRightClick: reloadPage,
                body: 'New version of app is available. An update is recommended (This will cause your browser to refresh your tab).',
              }))
            }
          }
        }).catch(err => {

          console.log('Not able to fetch new version hash of app', err)
        })

    }, 1000 * 60 * 5)
  }
}