import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { If, Then, Else } from 'react-if';
import useDashboard from './useDashboard';
import useDistributor from '../../../sharedComponents/useDistributor/useDistributor';
import Announcements from './Subcomponents/Announcements';
import DashboardLoading from './Subcomponents/DashboardLoading';
import AnimatedLoadingPrompt from '../../../sharedComponents/AnimatedLoadingPrompt/AnimatedLoadingPrompt';
import DashboardHeader from './Subcomponents/DashboardHeader';
import DashboardEmpty from './Subcomponents/DashboardEmpty';
import DashboardOverallStats from './Subcomponents/DashboardOverallStats';
import DashboardVolumeOverview from './Subcomponents/DashboardVolumeOverview';
import DashboardMonthlyVolume from './Subcomponents/DashboardMonthlyVolume';
import DashboardTopChart from './Subcomponents/DashboardTopChart';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { updateLostItems, setVolumeChartPeriod } from '../../../reducers/DashboardReducer/DashboardActions';
import Select from 'react-select';
import { selectStyles } from '../../../config/constants';
import { MdMultilineChart } from 'react-icons/md';
import { colors } from '../../../config/constants';

const OVERALL_HEADER = "Overall Stats";
const VOLUME_HEADER = "Volume Overview";
const LOST_CATEGORIES_HEADER = "Top Categories Lost to Competitors";
const LOST_ITEMS_HEADER = "Top Items Lost to Competitors";
const VIEW_CHART = "View Pie Chart";
const VIEW_DETAILS = "View Details";
const LOG_TOGGLE = "Switch to Logarithmic View";
const LIN_TOGGLE = "Switch to Linear View";

const Dashboard = () => {

  useEffect(()=> {
    window.scrollTo({
      top: 0,
    });
  },[]);
  
  const {
    fromDate, setDateRange, toDate, onApplySearch
  } = useDashboard.useDates();
  const {exportItemsLostToComps, exportCategoriesLostToComps} = useDashboard.useCsvExport();

  const {
    selectActiveDistributor, 
    activeDistributor,
    distributorsList
  } = useDistributor();

  const totalVolume = useSelector(({dashboard}) => dashboard.totalVolume);
  const competitorVolume = useSelector(({dashboard}) => dashboard.competitorVolume);
  const volumeByMonth = useSelector(({dashboard}) => dashboard.volumeByMonth);
  const volumeLoading = useSelector(({dashboard}) => dashboard.volumeLoading);
  const volumeChartPeriod = useSelector(({dashboard}) => dashboard.volumeChartPeriod);
  const volumeLoadFailed = useSelector(({dashboard}) => dashboard.volumeLoadFailed);
  const totalRebates = useSelector(({dashboard}) => dashboard.totalRebates);
  const annualizedRebates = useSelector(({dashboard}) => dashboard.annualizedRebates);
  const lostItemCategories = useSelector(({dashboard}) => dashboard.lostItemCategories);
  const lostItemDescriptions = useSelector(({dashboard}) => dashboard.lostItemDescriptions);
  const isLoading = useSelector(({dashboard}) => dashboard.isLoading);
  const loadComplete = useSelector(({dashboard}) => dashboard.loadComplete);
  const loadFailed = useSelector(({dashboard}) => dashboard.loadFailed);
  const sortBy = useSelector(({dashboard}) => dashboard.sortBy);
  const isInternalAdmin = useSelector(({user}) => user.isInternalAdmin);
  const merchants = useSelector(({intel}) => intel.merchantsList);
  const selectedMerchant = useSelector(({dashboard}) => dashboard.selectedMerchant);
  const userId = useSelector(({user}) => user.id);
  const userList = useSelector(({user}) => user.userRol);
  const impersonate = useSelector(({user}) => user.impersonateAs);
  const volumeByMerchant = useSelector(({dashboard}) => dashboard.volumeByMerchant);

  const dispatch = useDispatch();

  const [viewOverallStatsChart, setViewOverallStatsChart] = useState(false);
  const [logarithmic, setLogarithmic] = useState(true);

  const onSortBy = (sortType) => {

    switch(sortType) {
      case 'volume':
        lostItemDescriptions.sort((a, b) => {
          return b.volume - a.volume;
        });
        break;
      case 'rebate':
        lostItemDescriptions.sort((a, b) => {
          return b.rebate - a.rebate;
        });
        break;
      // case 'deviation':
      //   lostItemDescriptions.sort((a, b) => {
      //     return b.deviation - a.deviation;
      //   });
      //   break;
      default:
        break;
    }

    dispatch(updateLostItems(lostItemDescriptions, sortType));
  }

  // for Volume Overview dropdown
  const chartRanges = [
    {
      label: '3 months',
      value: 3
    },
    {
      label: '6 months',
      value: 6
    },
    {
      label: '9 months',
      value: 9
    },
    {
      label: '12 months',
      value: 12
    }
  ];

  const onChangeChartPeriod = (period) => {
    dispatch(setVolumeChartPeriod(period));
  }

  const onOverallViewToggle = () => {
    setViewOverallStatsChart(!viewOverallStatsChart);
  }

  const onLogarithmicToggle = () => {
    setLogarithmic(!logarithmic);
  }

  if(isMobile) {
    return (
      <Container fluid className='bg-white' style={{minHeight: '100vh'}}>
        <Announcements />
        <DashboardHeader 
          fromDate={fromDate} 
          toDate={toDate} 
          setDateRange={setDateRange}
          onApplySearch={onApplySearch} 
          selectActiveDistributor={selectActiveDistributor}
          activeDistributor={activeDistributor}
          distributorsList={distributorsList}
          loadComplete={loadComplete}
          merchants={merchants}
          selectedMerchant={selectedMerchant}
          userId={userId}
          userList={userList}
          impersonate={impersonate}
          volumeByMerchant={volumeByMerchant}
        />
        <If condition={isLoading}>
          <Then>
            <AnimatedLoadingPrompt />
          </Then>
          <Else>
          {loadComplete && loadFailed ? <DashboardEmpty /> :
          <>
            <Row className='label-text text-size-14 d-flex align-items-center border-bottom'>
              <Col className='py-3 w-50'>
                <Row>
                  <Col>
                    <div className='main-header text-size-20'>{OVERALL_HEADER}</div>
                  </Col>
                  <Col className={'see-details-label w-fc text-right text-beplMagenta clickable'} onClick={onOverallViewToggle}>
                    { viewOverallStatsChart ? VIEW_DETAILS : VIEW_CHART }
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              { viewOverallStatsChart ? 
                <DashboardVolumeOverview
                  totalVolume={totalVolume} 
                  competitorVolume={competitorVolume}
                />
                :
                <DashboardOverallStats
                  totalVolume={totalVolume}
                  competitorVolume={competitorVolume}
                  totalRebates={totalRebates}
                  annualizedRebates={annualizedRebates}
                /> 
              }
            </Row>
            <Row className='label-text text-size-14 d-flex align-items-center border-bottom'>
              <Col className='py-3 w-50'>
                <Row>
                  <Col>
                    <div className='main-header text-size-20 pb-3'>{VOLUME_HEADER}</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col className='w-fc px-3'>
                        <MdMultilineChart 
                          className='clickable'
                          size={38}
                          color={logarithmic ? colors.beplMagenta : colors.beplDarkBlue}
                          title={logarithmic ? LIN_TOGGLE : LOG_TOGGLE}
                          onClick={onLogarithmicToggle}
                        />
                      </Col>
                      <Col>
                        <Select
                          options={chartRanges}
                          onChange={onChangeChartPeriod}
                          styles={selectStyles}
                          value={volumeChartPeriod}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <DashboardMonthlyVolume 
                volumeByMonth={volumeByMonth} 
                volumeLoading={volumeLoading}
                volumeChartPeriod={volumeChartPeriod}
                volumeLoadFailed={volumeLoadFailed}
                logarithmic={logarithmic}
              />
            </Row>
            <Row className='label-text text-size-14 d-flex align-items-center border-bottom'>
              <Col className='py-3 w-50'>
                <div className='main-header text-size-20'>{LOST_CATEGORIES_HEADER}</div>
              </Col>
            </Row>
            <Row>
              <DashboardTopChart data={lostItemCategories} />
            </Row>
            <Row className='label-text text-size-14 border-bottom'>
              <Row className='py-3'>
                <Col className='mx-3 main-header text-size-20'>
                  {LOST_ITEMS_HEADER}
                </Col>
              </Row>
              <Row className='mx-3'>
                <Col 
                  className={'mt-auto mb-0 pb-3 text-center text-size-10 text-uppercase clickable ' + (sortBy === 'volume' ? 'sort-active-line sort-active-text' : '')} 
                  onClick={()=> onSortBy('volume')}
                >
                  Volume
                </Col>
                <Col 
                  className={'mt-auto mb-0 pb-3 text-center text-size-10 text-uppercase clickable ' + (sortBy === 'rebate' ? 'sort-active-line sort-active-text' : '')} 
                  onClick={()=> onSortBy('rebate')}
                >
                  Rebates
                </Col>
                {/* <Col 
                  className={'mt-auto mb-0 pb-3 text-center text-size-10 text-uppercase clickable ' + (sortBy === 'deviation' ? 'sort-active-line sort-active-text' : '')} 
                  onClick={()=> onSortBy('deviation')}
                >
                  Deviations
                </Col> */}
              </Row>
            </Row>
            <Row>
              <DashboardTopChart data={lostItemDescriptions} sortBy={sortBy} />
            </Row>
          </>}
          </Else>
        </If>
      </Container>
    );
  } else {
    return (
      <Container fluid style={{minHeight: '100vh'}} className='bg-white px-4'>
        <Announcements />
        <DashboardHeader 
          fromDate={fromDate} 
          toDate={toDate} 
          setDateRange={setDateRange}
          onApplySearch={onApplySearch} 
          selectActiveDistributor={selectActiveDistributor}
          activeDistributor={activeDistributor}
          distributorsList={distributorsList}
          loadComplete={loadComplete}
          merchants={merchants}
          selectedMerchant={selectedMerchant}
          userId={userId}
          userList={userList}
          impersonate={impersonate}
          volumeByMerchant={volumeByMerchant}
        />
        <If condition={isLoading}>
          <Then>
            <DashboardLoading />
          </Then>
          <Else>
          {loadComplete && loadFailed ? <DashboardEmpty /> :
          <>
            <Row className='label-text text-size-14 d-flex align-items-center border-bottom'>
              <Col className='py-3 w-50'>
                <Row>
                  <Col>
                    <div className='main-header text-size-20'>{OVERALL_HEADER}</div>
                  </Col>
                  <Col className={'see-details-label w-fc text-right text-beplMagenta clickable'} onClick={onOverallViewToggle}>
                    { viewOverallStatsChart ? VIEW_DETAILS : VIEW_CHART }
                  </Col>
                </Row>
              </Col>
              <Col className='py-3 w-50 border-left'>
                <Row>
                  <Col>
                    <div className='main-header text-size-20'>{VOLUME_HEADER}</div>
                  </Col>
                  <Col xs={4}>
                    <Row>
                      <Col className='w-fc p-0'>
                        <MdMultilineChart 
                          className='clickable'
                          size={38}
                          color={logarithmic ? colors.beplMagenta : colors.beplDarkBlue}
                          title={logarithmic ? LIN_TOGGLE : LOG_TOGGLE}
                          onClick={onLogarithmicToggle}
                        />
                      </Col>
                      <Col>
                        <Select
                          options={chartRanges}
                          onChange={onChangeChartPeriod}
                          styles={selectStyles}
                          value={volumeChartPeriod}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              { viewOverallStatsChart ? 
                <DashboardVolumeOverview
                  totalVolume={totalVolume} 
                  competitorVolume={competitorVolume}
                />
                :
                <DashboardOverallStats
                  totalVolume={totalVolume}
                  competitorVolume={competitorVolume}
                  totalRebates={totalRebates}
                  annualizedRebates={annualizedRebates}
                /> 
              }
              <DashboardMonthlyVolume 
                volumeByMonth={volumeByMonth} 
                volumeLoading={volumeLoading}
                volumeChartPeriod={volumeChartPeriod}
                volumeLoadFailed={volumeLoadFailed}
                logarithmic={logarithmic}
              />
            </Row>
            <Row className='label-text text-size-14 d-flex align-items-center border-bottom'>
              <Col className='py-3 w-50 d-flex align-items-center'>
                <div className='main-header text-size-20'>{LOST_CATEGORIES_HEADER}</div>
                <If condition={isInternalAdmin}>
                  <Col>
                    <Button onClick={exportCategoriesLostToComps} className='py-2 button-label ml-3' size='sm' color='beplMagenta'>Export</Button>
                  </Col>
                </If>
              </Col>
              <Col className='w-50 border-left'>
                <Row>
                  <Col className='py-3 main-header text-size-20 d-flex align-items-center' xs={7}>
                    {LOST_ITEMS_HEADER}
                    <If condition={isInternalAdmin}>
                      <Col>
                        <Button onClick={exportItemsLostToComps} className='py-2 button-label ml-3' size='sm' color='beplMagenta'>Export</Button>
                      </Col>
                    </If>
                  </Col>
                  <Col 
                    className={'mt-auto mb-0 pb-3 text-center text-size-10 text-uppercase clickable ' + (sortBy === 'volume' ? 'sort-active-line sort-active-text' : '')} 
                    onClick={()=> onSortBy('volume')}
                  >
                    Volume
                  </Col>
                  <Col 
                    className={'mt-auto mb-0 pb-3 text-center text-size-10 text-uppercase clickable ' + (sortBy === 'rebate' ? 'sort-active-line sort-active-text' : '')} 
                    onClick={()=> onSortBy('rebate')}
                  >
                    Rebates
                  </Col>
                  {/* <Col 
                    className={'mt-auto mb-0 pb-3 text-center text-size-10 text-uppercase clickable ' + (sortBy === 'deviation' ? 'sort-active-line sort-active-text' : '')} 
                    onClick={()=> onSortBy('deviation')}
                  >
                    Deviations
                  </Col> */}
                </Row>
              </Col>
            </Row>
            <Row>
              <DashboardTopChart data={lostItemCategories} leftSide={true} />
              <DashboardTopChart data={lostItemDescriptions} sortBy={sortBy}/>
            </Row>
          </>}
          </Else>
        </If>
      </Container>
    );
  }
}

export default Dashboard;