import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { isMobile } from 'react-device-detect';


const TableRow = ({ listItem, actions }) => {
  if (!listItem) {
    return <></>
  }
  const keys = Object.keys(listItem);
  let actionKeys;

  if (actions && Object.keys(actions).length > 0) {
    actionKeys = Object.keys(actions)
  } else if (listItem.actions) {
    actionKeys = Object.keys(listItem.actions);
    actions = listItem.actions ? listItem.actions : false;
  } else {
    actionKeys = false;
    actions = false;
  }

  const desktopRow =
    <CardBody className='py-2 px-3 hover-row rounded-0 border-top' style={{ minHeight: 'auto' }}>
      <Row className='d-flex justify-content-between'>
        {keys.map((key, ind) => {
          if (key === 'data' || key === 'actions' || key === 'index') {
            return false;
          }
          return (
            <Col key={ind} xs={listItem[key].size} className='text-size-14'>
              {listItem[key].label}
            </Col>
          )
        })}

        {listItem.flag ?
          <Col xs={1} className='m-0 p-0 d-flex justify-content-end pr-4'>
            {listItem.flag}
          </Col>
          : false}

        {actions ? <Col xs={1} className='m-0 p-0 d-flex justify-content-end pr-4'>
          {actionKeys.map((action, ind) => {
            return <div key={ind}>{actions[action](listItem)}</div>
          })}
        </Col> : false}
      </Row>
    </CardBody>

  const mobileRow =
    <CardBody className={`border-bottom p-3 ${listItem.index === 0 ? 'border-top' : '' }`}>
      {keys.map((key, ind) => {
        if (key === 'data') {
          return false;
        }
        return (
          <Row key={ind} className='align-items-center py-2'>
            <Col className='w-fc text-muted'>{listItem[key].label}</Col>
            <Col className='text-right'>
              {listItem[key].value}
            </Col>
          </Row>
        )
      })}

      {actions ? 
      <div xs={1} className='m-0 p-0 d-flex justify-content-end w-100'>
        {actionKeys.map((action, ind) => {
          return <div key={ind}>{actions[action](listItem)}</div>
        })}
      </div> : false}
    </CardBody>;

  if (isMobile) {
    return mobileRow;
  } else {
    return desktopRow;
  }
}

export default TableRow;