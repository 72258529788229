import userApi from "../../api/userApi";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { spinner } from "../../reducers/UIReducer/UIActions";
import { useReducer } from "react";
import useLogin from "../../sharedComponents/useLogin/useLogin";
import { useHistory } from "react-router-dom";
import { setInternalAdmin } from "../../reducers/UserReducer/UserActions";

const reducer = (state, action) => {
  switch(action.type) {
    case 'SET_USER_LIST':
      return {
        ...state,
        users: action.payload.map(u => {
          return {
            label: `${u.contactName} ${u.email}`,
            value: {
              ...u,
              isAdmin: false
            }
          };
        })
      }
    case 'SELECT_OPTION':
      return {
        ...state,
        selectedUser: action.payload
      }
    default:
      return state;
  }
};

const useAdminLogin = () => {
  const [{users, selectedUser}, set] = useReducer(reducer, {users: [], selectedUser: null});
  const dispatch = useDispatch();
  const history = useHistory();
  const { login } = useLogin();

  useEffect(() => {
    dispatch(spinner.add());
    userApi.get().then(({data}) => {
      dispatch(spinner.subtract());
      set({type: 'SET_USER_LIST', payload: data});
    });
    // eslint-disable-next-line
  }, []);

  const onChange = (opt) => {
    set({type: 'SELECT_OPTION', payload: opt})
  };

  const onLogin = (e) => {
    e.preventDefault();
    dispatch(setInternalAdmin(true));
    login({...selectedUser.value, internalAdmin: true});
  };

  const onLogout = () => {
    history.push('/logout');
  };

  return {
    selectProps: {
      onChange,
      value: selectedUser,
      options: users
    },
    onLogin,
    onLogout
  };
};

export default useAdminLogin;