import React, {useState, useEffect} from 'react';
import { Row, Col, CustomInput } from 'reactstrap';
import { isMobile } from 'react-device-detect';

const CheckableList = ({title, list, itemsChecked, ...props}) => {
  const [listHeight, setListHeight] = useState('0px');
  
  useEffect(() => {
    let height = window.innerHeight;
    setListHeight(height - 387 +'px')
  }, []);
  
  const listMap = list.map(o => {
    return (
      <Row key={`${title}_${o.value}`} className='mr-0 pt-3 clickable'>
        <Col>
          <CustomInput id={`${title}_${o.value}`} label={o.label} type='checkbox' checked={itemsChecked.some(cc => cc.value === o.value)} onChange={()=>props.checkItem(o)} />
        </Col>
      </Row>
    )
  });

  return (
     <Col className={`px-0 overflow-auto border text-size-14 line-height-16 bg-${props.color}`}>
        <Col className='pr-0' style={isMobile ? {height: '160px'} : { height: listHeight }}>
          {listMap}
        </Col>
      </Col>
  )
}

export default CheckableList;
