import React from "react";
import { useHistory } from "react-router-dom";

const BreadCrumbs = ({ links }) => {
  let history = useHistory();
  return (
    <div className="d-flex flex-wrap">
      {
        links.map((link, ind) => {
          return (
            <div key={ind+link} className="clickable d-flex font-weight-bold" >
              <div className={`${links.length - 1 === ind ? 'text-beplMagenta' : ''}`} onClick={() => (ind < links.length - 1) ? history.push(link.path) : ''}>{link.label}</div>
              {links.length > 1 && ind!== links.length-1 ? <div className={`text-sm px-1 `}>{` > `}</div> : false}
            </div>
          )
        })
      }
    </div>
  )

}

export default BreadCrumbs;