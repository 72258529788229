const init = {
  spinner: {
    state: 0,
    showGraphic: true
  },
  burgerMenu: false,
  compInsightLastViewedUrl: '/competitiveinsights/merchants',
  intelLastViewedUrl: '/intel/list',
  menuExpanded: false
}
export default function reducer(state = init, action) {
  switch (action.type) {
    case 'ADD_SPINNER':
      return {
        ...state,
        spinner: {
          state: state.spinner.state + 1,
          showGraphic: action.payload
        }
      };
    case 'SUBTRACT_SPINNER':
      return {
        ...state,
        spinner: {
          state: state.spinner.state > 0 ? state.spinner.state - 1 : 0,
          showGraphic: true
        }
      };
    case 'TOGGLE_BURGER_MENU':
      return {
        ...state,
        burgerMenu: state.burgerMenu ? false : true
      }
    case 'SET_LAST_VIEWED_COMP_INSIGHT_URL':
      return {
        ...state,
        compInsightLastViewedUrl: action.payload
      }
    case 'SET_LAST_VIEWED_INTEL_URL':
      return {
        ...state,
        intelLastViewedUrl: action.payload
      }
    case 'SET_MENU_EXPANDED':
      return {
        ...state,
        menuExpanded: action.payload
      };
    case 'CLEAR_ALL_UI_DATA':
      return init;
    default:
      return state;
  };
};