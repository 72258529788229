import { getQuarter } from "../../sharedComponents/Helper/calcWhatFiscalQtrAndYrForGivenAmountOfQtrsToLookBack";
const date = new Date()
const yearAbbreviation = date.getFullYear().toString().slice(2, 4)
const month = date.getMonth();

const init = {
  rebateChecksSort: {
    sortBy: 'merchant',
    sortDesc: false
  },
  rebateChecksFilters: {
    paymentrouting: 'all',
  },
  check: false,
  timeframe: yearAbbreviation + 'Q' + getQuarter(month),
  checkLoading: null,
  checkLoadStatus: null,
  merchants: [],
  merchantsWithChecks: [],
  merchantsLoading: null,
  merchantsLoadStatus: null,
  search: ''
}
export default function reducer(state = init, action) {
  switch (action.type) {
    case 'SET_MERCHANTS_WITH_CHECKS':
      return {
        ...state,
        merchantsWithChecks: action.payload
      }
    case 'SET_MERCHANTS':
      return {
        ...state,
        merchants: action.payload
      }
    case 'SET_REBATE_CHECKS_SORT':
      return {
        ...state,
        rebateChecksSort: {
          sortBy: action.payload.sortBy,
          sortDesc: action.payload.order
        }
      }
    case 'SET_ALL_CHECKS':
      return {
        ...state,
        allChecks: action.payload
      }
    case 'SET_CHECK':
      return {
        ...state,
        check: action.payload
      }
    case 'SET_TIMEFRAME':
      return {
        ...state,
        timeframe: action.payload
      }
    case 'SET_CHECK_LOADING':
      return {
        ...state,
        checkLoading: action.payload.loading,
        checkLoadStatus: action.payload.status ? action.payload.status : null
      }
    case 'SET_MERCHANTS_LOADING':
      return {
        ...state,
        merchantsLoading: action.payload.loading,
        merchantsLoadStatus: action.payload.status ? action.payload.status : null
      }
    case 'SET_SEARCH':
      return {
        ...state,
        search: action.payload
      }
    case 'SET_REBATE_CHECKS_FILTERS':
      return {
        ...state,
        rebateChecksFilters: {
          [action.payload.type]: action.payload.value
        }
      }
    case 'CLEAR_REBATE_CHECKS_DATA':
      return init
    default:
      return state;
  }
};