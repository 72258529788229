import Axios from "axios";

const url = '/test';

const testApi = {
  dash: {
    speeds: (params) => {
      return Axios.get(`${url}/dash/load-speeds`, {params});
    }
  } 
};

export default testApi;