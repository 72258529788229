import { useEffect, useRef } from "react";
import analyticsApi from "../../api/analyticsApi";

const useTrackUserSession = () => {
  const timeAwayBeforeExpire = 60000;
  const userLoggedIn = useRef(false);
  const setUserLoggedIn = (bool) => {
    if(userLoggedIn.current!==null) {
      userLoggedIn.current = bool
    }
  }
  let interval = useRef(null);

  const setUpSession = () => {
    if(userLoggedIn.current === false) {
      // console.log('session set up')
      localStorage.setItem("userSessionStartTimeForTracking", Date.now());
      interval.current = setInterval(() => {
        checkForInactivity();
      },5000);
      setUserLoggedIn(true);
      document.addEventListener("click", updateExpireTime);
      document.addEventListener("keypress", updateExpireTime);
      document.addEventListener("scroll", updateExpireTime);
      document.removeEventListener("mousemove", updateExpireTime);
      document.addEventListener("mousemove", updateExpireTime);
      document.addEventListener('beforeunload', () => {
        setUserLoggedIn(false);
        sendBackTrackingTime();
      })
    }
    updateExpireTime();
  }

  const removeSession = () => {
    setUserLoggedIn(false);
    document.removeEventListener("click", updateExpireTime);
    document.removeEventListener("keypress", updateExpireTime);
    document.removeEventListener("scroll", updateExpireTime);
    sendBackTrackingTime();
    localStorage.removeItem("userSessionStartTimeForTracking");
    localStorage.removeItem("userSessionExpireTimeForTracking");
    clearInterval(interval.current);
  }

  const updateExpireTime = (e) => {
    const updateExpireTs = Date.now() + timeAwayBeforeExpire;
    localStorage.setItem("userSessionExpireTimeForTracking", updateExpireTs);
    if(userLoggedIn.current === false) {
      setUpSession();
    }
  }

  const checkForInactivity = () => {
    const expireTs = localStorage.getItem('userSessionExpireTimeForTracking');
    if (expireTs < Date.now()) {
      removeSession();
    }
  }

  const sendBackTrackingTime = () => {
    let expireTime = localStorage.getItem("userSessionExpireTimeForTracking");
    let endTime = expireTime ? Number(expireTime) - timeAwayBeforeExpire : 0;
    let startTime = localStorage.getItem("userSessionStartTimeForTracking");
    startTime = startTime ? Number(startTime) : 0;
    analyticsApi.userSessions.post({startTime, endTime, time: endTime - startTime});
    // console.log('sent session to server');
  }


  useEffect(() => {
    setUpSession();
    document.addEventListener("visibilitychange", (event) => {
      if (document.visibilityState === "visible" && !userLoggedIn.current) {
        setUpSession();
      }
    });

    return () => removeSession();
  // eslint-disable-next-line
  },[])

  return {
  }
}

export default useTrackUserSession
