import { useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setDateRange as setIntelDateRange, applySearch, setOrderHistoryCalendarDate,
  setCompetitiveInsightCategories, setCompetitiveInsightItems, clearCompetitiveOrderHistoryPurchases, clearOrderHistoryMonths } from '../../../reducers/IntelReducer/IntelActions';
import { cancelDashboardApi, dashboardApi } from '../../../api/dashboardApi';
import userApi from '../../../api/userApi';
import merchantApi from '../../../api/merchantApi';
import { clearDashboardData, dataLoading, setDashboardData, dashboardLoadFailed, volumeDataLoading, setVolumeData, volumeDataLoadFailed, setVolumeByMerchant, clearVolumeByMerchant, selectMerchant } from '../../../reducers/DashboardReducer/DashboardActions';
import { showModal } from '../../../reducers/ModalsReducer/ModalsActions';
import { setUserData } from '../../../reducers/UserReducer/UserActions';
import AlertModal from '../../../sharedComponents/AlertModal/AlertModal';
import stringify from 'csv-stringify';
import fileDownload from 'js-file-download';
import numeral from 'numeral';
import logger from '../../../sharedComponents/Logger/LogglyLogger'
import { spinner } from "../../../reducers/UIReducer/UIActions";
import { setMerchants } from '../../../reducers/IntelReducer/IntelActions';

const useDates = () => {
  const dispatch = useDispatch();
  const fromDate = useSelector(({ intel }) => intel.fromDate);
  const toDate = useSelector(({ intel }) => intel.toDate);
  const activeDistributor = useSelector(({ user }) => user.activeDistributor);
  const activeDistributorApply = useSelector(({ user }) => user.activeDistributorApply);

  const onApplySearch = () => {
    //console.log(`active: ${activeDistributor.id} activeApply: ${activeDistributorApply.id}`);
    if (activeDistributor.id !== activeDistributorApply.id) {
      // Change the user's active distributor
      userApi.activeDistributor(activeDistributor.id).post().then(({ data }) => {
        dispatch(applySearch());
        const { channelPartner } = data;
        dispatch(setUserData({ channelPartner }));
      }).catch(err => {
        console.error(err);
      });
    } else {
      dispatch(applySearch());
    }
  };

  // Reload dashboard when the distributor is changed (multi house users only)
  useEffect(() => {
    // activeDistributorApply is null on initial load
    if (activeDistributorApply) {
      onApplySearch();
    }

  // eslint-disable-next-line
  }, [activeDistributor]);

  
  const setDateRange = (d) => {
    cancelDashboardApi();
    dispatch(clearCompetitiveOrderHistoryPurchases());
    dispatch(clearOrderHistoryMonths());
    dispatch(setOrderHistoryCalendarDate(d.fromDate));
    dispatch(setIntelDateRange(d))
  }
  
  return {
    setDateRange,
    toDate,
    fromDate,
    onApplySearch
  };
}

const useDashboardStats = () => {
  const fromDate = useSelector(({ intel }) => intel.fromDateApply);
  const toDate = useSelector(({ intel }) => intel.toDateApply);
  const selectedMerchant = useSelector(({ dashboard }) => dashboard.selectedMerchant);
  const dispatch = useDispatch();
  const activeDistributorApply = useSelector(({ user }) => user.activeDistributorApply);
  const merchantsLoaded = useSelector(({intel}) => intel.merchantsLoaded);
  const impersonateAs = useSelector(({user}) => user.impersonateAs);
  const impersonateMerchants = useSelector(({user}) => user.impersonateMerchants);
  const merchants = useSelector(({intel}) => intel.merchantsList);

  useEffect(() => {
    if (impersonateAs) {
      // Load merchant data for the impersonated user and save to state
      dispatch(spinner.add());
      merchantApi.available.get(impersonateAs).then(({data}) => {
        dispatch(spinner.subtract());
        dispatch(setMerchants(data.locations));
      }).catch(err => {
        dispatch(spinner.subtract());
        console.error(err);
      });
    }
    else {
      // No user impersonated; reset merchant data to the default user
      if (merchantsLoaded) {
        dispatch(setMerchants(impersonateMerchants));
      }
    }
    // eslint-disable-next-line
  }, [dispatch, impersonateAs]);

  useEffect(() => {
    dispatch(selectMerchant(null));
    dispatch(clearVolumeByMerchant());
  }, [dispatch, activeDistributorApply]);

  // Reload dashboard data when the date range or selected merchant changes.
  // Don't try to load until merchant list has loaded.
  // Don't reload if we're already fetching data.
  useEffect(() => {
    if (activeDistributorApply && merchantsLoaded) {
      const fromDateMoment = moment(fromDate);
      const toDateMoment = moment(toDate);

      if (toDateMoment.diff(fromDateMoment, 'days') >= 366) {
        dispatch(showModal(AlertModal, { title: "Please enter a date range no greater than 1 year." }));
      }
      else {
        dispatch(dataLoading(true));
        dispatch(clearDashboardData());
        
        // Build params for Dashboard API
        const params = {
          startDate: moment(fromDate).format('YYYY-MM-DD'),
          endDate: moment(toDate).format('YYYY-MM-DD'),
          showRebates: 1,
          showDeviations: 0
        };
      
        if (selectedMerchant) {
          cancelDashboardApi()
          params.merchants = [selectedMerchant.value];
        }
        else {
          params.merchants = merchants.map((m) => m.id);
        }
  
        const reqTs = new Date();
        dashboardApi.post(params).then(({ data }) => {
          const resTs = new Date();
          logger.push({
            tag: process.env.REACT_APP_LOGGLY_TAG,
            requestName: 'dashboardApi',
            time: resTs - reqTs,
            reqTimestamp: reqTs,
            resTimestamp: resTs
          });
          dispatch(setDashboardData(
            data.totalVolume,
            data.competitorVolume,
            data.totalRebates,
            data.annualizedRebates,
            data.lostItemCategories,
            data.lostItemDescriptions
          ));

          // Don't change volumes when a merchant is selected
          if (!selectedMerchant) {
            dispatch(setVolumeByMerchant(data.volumeByMerchant));
          }

          // Initial values for dropdowns in Competitive Insight
          dispatch(setCompetitiveInsightCategories(data.lostItemCategories));
          dispatch(setCompetitiveInsightItems(data.lostItemDescriptions));
          dispatch(dataLoading(false));
  
        }).catch(err => {
          console.error(err);
          if(err.message !== 'canceled') {
            dispatch(dashboardLoadFailed());
            dispatch(dataLoading(false));
            dispatch(showModal(AlertModal, { title: "Couldn't load dashboard data." }));

          }
        });
      }
    }
  }, [dispatch, activeDistributorApply, merchants, merchantsLoaded, fromDate, toDate, selectedMerchant]);
}

const useDashboardVolumeStats = () => {
  const dispatch = useDispatch();
  const activeDistributorApply = useSelector(({ user }) => user.activeDistributorApply);
  const merchantsLoaded = useSelector(({intel}) => intel.merchantsLoaded);
  const volumeChartPeriod = useSelector(({dashboard}) => dashboard.volumeChartPeriod);
  const dashboardLoading = useSelector(({dashboard}) => dashboard.isLoading);
  const dashboardLoadFailed = useSelector(({dashboard}) => dashboard.loadFailed);
  const selectedMerchant = useSelector(({ dashboard }) => dashboard.selectedMerchant);
  const merchants = useSelector(({intel}) => intel.merchantsList);

  // Reload volume chart when selected merchant, distributor, or chart period changes.
  // Don't try to load until merchant list has loaded.
  // Don't load while main dashboard is loading or if the main dashboard load failed.
  useEffect(() => {
    if (activeDistributorApply && merchantsLoaded && !dashboardLoading && !dashboardLoadFailed) {
      dispatch(volumeDataLoading(true));

      // Build params for Dashboard API
      const params = {
        // set variable chart dates (based on user select)
        startDate: new Date(moment().subtract(volumeChartPeriod.value, 'month').startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')),
        // query optimizations
        showRebates: 0,
        showDeviations: 0,
        showLineItems: true
      };
    
      if (selectedMerchant) {
        params.merchants = [selectedMerchant.value];
      }
      else {
        params.merchants = merchants.map((m) => m.id);
      }
  
      // Don't load this chart for large accounts - it is currently taking too long to load in such cases
      if (params.merchants.length > 1000) {
        console.log("Volume Overview chart is disabled for this account");
        dispatch(volumeDataLoadFailed(true));
        dispatch(volumeDataLoading(false));
      }
      else {
        dashboardApi.post(params).then(({ data }) => {
          dispatch(setVolumeData(data.volumeByMonth));
        }).catch(err => {
          console.error(err);
          dispatch(volumeDataLoadFailed(true));
          dispatch(volumeDataLoading(false));
        });
      }
    }

    // notable exclusions to dependencies: anything related to distributor & merchants
    // changes to these will get picked up by a dashboard reload.
    // eslint-disable-next-line
  }, [dispatch, volumeChartPeriod, dashboardLoading, dashboardLoadFailed]);
}

const useCsvExport = () => {
  let dashboard = useSelector(({ dashboard }) => dashboard);
  const pushToPreCsvArray = (data) => {
    let csvData = []
    let keys = Object.keys(data[0])
    csvData.push(keys);

    data.forEach(e => {
      csvData.push(keys.map(key => typeof e[key] === 'number' ? numeral(e[key]).format('0,0.00') : e[key]));
    });
    return csvData;
  }
  const exportCsv = (data, fileName) => {
    let preCsvArray = pushToPreCsvArray(data);
    if (preCsvArray.length > 0) {
      stringify(preCsvArray, (err, output) => {
        fileDownload(output, fileName);
      });
    }
  }

  const exportCategoriesLostToComps = () => {
    let data = dashboard.lostItemCategories
    if (data.length > 0) {
      exportCsv(data, 'topCategoriesLostToCompetitors  - For internal use only.csv');
    }
  }
  const exportItemsLostToComps = () => {
    let data = dashboard.lostItemDescriptions;
    if (data.length > 0) {
      exportCsv(data, 'topItemsLostToCompetitors - For internal use only.csv');
    }
  }


  return {
    exportCategoriesLostToComps,
    exportItemsLostToComps
  }
}

export default { useDates, useDashboardStats, useDashboardVolumeStats, useCsvExport };