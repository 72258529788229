import React, { useState } from 'react';
import { Row, Col, Card, Button, Spinner } from 'reactstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useIntelGraph from './useIntelGraph';
import { GoPrimitiveDot } from 'react-icons/go';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { If, Then, Else } from 'react-if';
import numeral from 'numeral';
import { randomColors } from '../../../../config/constants';
import NoDataRows from './Subcomponents/NoDataRows';

const IntelGraphCard = (props) => {
  const { id, name, loading, data, noData } = props;
  const [ showMore, setShowMore ] = useState(false);
  const { chartOptions, goToSeeDetails } = useIntelGraph.useCard(props);

  if (loading) {
    return (
      <Col xs={12} md={4} lg={3} sm={12} className='pb-4'>
        <Card className='text-center h-100'>
          <Row className='m-0'>
            <Col className='intel-graph-header text-size-20 py-3 h-92px'>
              {name}
            </Col>
          </Row>
          <Row className='align-items-center' style={{minHeight: '300px'}}>
            <Col>
              <Spinner color='primary' />
            </Col>
          </Row>
        </Card>
      </Col>
    )
  };

  const distributorsMap = data.slice(0, showMore ? data.length : 4).map(d => {
    return (
      <Row key={`mer_${id}_dist_${d.distributorPlatformId}_merdist_${d.dcn}`} className='text-muted pb-2'>
        <Col className='w-fc'>
          <GoPrimitiveDot color={randomColors[d.distributorId % randomColors.length]} />
        </Col>
        <Col className='text-left px-0'>{d.distributor}</Col>
        <Col className='text-right text-muted'>{numeral(d.volume).format('$0,0.00')}</Col>
      </Row>
    )
  });

  return (
    <Col xs={12} md={4} lg={3} sm={12} className='pb-4'>
      <Card className='text-center h-100 pb-3'>
        <Row className='m-0'>
          <Col className='intel-graph-header text-size-20 py-3 h-92px'>
            {name}
          </Col>
        </Row>
        <Row>
          <Col>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={{ className: 'highcharts-parent' }} />
          </Col>
        </Row>
        <Row className='m-0 flex-fill'>
          <Col>
            { noData ? <NoDataRows /> : distributorsMap}
            <If condition={data.length > 4 && !noData}>
              <If condition={!showMore}>
                <Then>
                  <Row className='text-beplMagenta show-more-less-label text-left pb-3 clickable' onClick={() => setShowMore(true)}>
                    <Col className='w-fc pr-0'>Show More</Col>
                    <Col><IoIosArrowDown /></Col>
                  </Row>
                </Then>
                <Else>
                  <Row className='text-beplMagenta show-more-less-label text-left pb-3 clickable' onClick={() => setShowMore(false)}>
                    <Col className='w-fc pr-0'>Show Less</Col>
                    <Col><IoIosArrowUp /></Col>
                  </Row>
                </Else>
              </If>
            </If>
          </Col>
        </Row>
        <Row>
          <Col className='py-3'>
            <Button color={ noData ? 'grayBg' : 'beplMagenta'} className={'px-5 py-2 button-label' + (noData ? ' text-white' : '')} size='md' disabled={noData} onClick={goToSeeDetails}>See Details</Button>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
  
export default IntelGraphCard;  