
import { useEffect, useState } from "react";
import distributorApi from "../../api/distributorApi";
import { FaEdit } from "react-icons/fa";
import moment from "moment";
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import { useDispatch } from "react-redux";
import InviteModal from './Subcomponents/InviteModal';
import React from "react";
import sortArrayByKey from "../../sharedComponents/Helper/sortArrayByKey";
import { isMobile } from "react-device-detect";
import ErrorModal from "./Subcomponents/ErrorModal";
import ExportModal from "./Subcomponents/ExportModal";
import userApi from "../../api/userApi";

const useAdminDashboard = () => {

  const dispatch = useDispatch();
  const [userSearch, setUserSearch] = useState('');
  const [distributors, setDistributors] = useState([]);
  const [distributorsSelectOps, setDistributorsSelectOps] = useState([]);
  const [selectedParentDistributor, setSelectedParentDistributor] = useState();
  const [editUser, setEditUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [exportUsers, setExportUsers] = useState(false);
  const [childrenDistributors, setChildrenDistributors] = useState([]);
  const [allUsers, setAllUsers] = useState();
  const [selectedChildDistributor, setSelectedChildDistributor] = useState();

  const [sortState, setSortState] = useState({
    sortDesc: false,
    sortBy: 'name'
  })

  const sortBy = (key) => {
    let order = key === sortState.sortBy ? !sortState.sortDesc : true;
    setSortState({ sortDesc: order, sortBy: key })
  };

  function transformUsersData(users) {
    return users.map(user => ({
      name: {
        value: user.contactName,
        label: user.contactName,
        size: 2
      },
      email: {
        value: user.email,
        label: user.email,
        size: 3
      },
      distributor: {
        value: user.activeDistributor ? user.activeDistributor.nickname : 'No Data',
        label: user.activeDistributor ? user.activeDistributor.nickname : 'No Data',
        size: 3
      },
      createdDate: {
        value: user.createdDate ? moment(user.createdDate).format('MM-DD-YYYY') : 'No Data',
        label: user.createdDate ? moment(user.createdDate).format('MM-DD-YYYY') : 'No Data',
        size: 1
      },
      lastLogin: {
        value: user.lastLoginDateTime ? moment(user.lastLoginDateTime).format('MM-DD-YYYY') : 'No Data',
        label: user.lastLoginDateTime ? moment(user.lastLoginDateTime).format('MM-DD-YYYY') : 'No Data',
        size: 1
      },
      role: {
        value: user.title,
        label: user.title,
        size: 1
      },
      data: user,
      actions: {
        edit: (user) => {
          return <FaEdit size={isMobile ? 30 : 15} style={{ cursor: 'pointer' }} className={`text-blue`} onClick={() => setEditUser(user)} />
        }
      }
    }));
  }


  const usersTableHeaderColumns = [
    {
      label: 'Name',
      value: 'name',
      size: 2
    },
    {
      label: 'Email',
      value: 'email',
      size: 3
    },
    {
      label: 'Distributor',
      value: 'distributor',
      size: 3
    },
    {
      label: 'Created Date',
      value: 'created_date',
      size: 1
    },
    {
      label: 'Last Login',
      value: 'last_login',
      size: 1
    },
    {
      label: 'Role',
      value: 'role',
      size: 1
    }
  ]




  const addUserToList = (user) => {
    let userRows = transformUsersData([user]);
    setUsers([...userRows, ...users]);
  }

  const getDistributors = async () => {
    try {
      const distributors = await distributorApi.getParents();
      setDistributors(distributors.data);
      const ops = distributors.data.map(d => {
        return {
          label: d.nickname,
          value: d.platform_id,
          data: d
        }
      });
      setDistributorsSelectOps(ops);
    } catch (err) {
      dispatch(showModal(ErrorModal, { title: 'Error', text1: 'Failed to get the list of distributors', text2: 'Try reloading the page' }))
    }
  }

  const getUsersOfFamily = async (distributorId) => {
    try {
      const users = await distributorApi.getUsersOfFamily(distributorId);
      const transformedUsers = transformUsersData(users.data);
      setUsers(transformedUsers);
    } catch (err) {
      dispatch(showModal(ErrorModal, { title: 'Error', text1: 'Failed to get the list of users', text2: 'Try picking again' }))
    }
  }
  const getUsersForChildDistributor = async (distributorId) => {
    try {
      const users = await distributorApi.getUsersOfChildDistributor(distributorId);
      const transformedUsers = transformUsersData(users.data);
      setUsers(transformedUsers);
    } catch (err) {
      dispatch(showModal(ErrorModal, { title: 'Error', text1: 'Failed to get the list of users', text2: 'Try picking again' }))
    }
  }

  const getAndSetChildrenDistributors = async (distOp) => {
    try {
      const children = await distributorApi.getChildren(distOp.data.id);
      const ops = children.data.map(d => {
        return {
          label: d.nickname,
          value: d.id,
          data: d
        }
      });
      setChildrenDistributors([distOp, ...ops]);
    } catch (err) {

    }
  }

  const inviteUserModal = () => {
    dispatch(showModal(InviteModal, { selectedDistributor: selectedChildDistributor, addUserToList }))
  }

  const openExportModal = () => {
    dispatch(showModal(ExportModal, { distributors: childrenDistributors }))
  }

  const getAllUsers = async () => {
    const users = await userApi.get();
    let transformedData = transformUsersData(users.data);
    setAllUsers(transformedData);
  }

  useEffect(() => {
    if (selectedChildDistributor) {
      getUsersForChildDistributor(selectedChildDistributor.data.platform_id);
    }
    //eslint-disable-next-line
  }, [selectedChildDistributor])

  useEffect(() => {
    if (selectedParentDistributor) {
      getAndSetChildrenDistributors(selectedParentDistributor);
      getUsersOfFamily(selectedParentDistributor.value);

    }
    //eslint-disable-next-line
  }, [selectedParentDistributor])

  useEffect(() => {
    getAllUsers();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (allUsers) {

      let filteredUsers = allUsers.filter((u, ind, arr) => {
        return u.name.value.includes(userSearch) || u.email.value.includes(userSearch)
      });
      // let sortedUsers = sortArrayByKey(filteredUsers, [sortState.sortBy, 'value'], sortState.sortDesc);
      setUsers(filteredUsers)
    }
  // eslint-disable-next-line
  }, [userSearch]);

  let sortedUsers = sortArrayByKey(users, [sortState.sortBy, 'value'], sortState.sortDesc);

  // let filteredUsers = sortedUsers.filter((u, ind, arr) => {
  //   return u.name.value.includes(userSearch) || u.email.value.includes(userSearch)
  // });


  return {
    usersTableHeaderColumns,
    setUserSearch,
    userSearch,
    getDistributors,
    distributors,
    distributorsSelectOps,
    selectedParentDistributor,
    setSelectedParentDistributor,
    editUser,
    setEditUser,
    sortState,
    setSortState,
    sortBy,
    users,
    // filteredUsers,
    inviteUserModal,
    sortedUsers,
    setUsers,
    exportUsers,
    setExportUsers,
    childrenDistributors,
    openExportModal,
    allUsers,
    selectedChildDistributor,
    setSelectedChildDistributor

  }
}

export default useAdminDashboard