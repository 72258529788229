import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { If, Then  } from 'react-if';
import InfiniteScroll from 'react-infinite-scroller';

const RebatesContent = (props) => {
  const { getRebates, hasMore, rebateMap } = props;
  return (
    <Row className='mb-3' style={{marginTop: isMobile ? '':'15px'}}>
      <Col>
        <Card>
          <If condition={isMobile}>
            <Then>
              <InfiniteScroll
                initialLoad = {rebateMap.length === 0}
                pageStart={0}
                loadMore={getRebates}
                hasMore={hasMore}
                useWindow={true} >
                {rebateMap}
              </InfiniteScroll>
            </Then>
          </If>
        </Card>
      </Col>
    </Row>
  );
};

export default RebatesContent;