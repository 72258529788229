import React from 'react';
import { Button, Container, Row, Col, Card, Spinner } from 'reactstrap';
import { If, Then } from 'react-if'
import { showModal } from '../../../reducers/ModalsReducer/ModalsActions';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import InvoiceUploadModal from './Subcomponents/InvoiceUploadModal';
import InputSearch from '../../../sharedComponents/InputSearch/InputSearch';
import UploadedInvoiceSortHeader from './Subcomponents/UploadedInvoiceSortHeader';
import UploadedInvoiceRow from './Subcomponents/UploadedInvoiceRow';
import sortArrayByKey from '../../../sharedComponents/Helper/sortArrayByKey';
import { BsSearch } from 'react-icons/bs';
import { colors } from '../../../config/constants';
import useInvoice from './useInvoice';

const InvoiceScanning = () => {
  const dispatch = useDispatch();
  const { sortState, sortBy, invoiceSearch, setInvoiceSearch, uploadedInvoicesData, deleteUploadedInvoice, error, invoicesLoaded } = useInvoice.useInvoiceScanning();

  const showInvoiceUploadModal = () => {
    dispatch(showModal(InvoiceUploadModal, {}));
  }

  const filteredInvoicesData = uploadedInvoicesData.filter(invoice => {
    const merchant = invoice.merchant_name && invoice.merchant_name !== null ? invoice.merchant_name.toLowerCase() : '';
    const status = invoice.status && invoice.status !== null ? invoice.status.toLowerCase() : '';
    const description = invoice.description && invoice.description !== null ? invoice.description.toLowerCase() : '';

    return merchant.includes(invoiceSearch.toLowerCase()) || description.includes(invoiceSearch.toLowerCase())
      || status.includes(invoiceSearch.toLowerCase())
  });
  const sortedAndFilteredInvoices = sortArrayByKey(filteredInvoicesData, sortState.sortBy, sortState.sortDesc);


  const uploadedInvoices = sortedAndFilteredInvoices.map(invoice => {
    return <UploadedInvoiceRow key={invoice.id} deleteUploadedInvoice={deleteUploadedInvoice} {...invoice} />
  });

  
  return (
    <Container fluid className='bg-grayBg flex-grow-1 pb-5 pl-4'>
      <Row className={`d-flex main-header text-size-20 m-0 mt-3`}>Upload Files</Row>
      <Row className="m-0 justify-content-between mt-2">
        <Col className="p-0" xs={isMobile ? 6 : 3}>
          <InputSearch value={invoiceSearch} onChange={(e) => setInvoiceSearch(e.target.value)} />
        </Col>
        <Button color="beplMagenta" className='btn btn-beplMagenta btn-sm button-label py-2 px-2' onClick={showInvoiceUploadModal}>
          Upload Invoice
        </Button>
      </Row>
      {invoicesLoaded ? <>
        {!isMobile ? <Col className="mt-3 w-100 p-0">
          <UploadedInvoiceSortHeader sortBy={sortBy} sortState={sortState} className="mt-2" />
        </Col> : false}
        <Card className={isMobile ? 'mt-3' : ''}>
          <If condition={invoicesLoaded && uploadedInvoicesData.length > 0}>
            <Then>
              {uploadedInvoices}
            </Then>
          </If>
          <If condition={invoicesLoaded && uploadedInvoicesData.length === 0}>
            <Row style={{ position: 'relative' }}>
              <Col xs={12} className='text-center p-5'>
                <BsSearch color={colors.beplMagenta} size={45} />
                <div className='mt-3 main-header text-center'>No Invoices</div>
              </Col>
            </Row>
          </If>
          <If condition={invoicesLoaded && error !== null}>
            <Row style={{ position: 'relative' }}>
              <Col xs={12} className='text-center p-5'>
                <BsSearch color={colors.beplMagenta} size={45} />
                <div className='mt-3 main-header text-center'>{error}</div>
              </Col>
            </Row>
          </If>
        </Card> </> : false}
      <If condition={!invoicesLoaded}>
        <Row className="d-flex justify-content-center align-items-center h-25vh"><Spinner color='primary' /></Row>
      </If>
    </Container>
  );



};

export default InvoiceScanning;