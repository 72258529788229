import LocalStorage from "../../sharedComponents/LocalStorage/LocalStorage";

const userData = LocalStorage.userData.get();
const adminData = LocalStorage.adminUserData.get();
const isLogin = LocalStorage.authKey.get() ? (userData ? true : false) : false
let init = {
  isLogin: isLogin,
  id: isLogin ? userData.id : null,
  email: isLogin ? userData.email : null,
  name: isLogin ? userData.name || userData.contactName : null,
  displayIntel: userData ? userData.displayIntel : null,
  userRol: [],
  locationList: [],
  locationChecked: [],
  locationCheckedInit: [],
  inviteAll: false,
  merchantsAll: [],
  distributorsList: [],
  searchInviteLocation: '',
  searchInvitedLocation: '',
  activeDistributor: null,
  activeDistributorApply: null,
  impersonateAs: null,
  impersonateMerchants: [],
  isAdmin: false,
  isGroup: userData?.isGroup ? true : false,
  // Check for both old and new flags for isInternalAdmin,
  // as existing sessions will still have exportUser set until they log in again
  isInternalAdmin: userData?.exportUser || userData?.internalAdmin || adminData ? true : false,
  displayInvoice: userData?.displayInvoice ? true : false,
  editUsersRoleView: false,
  editUsersRoleProps: null,
  displayPaymentInfo: userData?.displayPaymentInfo? true : false,

}
export default function reducer(state = init, action) {
  switch (action.type) {
    case 'SET_USER_DATA':
      return {
        ...state,
        ...action.payload,
        isLogin: true,
      };
    case 'CLEAR_USER_DATA':
      init.isLogin = false
      init.id = null
      init.email = null
      init.name = null
      init.isInternalAdmin = false
      init.impersonateAs = null
      init.displayInvoice = false
      return init;
    case 'SET_USER_ROL_DATA':
      return {
        ...state,
        userRol: action.payload,
      };
    case 'SET_INVITE_ALL':
      return {
        ...state,
        inviteAll: action.payload
      };
    case 'TOGGLE_INVITE_ALL':
      return {
        ...state,
        inviteAll: !state.inviteAll
      };
    case 'CLEAR_SEARCH_INVITE_LOCATION':
      return {
        ...state,
        searchInviteLocation: ''
      };
    case 'CLEAR_SEARCH_INVITED_LOCATION':
      return {
        ...state,
        searchInvitedLocation: ''
      };
    case 'SET_SEARCH_INVITE_LOCATION':
      return {
        ...state,
        searchInviteLocation: action.payload
      };
    case 'SET_SEARCH_INVITED_LOCATION':
      return {
        ...state,
        searchInvitedLocation: action.payload
      };
    case 'SET_LOCATION_CHECKED':
      return {
        ...state,
        locationChecked: state.locationChecked.some(m => m === action.payload) ? state.locationChecked.filter(m => m !== action.payload) : [...state.locationChecked, action.payload]
      };
    case 'SET_LOCATION_CHECKED_ALL':
      return {
        ...state,
        locationChecked: action.payload,
      };
      case 'SET_LOCATION_UNCHECKED_ALL':
      return {
        ...state,
        locationChecked: state.locationChecked = [],
      };
    case 'SET_LOCATION_CHECKED_INIT':
      return {
        ...state,
        locationChecked: action.payload,
        locationCheckedInit: action.payload
      };
    case 'SET_ACTIVE_DISTRIBUTOR':
      return {
        ...state,
        activeDistributor: action.payload,
        activeDistributorApply: state.activeDistributorApply === null ? action.payload : state.activeDistributorApply
      };
    case 'APPLY_ACTIVE_DISTRIBUTOR':
    case 'APPLY_INTEL_SEARCH':
      return {
        ...state,
        activeDistributorApply: state.activeDistributor
      }
    case 'SET_DISTRIBUTORS_LIST':
      return {
        ...state,
        distributorsList: action.payload
      };
    case 'SET_MERCHANTS_ALL':
      return {
        ...state,
        merchantsAll: action.payload
      };
    case 'SET_INTERNAL_ADMIN':
      return {
        ...state,
        isInternalAdmin: action.payload
      };
    case 'EDIT_USERS_ROLE':
      return {
        ...state,
        editUsersRoleView: action.payload ? true : false,
        editUsersRoleProps: action.payload
      }
    case 'SET_ADMIN':
      return {
        ...state,
        isAdmin: action.payload
      }
    case 'SET_IMPERSONATE':
      return {
        ...state,
        impersonateAs: action.payload
      }
    case 'SET_IMPERSONATE_MERCHANTS':
      return {
        ...state,
        impersonateMerchants: action.payload
      }
    case 'CLEAR_IMPERSONATE':
      return {
        ...state,
        impersonateAs: null,
        impersonateMerchants: []
      }
    case 'RESET_IMPERSONATE':
      return {
        ...state,
        impersonateAs: null,
        impersonateMerchants: action.payload
      }
    default:
      return state;
  };
};