import React from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { setImpersonateAs } from '../../../../reducers/UserReducer/UserActions';
import { selectStyles } from '../../../../config/constants';

const AdminUserSelect = (props) => {
  const dispatch = useDispatch();

  // Build user dropdown items
  const userSelectList = props.userList?.map(u => {
    return {
      label: u.contactName,
      value: u
    };
  });

  const impersonatedUser = props.userList?.find((u) => u.id === props.impersonate);

  const selection = impersonatedUser ? {
    label: impersonatedUser.contactName,
    value: impersonatedUser
  } : {
    label: props.currentUser.contactName,
    value: props.currentUser
  };

  // Handler for user select
  const onSelectUser = (user) => {
    if (user.value.id === props.currentUser.id) {
      dispatch(setImpersonateAs(null));
    }
    else {
      dispatch(setImpersonateAs(user.value.id));
    }
  }

  return (
    <>
      <Row>
        <Col className='text-size-14' >
          View As
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            options={userSelectList}
            onChange={onSelectUser}
            styles={selectStyles}
            value={selection}
            />
        </Col>
      </Row>
    </>
  );
}

export default AdminUserSelect;