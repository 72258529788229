import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IntelDetailCompetitiveRow from './IntelDetailCompetitiveRow';
import sortArrayByKey from '../../../../../sharedComponents/Helper/sortArrayByKey';
import { setDetailSortBy } from '../../../../../reducers/IntelReducer/IntelActions';

const useApi = (id) => {
  const dispatch = useDispatch();
  const search = useSelector(({intel}) => intel.searchApply);
  const sortState = useSelector(({intel}) => intel.detailOptions.competitiveSort);
  const merchant = useSelector(({intel}) => intel.merchants.filter(m => m.id === id)[0]);

  const sortBy = (key) => {
    dispatch(setDetailSortBy('competitiveSort', key));
  };

  const competitiveDistributors = merchant ? merchant.data.filter(d => d.competitor === true) : [];

  const competitiveData = competitiveDistributors
    .map(d => d.items)
    .reduce((prev, cur) => { 
      return cur ? [...prev, ...cur] : [...prev];
    }, []);

  const totalCases = competitiveData.reduce((prev, cur) => { return prev + cur.quantity}, 0);

  const detailsListMap = sortArrayByKey(competitiveData, sortState.sortBy, sortState.sortBy === 'uom' ? !sortState.sortDesc : sortState.sortDesc)
  .filter(d => d.description.toLowerCase().search(search.toLowerCase()) !== -1 || d.manufacturer.toLowerCase().search(search.toLowerCase()) !== -1).map((d, i) => {
    return (
      <IntelDetailCompetitiveRow key={`competitive_data_${i}`} {...d} />
    );
  });

  return {
    sortBy, sortState,
    merchant,
    loading: merchant ? merchant.loading : true,
    competitorVolume: merchant ? merchant.competitorVolume : 0,
    competitorVolumePerc: merchant ? merchant.competitorVolumePerc : 0,
    totalCases: totalCases,
    detailsListMap,
    competitiveData
  };
};

export default {
  useApi
};