import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
//import moment from 'moment';
import { isMobile } from 'react-device-detect';
import numeral from 'numeral';
import { MdOutlineTag, MdFitnessCenter } from 'react-icons/md';
import { FaPercent } from 'react-icons/fa';
import { IoIosPricetags } from 'react-icons/io';

const IntelDetailCompetitiveRow = ({ description, manufacturer, packsize, rebateValue, quantity, uom, rebateType, deviationValue, startGuidanceRange, endGuidanceRange }) => {
  // Determine if we show '$X.XX' or 'X.XX%' for the rebate value based on the rebate type
  const rebateFormat = rebateValue !== 'N/A' ? numeral(rebateValue).format('0.00') : 'N/A';
  const rebateWords = rebateType !== 'N/A' ? rebateType.toString().split('_') : '';
  const rebateSymbol = rebateWords ? (rebateWords.includes('PERCENT') ? '%' : '$') : '';
  const rebateValueWithSymbol = rebateSymbol === '$' ? rebateSymbol + rebateFormat : rebateFormat + rebateSymbol;
  const marketIndex = numeral(startGuidanceRange).format('$0,0.00') + '-' + numeral(endGuidanceRange).format('$0,0.00')
  //renders a different symbol for each rebate type; defaults to N/A if there is no rebate type
  let rebateTypeSymbol;
  switch (rebateType) {
    case 'FIXED_AMOUNT_PER_QTY':
      rebateTypeSymbol = <MdOutlineTag title='FIXED_AMOUNT_PER_QTY' />;
      break;
    case 'FIXED_AMOUNT_PER_WEIGHT':
      rebateTypeSymbol = <MdFitnessCenter title='FIXED_AMOUNT_PER_WEIGHT' />;
      break;
    case 'PERCENT_OF_COST':
      rebateTypeSymbol = <FaPercent className='text-size-12' title='PERCENT_OF_COST' />;
      break;
    case 'PERCENT_OF_PRICE':
      rebateTypeSymbol = <IoIosPricetags title='PERCENT_OF_PRICE' />;
      break;
    default:
      rebateTypeSymbol = 'N/A';
      break;
  }

  if (isMobile) {
    return (
      <CardBody className='border-bottom p-3'>
        <Row className='pb-3'>
          <Col className='text-capitalize font-weight-bold'>
            {description.toLowerCase()}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>
            Manufacturer
          </Col>
          <Col className='text-right'>
            {manufacturer}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>
            Packsize
          </Col>
          <Col className='text-right'>
            {packsize}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>
            Market Index
          </Col>
          <Col title={startGuidanceRange === 0 && endGuidanceRange === 0 ? 'Distributor did not provide price for this purchase' : ''} className='text-right'>
            {startGuidanceRange === 0 && endGuidanceRange === 0 ? 'N/A' : marketIndex}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>
            Rebate
          </Col>
          <Col className='text-right'>
            {rebateValueWithSymbol}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>
            Rebate Type
          </Col>
          <Col className='text-right'>
            {rebateTypeSymbol}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>
            Deviation
          </Col>
          <Col className='text-right'>
            {deviationValue ? numeral(deviationValue).format('$0,0.00') : 'N/A'}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>
            Quantity
          </Col>
          <Col className='text-right'>
            {numeral(quantity).format('0,0.00')}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>
            UOM
          </Col>
          <Col className='text-right'>
            {uom}
          </Col>
        </Row>
      </CardBody>
    );
  }

  return (
    <CardBody className='border-top py-2 px-3 hover-row'>
      <Row className='align-items-center'>
        <Col className='text-capitalize' xs={2}>
          {description.toLowerCase()}
        </Col>
        <Col xs={2}>
          {manufacturer}
        </Col>
        <Col xs={1}>
          {packsize}
        </Col>
        <Col title={startGuidanceRange === 0 && endGuidanceRange === 0 ? 'Distributor did not provide price for this purchase' : ''} xs={1}>
          {startGuidanceRange === 0 && endGuidanceRange === 0 ? 'N/A' : marketIndex}
        </Col>
        <Col xs={1}>
          {rebateValueWithSymbol}
        </Col>
        <Col xs={2}>
          {rebateTypeSymbol}
        </Col>
        <Col xs={1}>
          {deviationValue ? numeral(deviationValue).format('$0,0.00') : 'N/A'}
        </Col>
        <Col xs={1}>
          {numeral(quantity).format('0,0.00')}
        </Col>
        <Col xs={1}>
          {uom}
        </Col>
      </Row>
    </CardBody>
  );
};

export default IntelDetailCompetitiveRow;
