import React, { useEffect } from "react";
import { Card, Container, Row, Spinner, Col, Button } from 'reactstrap';
import { If } from 'react-if'
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import useItemMatchesData from "./useItemMatchesData";
import RebatesItemMatchesSortHeader from './RebatesItemMatchesSortHeader';
import RebatesEmpty from "../RebatesEmpty";
import RebatesItemMatchesRow from "./RebatesItemMatchesRow";
import InputSearch from "../../../../sharedComponents/InputSearch/InputSearch";

const RebateItemMatchesView = () => {
  const loading = useSelector(({ rebate }) => rebate.itemMatchesLoading);
  const itemMatches = useSelector(({ rebate }) => rebate.filteredSortedItemMatches);
  const { getItemMatches, search, onChangeSearch, clearValueSearch, breadCrumbs, min, manPlatId, merchId, itemName, showMatchFeedbackModal, isInternalAdmin } = useItemMatchesData.useApi();
  const { exportItemMatchesList } = useItemMatchesData.useCsvExport();

  useEffect(() => {
    getItemMatches(min, manPlatId, merchId);
      // eslint-disable-next-line
  }, []);

  if (loading === 'complete') {
    return (
      <Container fluid className='bg-grayBg flex-grow-1'>
        <Col className="px-0">
          <Row className={`py-3 pl-3`}>{breadCrumbs()}</Row>
          <Col className="px-0 main-header text-size-20" xs={4} style={{ marginTop: '16px', paddingBottom: '6px', paddingLeft: '15px' }}>
            {`Similar Items to ${itemName}`}
          </Col>
          <Row className="px-3 pt-1 justify-content-between" style={{ paddingBottom: '16px' }}>

            <Col className="px-0" xs={3}>
              <InputSearch value={search} clearValue={clearValueSearch} onChange={onChangeSearch} on placeholder="Search Similar Items" />
            </Col>
            <Col className={`px-0 justify-content-end ${isMobile ? 'mt-2' : 'd-flex'}`}>
              <Button type="button" onClick={showMatchFeedbackModal} color="beplMagenta" className={`${isMobile ? 'w-100 mb-2' : ''} mr-2 py-2 px-2 btn btn-beplMagenta btn-sm button-label`}>+ Recommend Similar Item</Button>
              <If condition={isInternalAdmin}>
                <Button onClick={exportItemMatchesList} type="button" color="beplMagenta" className="py-2 px-2 btn btn-beplMagenta btn-sm button-label" style={{ float: 'right' }}>Export Page Details</Button>
              </If>
            </Col>
          </Row>
        </Col>

        {!isMobile && <RebatesItemMatchesSortHeader />}
        <Card className="mb-5">
          {itemMatches.length > 0 ? itemMatches.map((match, ind) => {
            return <RebatesItemMatchesRow key={match.min + ind} match={match} />
          }) :
            <Row className="py-5 d-flex justify-content-center">There are no similar items for this item</Row>}
        </Card>
      </Container>
    );
  } else if (loading === 'failed') {
    return (
      <Col xs={12}>
        <RebatesEmpty reason={"Couldn't load item matches"} />
      </Col>
    )
  } else {
    return (
      <Row className="d-flex justify-content-center align-items-center h-25vh"><Spinner color='primary' /></Row>
    )
  }
}

export default RebateItemMatchesView;