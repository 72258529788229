import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CompetitiveInsightItemRow from './CompetitiveInsightItemRow';
import sortArrayByKey from '../../../../../sharedComponents/Helper/sortArrayByKey';
import { setDetailSortBy } from '../../../../../reducers/IntelReducer/IntelActions';
import stringify from 'csv-stringify';
import fileDownload from 'js-file-download';
import numeral from 'numeral';
import { useHistory } from 'react-router-dom';
import stringSanitizer from 'string-sanitizer';

const useApi = (categories, items) => {
  const dispatch = useDispatch();
  const sortState = useSelector(({ intel }) => intel.detailOptions.competitiveInsightItemSort);
  const merchants = useSelector(({ intel }) => intel.merchants);
  const { merchantId } = useParams();
  const merchantData =   merchants.filter(m => m.id === merchantId)[0];
  const distributorDataForMerchant = merchantData ? merchantData.data.filter(m => m.competitor===false)[0] : false;
  const merchantItemsFromDistributor = distributorDataForMerchant ? distributorDataForMerchant.items : [];

  const matchLoading = useSelector(({ intel }) => intel.itemMatchesLoading);
  const history = useHistory();


  const sortBy = (key) => {
    let order = key === sortState.sortBy ? !sortState.sortDesc : true;
    order = ((key === 'description' && key !== sortState.sortBy) || (key === 'manufacturer' && key !== sortState.sortBy)) ? !order : order;
    dispatch(setDetailSortBy('competitiveInsightItemSort', key, order));
  };

  const detailMerchant = merchants.find(m => m.id === merchantId);
  const goToItemMatches = (min, manufacturerPlatformId, manufacturerGroupPlatformId, name) => {
    // Use the group platform ID if the regular platform ID is unavailable
    const platformIdToUse =
      manufacturerPlatformId === "" ?
        manufacturerGroupPlatformId :
        manufacturerPlatformId
      ;
    let merchant = stringSanitizer.sanitize.keepSpace(detailMerchant.merchant);
    name = stringSanitizer.sanitize.keepSpace(name);

    let cleanUrl = '/competitiveinsights/matches/' + merchant + '/' + name + '/' + min + '/' + merchantId + '/' + platformIdToUse;
    history.push(cleanUrl);
  }

  // find all competitors for the selected merchant
  const competitors = {
    merchant: detailMerchant ? detailMerchant.merchant : '',
    data: detailMerchant ? detailMerchant.data.filter(d => d.competitor === true) : []
  };

  let compItems = [];
  let distItems = merchantItemsFromDistributor;


  competitors.data.forEach(distributor => {
    let filteredItems = [];

    // filter by category
    filteredItems = categories.length > 0 ?
      distributor.items.filter(item => categories.includes(item.category)) :
      distributor.items;

    // filter by item desc
    if (items.length > 0) {
      filteredItems = filteredItems.filter(item => items.includes(item.description))
    }

    // add merchant id to each item
    filteredItems = filteredItems.map((item) => {
      return { merchant: detailMerchant.id, ...item };
    });

    compItems.push(filteredItems);
  });

  if(categories.length > 0) {
    distItems = merchantItemsFromDistributor.filter(item => categories.includes(item.category));
  }

  // combine all merchants' items into one array,
  // then sort by the selected sort state
  compItems = compItems.flat();
  compItems = sortArrayByKey(
    compItems,
    sortState.sortBy,
    sortState.sortBy === 'merchant' ? !sortState.sortDesc : sortState.sortDesc
  );

  distItems = sortArrayByKey(
    distItems,
    sortState.sortBy,
    sortState.sortBy === 'merchant' ? !sortState.sortDesc : sortState.sortDesc
  );

  // build table rows, one for each competitor item
  const compDetailListMap = compItems.flat().map((item, key) => {
    // Use the group platform ID if the regular platform ID is unavailable
    return (
      <CompetitiveInsightItemRow
        key={`detail_${merchantId}_${key}`}
        {...item}
        goToItemMatches={goToItemMatches}
        matchLoading={matchLoading}
      />
    );
  });

  const distDetailListMap = distItems.map((item, key) => {
    // Use the group platform ID if the regular platform ID is unavailable
    return (
      <CompetitiveInsightItemRow
        key={`detail_${merchantId}_${key}`}
        {...item}
        // goToItemMatches={goToItemMatches}
        matchLoading={matchLoading}
      />
    );
  });

  // calculate total volume
  const totalVolume = compItems.reduce((prev, cur) => { return prev + cur.purchase }, 0);

  return {
    merchant: detailMerchant ? detailMerchant.merchant : '',
    totalVolume,
    compItems,
    compDetailListMap,
    sortState,
    sortBy,
    goToItemMatches,
    distDetailListMap
  };
};

const useCsvExport = () => {
  const filteredItemsList = useSelector(({ intel }) => intel.filteredCompItemsList, (prev, next) => prev.length === next.length);

  const prepareItemsList = (data) => {
    let csvData = [];
    csvData.push(['description', 'manufacturer', 'packsize', 'marketIndex',
      'rebate', 'deviation', 'quantity', 'uom', 'volume']);

    data.forEach(({ description, manufacturer, packsize, startGuidanceRange, endGuidanceRange,
      rebateValue, deviationValue, quantity, uom, purchase }) => {
      csvData.push([
        description, manufacturer, packsize, numeral(startGuidanceRange).format('0,0.00') + '-' + numeral(endGuidanceRange).format('0,0.00'),
        numeral(rebateValue).format('0,0.00'), numeral(deviationValue).format('0,0.00'), quantity, uom, numeral(purchase).format('0,0.00')
      ])
    });
    return csvData;
  }

  const exportCsv = (data, fileName) => {
    let preCsvArray = prepareItemsList(data);
    if (preCsvArray.length > 0) {
      stringify(preCsvArray, (err, output) => {
        fileDownload(output, fileName);
      });
    }
  }

  const exportItemsList = () => {
    if (filteredItemsList?.length > 0) {
      exportCsv(filteredItemsList, 'competitiveItemsList - For internal use only.csv');
    }
  }


  return {
    exportItemsList
  }
}

export default {
  useApi, useCsvExport
};