let init = {
  uploadedInvoicesData: [],
  uploadedInvoiceImages: [],
  uploadedInvoicesSort: {
    sortBy: 'createdAt',
    sortDesc: true
  },
  uploadedInvoiceItemsSort: {
    sortBy: 'description',
    sortDesc: true
  }
}
export default function reducer(state = init, action) {
  switch (action.type) {
    case 'SET_UPLOADED_INVOICES_DATA':
      return {
        ...state,
        uploadedInvoicesData: action.payload
      }
    case 'SET_UPLOADED_INVOICE_IMAGES':
      return {
        ...state,
        uploadedInvoiceImages: action.payload
      }
    case 'SET_SORT':
      return {
        ...state,
        [action.payload.view]: {
          sortBy: action.payload.key,
          sortDesc: action.payload.desc === undefined ? (action.payload.key === state[action.payload.view].sortBy ? !state[action.payload.view].sortDesc : true) : action.payload.desc
        }

      }
    default:
      return state;
  }
};