import React, { useEffect, useState, createRef } from 'react';
import { Row, Col } from 'reactstrap';
import useTopBar from '../TopBar/useTopBar';
import { IoMdClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBurgerMenu } from '../../reducers/UIReducer/UIActions';

const BurgerMenu = () => {
  const { links, goToLinks } = useTopBar.useLinks();
  const burgerMenuStatus = useSelector(({ ui }) => ui.burgerMenu)
  const [visibility, setVisibility] = useState(burgerMenuStatus ? 'block' : 'none');
  const dispatch = useDispatch();
  const opacityBg = createRef();
  const menu = createRef();

  useEffect(() => {
    if (burgerMenuStatus) {
      if (opacityBg.current !== null && menu.current !== null) {
        opacityBg.current.classList.add('opacity-fade-in');
        opacityBg.current.classList.remove('opacity-fade-out');
        menu.current.classList.add('left-slide-in');
        menu.current.classList.remove('left-slide-out');
        setVisibility('block');
      }
    } else {
      if (opacityBg.current !== null && menu.current !== null) {
        opacityBg.current.classList.add('opacity-fade-out');
        opacityBg.current.classList.remove('opacity-fade-in');
        menu.current.classList.add('left-slide-out');
        menu.current.classList.remove('left-slide-in');
      }
      setTimeout(() => {
        setVisibility('none');
      }, 800)
    }
  // eslint-disable-next-line
  }, [burgerMenuStatus]);

  const linksMap = links.map((l, i) => {
    let Icon = l.icon('resp-font-two')
    const navigate = () => {
      dispatch(toggleBurgerMenu());
      goToLinks(l);
    }
    return (
      <Row onClick={navigate}
        style={l.isActive ? { borderLeft: '10px solid #CF3F7C' } : {}}
        className={`op-slide-left-30px  ml-2 text-white main-header d-flex align-items-center mt-3
        ${l.isActive ? ' pl-3' : ''}`} key={`burger-menu-${i}`}
      >
        <div >
          <Icon />
        </div>
        <div className={`ml-3 resp-font-two`}>{l.title}</div>
      </Row>
    )
  })

  return (
    <div ref={opacityBg} style={{ display: visibility, zIndex: '1200' }}
      className='position-fixed bg-black-opacity-30 w-100 h-screen opacity-fade-in'>
      <div ref={menu} className="w-80 bg-beplBlue h-screen left-slide-in resp-font-one">
        <IoMdClose onClick={() => dispatch(toggleBurgerMenu())} color="white" className='resp-font-one position-absolute-top-end mr-2 mt-3' />
        <Col style={{ paddingTop: '50px' }}>
          {linksMap}
        </Col>
      </div>
    </div>
  );
};

export default BurgerMenu;