import React from 'react';
import useNotification from '../useNotification/useNotification';

const Notifications = () => {
  // const notifications = useSelector(state => state.notifications.notifications);
  const { limitedNotifications, notifications } = useNotification();

  const notificationsMap = limitedNotifications.map(n =>
    <n.component key={n.props.id} id={n.props.id} {...n.props} />
  );
  return (
    <div style={{zIndex: '1200'}} className="position-fixed w-0 overflow-visible right-365px top-5">
      {notifications.length > 4 ? <div style={{ height: '10px', width: '10px', border: '2px solid #314174', marginTop: '-5px', zIndex: '1200'}} className=' p-3 right-0 border-2 border-beplMagenta 
      text-size-14 position-fixed bg-white rounded-circle font-weight-bold d-flex justify-content-center align-items-center'>{notifications.length}</div> : false }
      {notificationsMap}
    </div>
  );
};

export default Notifications;