import React from 'react';
import useModal from '../../../sharedComponents/useModal/useModal';
import { Modal, ModalBody, Row, Col, Button, Input, CustomInput } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { MdClose } from 'react-icons/md';
import Select from 'react-select';
import { selectStyles } from '../../../config/constants';
import useInviteModal from './useInviteModal';
import { Spinner } from 'reactstrap';

const InviteModal = ({ id, selectedDistributor, addUserToList }) => {
  const { modalProps, headerProps } = useModal(id, { isMenu: true, isFullscreen: true });
  const { userInviteFunc, roles, role, setRole, email, setEmail, name, setName, loading,
    setDisplayIntel, displayIntel, setDisplayPaymentInfo, displayPaymentInfo } = useInviteModal(selectedDistributor, addUserToList, modalProps);


  if (!isMobile) {
    // modalProps.className = 'img-view-upload-modal-wrapper';
    // modalProps.contentClassName = 'img-view-upload-modal';
  }



  return (
    <Modal {...modalProps} className='d-flex align-items-center' style={{ paddingTop: '150px' }}>
      <Row className="m-0 justify-content-between d-flex font-weight-bold p-3">
        <Col xs="8" className='px-2'>Invite User</Col>
        <MdClose size={25} className='clickable' onClick={headerProps.toggle} />
      </Row>
      <ModalBody style={{ marginTop: '0px !important' }} className={`${isMobile ? 'px-1' : ''} position-relative pt-0`} >

        <Col className='mt-1 px-0'>
          <Col xs={12} className='px-0 mt-0'>
            <div className='text-size-14 pl-1 pb-1'>Name*</div>
            <Input value={name} onChange={(e) => setName(e.target.value)} required />
          </Col>
          <Col xs={12} className='px-0 mt-3'>
            <div className='text-size-14 pl-1 pb-1'>Email Address*</div>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} required />
          </Col>
          <Col xs={12} className='px-0 mt-3'>
            <div className='text-size-14 pl-1 pb-1'>Select Role*</div>
            <Select
              styles={selectStyles}
              placeholder='Pick a Role'
              options={roles}
              onChange={setRole}
              value={role} />
          </Col>
        </Col>

        <Row className='text-size-14 line-height-16'>
          <Col xs={12} className='pt-3 d-flex align-items-center'>
            <CustomInput id={'allow-intel'} label={'Allow User to See Intel'} type='checkbox' onChange={() => setDisplayIntel(displayIntel ? false : true)} checked={displayIntel} />
          </Col>
        </Row>
        <Row className='text-size-14 line-height-16 '>
          <Col xs={12} className='pt-3 d-flex align-items-center'>
            <CustomInput id={'allow-display-info'} label={'Allow User to See Payment Info'} type='checkbox' onChange={() => setDisplayPaymentInfo(displayPaymentInfo ? false : true)} checked={displayPaymentInfo} />
          </Col>
        </Row>

        {!loading ?
          <Button onClick={userInviteFunc} className='py-2 button-label mt-3 w-100' color={'beplMagenta'}>
            Invite User
          </Button> :
          <Button className='py-2 button-label mt-3 w-100' color={'beplMagenta'}>
            <Spinner size="sm" />
          </Button>
        }
      </ModalBody>
      {/* <ModalFooter className='justify-content-end'>
      </ModalFooter> */}
    </Modal>
  )
}

export default InviteModal;