import { IoChevronBackOutline } from 'react-icons/io5';
import Select from 'react-select';
import { selectStyles } from '../../config/constants';
import React, { useRef } from 'react';


const addedSelectStyle = {
  control: (provided, state) => {
    return {
      ...provided,
      borderRadius: 2,
      borderColor: state.isFocused ? '#4fafff' : '#ced4da',
      ':hover': {
        borderColor: '#4fafff'
      },
      boxShadow: 'none',
      paddingLeft: '18px'
    };
  },
}

const MultiDimensionDropdown = ({
  firstList,
  selectedFromFirstList,
  setSelectedFromFirstList,
  secondList,
  selectedFromSecondList,
  setSelectedFromSecondItem,
  addItemFromSecondList,
  Option,
  withMulti
}) => {

  const selectRef = useRef();



  const goBackInSelect = () => {
    setSelectedFromFirstList(false);
    let waiting = setInterval(() => {
      if (selectRef.current.select) {
        selectRef.current.select.openMenu();
        clearInterval(waiting);
        let waiting2 = setInterval(() => {
          if (selectRef.current.select.menuListRef) {
            selectRef.current.select.menuListRef.scrollTop = 0;
            clearInterval(waiting2)
          }
        }, 50)
      }
    }, 200);
  }

  return (
    <div>
      {selectedFromFirstList ? (
        <IoChevronBackOutline
          onClick={goBackInSelect}
          size="20"
          className="clickable position-absolute text-gray"
          style={{ zIndex: 60, top: 9, left: 5 }}
        />
      ) : null}

      {!selectedFromFirstList ? (
        <Select
          ref={selectRef}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          controlShouldRenderValue={false}
          options={firstList}
          styles={selectStyles}
          onChange={setSelectedFromFirstList}
          value={selectedFromFirstList}
        />
      ) : (
        (!withMulti ? 
          <Select
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          options={secondList}
          styles={{ ...selectStyles, ...addedSelectStyle }}
          onChange={setSelectedFromSecondItem}
          isClearable={false}
          value={selectedFromSecondList}
        />
          : 
          <Select
          isMulti={withMulti}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          controlShouldRenderValue={false}
          options={secondList}
          styles={{ ...selectStyles, ...addedSelectStyle }}
          onChange={addItemFromSecondList}
          isClearable={false}
          value={selectedFromSecondList}
          components={{
            Option
          }}
        />

        )
    
      )}
    </div>
  );
};

export default MultiDimensionDropdown;
