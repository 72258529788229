export const setDashboardData = (totalVolume, competitorVolume, totalRebates, 
  annualizedRebates, lostItemCategories, lostItemDescriptions) => {
  return {
    type: 'SET_DASHBOARD_DATA',
    payload: {
      totalVolume,
      competitorVolume,
      totalRebates,
      annualizedRebates,
      lostItemCategories,
      lostItemDescriptions
    }
  }
};

export const clearDashboardData = () => {
  return {
    type: 'CLEAR_DASHBOARD_DATA'
  }
};

export const dataLoading = (flag) => {
  return {
    type: 'LOADING',
    payload: flag
  }
};

export const itemDrilldown = (item) => {
  return {
    type: 'SET_ITEM_DRILLDOWN',
    payload: item
  }
};

export const categoryDrilldown = (category) => {
  return {
    type: 'SET_CATEGORY_DRILLDOWN',
    payload: category
  }
};

export const itemDrilldownFromDashboard = (item) => {
  return {
    type: 'SET_ITEM_DRILLDOWN_DASH',
    payload: item
  }
};

export const categoryDrilldownFromDashboard = (category) => {
  return {
    type: 'SET_CATEGORY_DRILLDOWN_DASH',
    payload: category
  }
};

export const clearAllDashboardData = () => {
  return {
    type: 'CLEAR_ALL_DASHBOARD_DATA'
  }
}

export const dashboardLoadFailed = () => {
  return {
    type: 'DASHBOARD_LOAD_FAILED'
  }
}

export const updateLostItems = (items, sortBy) => {
  return {
    type: 'UPDATE_LOST_ITEMS',
    payload: {
      items,
      sortBy
    }
  }
};

export const redirectFromDashboardToItemDetails = (redirecting, type) => {
  return {
    type: 'REDIRECT_FROM_DASHBOARD_TO_ITEM_DETAILS',
    payload: {
      redirecting,
      type
    }
  }
}

export const selectMerchant = (merchant) => {
  return {
    type: 'SELECT_MERCHANT',
    payload: merchant
  }
}

export const setVolumeData = (data) => {
  return {
    type: 'SET_VOLUME_DATA',
    payload: data
  }
}

export const volumeDataLoading = (flag) => {
  return {
    type: 'VOLUME_LOADING',
    payload: flag
  }
};

export const setVolumeChartPeriod = (period) => {
  return {
    type: 'SET_VOLUME_CHART_PERIOD',
    payload: period
  }
};

export const volumeDataLoadFailed = (flag) => {
  return {
    type: 'VOLUME_LOAD_FAILED',
    payload: flag
  }
};

export const setVolumeByMerchant = (data) => {
  return {
    type: 'SET_VOLUME_BY_MERCHANT',
    payload: data
  }
};

export const clearVolumeByMerchant = () => {
  return {
    type: 'CLEAR_VOLUME_BY_MERCHANT'
  }
};