import React from 'react';
import useModal from '../../../../sharedComponents/useModal/useModal';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row} from 'reactstrap';
import { isMobile, } from 'react-device-detect';

const DeleteConfirmationModal = ({ id, deleteInvoice = null, deleteImage = null }) => {
  const { modalProps, headerProps } = useModal(id, { isMenu: true, isFullscreen: true });

  const executeDeleteInvoice = () => {
    deleteInvoice.deleteUploadedInvoice(deleteInvoice.invoiceId);
    modalProps.toggle();
  }
  const executeDeleteImage = () => {
    deleteImage.deleteInvoiceImage(deleteImage.invoiceId, deleteImage.imageId);
    modalProps.toggle();
  }

  return (
    <Modal  {...modalProps}>
      <ModalHeader {...headerProps} >Confirmation</ModalHeader>
      <ModalBody className={`${isMobile ? 'px-2' : ''} pb-0`} >
        <Row className={`p-2 ${isMobile ? 'pl-4' : ''}`}>Are you sure you want to delete this?</Row>
      </ModalBody>
      <ModalFooter className='justify-content-end'>
      <Row className={`m-0 d-flex justify-content-between align-items-center ${isMobile ? 'flex-column mt-2' : 'flex-row mt-3'}`}>
          <Row className={`m-0 d-flex  ${isMobile ? 'w-100 justify-content-end mt-4' : ''}`}>
            <Button color="beplMagenta" onClick={deleteInvoice !== null ? executeDeleteInvoice : executeDeleteImage} className="btn btn-beplMagenta btn-sm button-label py-2 px-2 align-self-start mr-2">Delete</Button>
            <Button color="beplMagenta" onClick={modalProps.toggle} className="btn btn-beplMagenta btn-sm button-label py-2 px-2 align-self-start">Cancel</Button>
          </Row>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteConfirmationModal;