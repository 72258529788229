import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import * as $ from 'jquery';
import { GiHamburgerMenu } from 'react-icons/gi';
import { HiCog } from 'react-icons/hi'
import { useHistory } from 'react-router-dom';
import { toggleBurgerMenu } from '../../reducers/UIReducer/UIActions';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

const HeaderLogo = ({ logo }) => {
  const [h, setH] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedIn = useSelector(({user}) => user.isLogin)

  const setHeight = () => {
    if ($('#header-container') !== null) {
      const height = $('#header-container').outerHeight();
      setH(height)
    } else {
      setH(81)
    }
  }
  const goToHome = () => {
    history.push('/');
  };



  return (
    <div>
      <Container id='header-container' fluid className='bg-beplBlue p-2' style={{zIndex: 1030, minHeight: `${h}px`}}>
        <Row className="d-flex align-items-center">
          {isMobile && loggedIn && !history.location.pathname.includes('group-login') && !history.location.pathname.includes('admin')?
            <GiHamburgerMenu onClick={() => dispatch(toggleBurgerMenu())} className="resp-font-one" color="white" style={{ marginLeft: '30px' }} />
            : false
          }
          <Col className="d-flex justify-content-center">
            <img src={logo} alt='Company Logo' onLoad={setHeight} style={{ marginLeft: '10px' }} className='company-logo clickable' onClick={goToHome} />
          </Col>
          {isMobile && loggedIn && !history.location.pathname.includes('admin') && !history.location.pathname.includes('group-login') ?
            <HiCog onClick={() => history.push('/settings')} className="resp-font-one" color="white" style={{ marginRight: '30px' }} />
            : false}
        </Row>
      </Container>
    </div>
  );
};

HeaderLogo.propTypes = {
  logo: PropTypes.string.isRequired
};

export default HeaderLogo;
