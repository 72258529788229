import Joi from '@hapi/joi';
import useForm from "../../sharedComponents/useForm/useForm";

const initialState = {
  password: '',
  passwordRepeat: ''
};

const reducer = (state, action) => {
  return {
    ...state,
    [action.field] : action.value
  };
};

const schema = Joi.object({
  password: Joi.string().min(3).label('Password').required(),
  passwordRepeat:  Joi.string().valid(Joi.ref('password')).messages({'any.only': `Passwords don't match`}),
});

const useLoginForm = () => {
  const { form, validate } = useForm(reducer, initialState, schema);

  return {
    ...form,
    validate
  };
};

export default useLoginForm;