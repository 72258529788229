import userApi from "../../../api/userApi";
import { useState, useEffect } from "react";
import { components } from 'react-select';
import { BsTrashFill } from "react-icons/bs";
import React from "react";
import distributorApi from "../../../api/distributorApi";
import sortArrayByKey from "../../../sharedComponents/Helper/sortArrayByKey";
import { showModal } from "../../../reducers/ModalsReducer/ModalsActions";
import ConfirmModal from "./ConfirmModal";
import { useDispatch } from "react-redux";
import { FaUserSlash } from 'react-icons/fa';
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";

const useEditUser = (user, setUser, setUsers, users) => {
  const [visible, setVisible] = useState(null);
  const [name, setName] = useState();
  const [email, setEmail] = useState('');
  const [role, setRole] = useState();
  const [distributors, setDistributors] = useState([]);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState([]);
  const [selectedParentDistributor, setSelectedParentDistributor] = useState();
  const [selectedChildDistributor, setSelectedChildDistributor] = useState([]);
  const [parentDistributors, setParentDistributors] = useState([]);
  const [childrenDistributors, setChildrenDistributors] = useState([]);
  const [originalDistributors, setOriginalDistributors] = useState([]);
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const [includeFamily, setIncludeFamily] = useState(false);

  const [sortState, setSortState] = useState({
    sortDesc: false,
    sortBy: 'name'
  });

  const sortBy = (key) => {
    let order = key === sortState.sortBy ? !sortState.sortDesc : true;
    // order = ((key === 'description' && key !== sortState.sortBy) || (key === 'manufacturer' && key !== sortState.sortBy)) ? !order : order;
    setSortState({ sortDesc: order, sortBy: key })
  };

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((id, index) => id === arr2[index]);
  };

  const Option = (props) => {
    let id = props.data.data.id;
    let exists = selectedChildDistributor.findIndex(sd => sd.data.id === id);
    let activeDistributorId = user.data.activeDistributor.id;

    if (activeDistributorId !== props.data.data.id) {
      return (
        <div>
          <components.Option {...props}>
            <input
              type="checkbox"
              checked={exists > -1}
              onChange={() => null}
            />
            <label className='ml-2'>{props.label}</label>
          </components.Option>
        </div>
      );
    } else {
      return false;
    }
  };

  const addedSelectStyle = {
    control: (provided, state) => {
      return {
        ...provided,
        borderRadius: 2,
        borderColor: state.isFocused ? '#4fafff' : '#ced4da',
        ':hover': {
          borderColor: '#4fafff'
        },
        boxShadow: 'none',
        paddingLeft: '18px'
      };
    },
  }

  const removeDistributor = (distributor) => {
    let filtered = distributors.filter(d => d.data.id !== distributor.data.id);
    setDistributors(filtered)
  }


  const transformRowData = (distributors, user) => {
    return distributors.map(distributor => {
      return {
        name: {
          value: distributor.nickname,
          label: distributor.nickname,
          size: 6
        },
        data: distributor,
        flag: user.activeDistributor.id === distributor.id ? [
          <div key={distributor.nickname} className="text-green font-weight-bold">Active</div>
        ] : false
      }
    });
  };

  const distRowActions = {
    delete: (item) => {
      if (item && user) {
        if (item.data.id !== user.data.activeDistributor.id) {
          return <BsTrashFill style={{ cursor: 'pointer' }} className={`text-red`} onClick={() => removeDistributor(item)} />
        }
      }
    }
  }


  const addNewDistributor = (dists) => {
    setSelectedChildDistributor(dists);
    let selectedData = dists.map(d => d.data);
    let transformedSelected = transformRowData(selectedData, user.data);
    setDistributors(transformedSelected);
  };



  const getParentDistributors = async () => {
    try {
      const parents = await distributorApi.getParents();
      const ops = parents.data.map(d => {
        return {
          label: d.nickname,
          value: d.id,
          data: d,

        }
      });
      setParentDistributors(ops);
    } catch (err) {

    }
  }

  const getAndSetChildrenDistributors = async (distOp) => {
    try {
      const children = await distributorApi.getChildren(distOp.data.id);
      const ops = children.data.map(d => {
        return {
          label: d.nickname,
          value: d.id,
          data: d
        }
      });
      setChildrenDistributors([distOp, ...ops]);
    } catch (err) {

    }
  }

  const getChildrenDistributors = async (distOp) => {
    try {
      const children = await distributorApi.getChildren(distOp.data.id);
      const ops = children.data.map(d => {
        return {
          label: d.nickname,
          value: d.id,
          data: d
        }
      });
      return [distOp, ...ops];
    } catch (err) {

    }
  }
  const getDistributorsForUser = async () => {
    if (user) {
      try {
        const distributors = await distributorApi.houses(user.data.id);
        const transformedData = transformRowData(distributors.data, user.data);
        setOriginalDistributors(distributors.data.map(d => d.id));

        setSelectedChildDistributor(
          distributors.data.map(d => {
            return {
              label: d.nickname,
              value: d.id,
              data: d
            }
          })
        );
        setDistributors(transformedData);
      } catch (err) {
      }
    }
  }

  const saveUser = async () => {
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        return dispatch(showModal(ErrorModal, { title: 'Error', text1: " Confirm password doesn't match", text2: 'Try picking user again' }));
      }
    }
    const currentDistributorIds = distributors.map(d => d.data.id);
    const distributorsToSend = arraysEqual(originalDistributors, currentDistributorIds) ? [] : currentDistributorIds;
    
    setLoading(true);
    let payload = {
      contactName: name,
      email,
      locations: [],
      password,
      passwordResetToken: user.data.passwordResetToken,
      distributors: distributorsToSend,
      title: role.label,
      isMultiHouse: !!user.data.isMultiHouse,
      displayIntel: !!user.data.displayIntel,
      displayInvoice: !!user.data.displayInvoice,
      displayPaymentInfo: !!user.data.displayPaymentInfo,
    }

    let updatedUsers = users.map(u => {
      if(u.data.id === user.data.id) {
        u.data.email = email;
        u.email.value = email;
        u.data.role = role.label;
        u.role.value = role.label;
        u.role.label = role.label;
      }
      return u;
    });
    setUsers(updatedUsers)
    try {
      await userApi.userInvite.patch(user.data.id, payload);
      setLoading(false);
      dispatch(showModal(SuccessModal, { text1: 'User was successfully saved!', title: 'User Saved' }))
    } catch (err) {
      setLoading(false);
      dispatch(showModal(ErrorModal, { title: 'Error', text1: 'Failed to save user', text2: 'Try again' }))
    }
  }
  const deactivateUser = async () => {
    try {
      await userApi.userInvite.delete(user.data.id);
      const filtered = users.filter(u => {
        return u.data.id !== user.data.id;
      })
      setUsers(filtered);
      setUser(false);
      dispatch(showModal(SuccessModal, { text1: 'User was successfully saved!', title: 'User Saved' }))
    } catch (err) {
      dispatch(showModal(ErrorModal, { title: 'Error', text1: 'Failed to deactivate user', text2: 'Try again' }))
    }
  }
  const showConfirmUserDeactivation = () => {
    let userCopy = { ...user };
    dispatch(showModal(ConfirmModal, {
      Icon: FaUserSlash, user: userCopy, action: deactivateUser, actionName: 'Confirm', title: 'Confirm Account Deactivation', buttonText: 'Deactivate User',
      text: 'If you deactivate this account, this person will lose access to this Distributor Portal and will be signed out of their account on all devices.'
    }));
  }


  const selectFamilyDistributors = async () => {
    if (user) {
      let activeDistributor = user.data.activeDistributor;
      let parentDistributor = parentDistributors.find(d => d.data.id === activeDistributor.parent_id);
      const family = await getChildrenDistributors(parentDistributor);
      const childrenOfParent = family.filter(fm => fm.data.parent_id !== null)
      addNewDistributor(childrenOfParent);
    }
  }

  const deselectFamilyDistributors = async () => {
    if (user) {
      let activeDistributor = user.data.activeDistributor;
      let parentDistributor = parentDistributors.find(d => d.data.id === activeDistributor.parent_id);
      let filtered = distributors.filter(d => d.data.parent_id !== activeDistributor.parent_id && d.data.id !== parentDistributor.data.id);

      addNewDistributor([{ label: activeDistributor.nickname, value: activeDistributor.id, data: activeDistributor }, ...filtered]);
    }
  }

  useEffect(() => {
    if (includeFamily) {
      selectFamilyDistributors()
    } else {
      deselectFamilyDistributors()
    }
  // eslint-disable-next-line
  }, [includeFamily]);

  useEffect(() => {
    if (user && user.name) {
      setName(user.name.value);
      setEmail(user.email.value);
      setRole({ value: user.role.value, label: user.role.value });
    }
    getDistributorsForUser();
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (selectedParentDistributor) {
      getAndSetChildrenDistributors(selectedParentDistributor);
    }
  }, [selectedParentDistributor]);

  useEffect(() => {
    getParentDistributors();
    // eslint-disable-next-line
  }, []);

  let sortedDistributors = sortArrayByKey(distributors, [sortState.sortBy, 'value'], sortState.sortDesc);

  return {
    Option,
    // goBackInSelect,
    addedSelectStyle,
    visible,
    name,
    email,
    role,
    setName,
    setEmail,
    setRole,
    setVisible,
    distributors,
    setSelectedChildDistributor,
    selectedChildDistributor,
    parentDistributors,
    selectedParentDistributor,
    setSelectedParentDistributor,
    childrenDistributors,
    addNewDistributor,
    saveUser,
    removeDistributor,
    distRowActions,
    sortBy,
    sortState,
    sortedDistributors,
    deactivateUser,
    showConfirmUserDeactivation,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    loading,
    includeFamily,
    setIncludeFamily
  }
}

export default useEditUser;