import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectStyles } from '../../../../config/constants';
import useModal from '../../../../sharedComponents/useModal/useModal';
import RebatesEmpty from '../RebatesEmpty';
import FilterSection from '../Subcomponents/FilterSection';
import Select from 'react-select';
import useRebates from '../useRebates';
import useDistributor from '../../../../sharedComponents/useDistributor/useDistributor';
import filterArrayByKey from '../../../../sharedComponents/Helper/filterArrayByKey';

const RebatesFilterModal = ({id, onClickSearch, onClickReset}) => {
  const { modalProps, headerProps, bodyProps } = useModal(id, {isMenu: true, isFullscreen: true});
  const { searchManufacturer, onCheckManufacturer, onCheckCategory, searchCategory } = useRebates.useFilter();
  const { selectActiveDistributor, activeDistributor, distributorsList } = useDistributor();
  const categoryGroup = useSelector(({rebate}) => rebate.categoryGroup);
  const manufacturerGroup = useSelector(({rebate}) => rebate.manufacturerGroup);
  const searchM = useSelector(({rebate}) => rebate.searchManufacturer);
  const searchC = useSelector(({rebate}) => rebate.searchCategory);
  const checkM = useSelector(({rebate}) => rebate.manufacturerChecked);
  const checkC = useSelector(({rebate}) => rebate.categoryChecked);
  const filtersLoadFailed = useSelector(({rebate}) => rebate.filtersLoadFailed);

  const keysSearchM = [
    { key: 'name', search: searchM }
  ];
  const keysSearchC = [
    { key: 'name', search: searchC }
  ];
  const apply = () => {
    modalProps.toggle();
    onClickSearch(checkM, checkC);
  }
  const reset = () => {
    modalProps.toggle();
    onClickReset();
  }

  const manufacturerOptions = filterArrayByKey(manufacturerGroup, keysSearchM).map(m => {
    return {
      id: m.id,
      value: checkM.some(mm => mm === m.id),
      label: m.name
    }
  });
  const categoryOptions = filterArrayByKey(categoryGroup, keysSearchC).map(c => {
    return {
      id: c.id,
      value: checkC.some(cc => cc === c.id),
      label: c.name
    }
  });
  bodyProps.className += ' pt-3';

  let distributorSelect = null;
  if (distributorsList && distributorsList.length === 1) {
    // Show a single distributor as a label
    distributorSelect = 
      <Col xs={12} className='pb-3 px-3'>
        <div className='main-header text-size-20'>{distributorsList[0].label}</div>
      </Col>
    ;
  }
  else {
    // Show multiple distributors as a select
    distributorSelect = 
      <Col xs={12} className='pb-3 px-3'>
        <Select 
          options={distributorsList} 
          onChange={selectActiveDistributor} 
          styles={selectStyles} 
          value={activeDistributor}
        />
      </Col>
    ;
  }

  return (
    <Modal {...modalProps}>
      <ModalHeader {...headerProps}>
        Filters
      </ModalHeader>
      {
        filtersLoadFailed ? <RebatesEmpty reason="Couldn't load filters, try refreshing" /> : 
        <>
        <ModalBody {...bodyProps} >
        <Row>
          <Col xs={12} className='pb-3 px-3 font-weight-bold'>
            Distributor
          </Col>
          {distributorSelect}
        </Row>
        <Col className='p-0'>
          <FilterSection title='Manufacturer' options={manufacturerOptions} search={searchManufacturer} onCheck={onCheckManufacturer} />
          <FilterSection title='Category' options={categoryOptions} search={searchCategory} onCheck={onCheckCategory} />
        </Col>
      </ModalBody>
      <ModalFooter className='justify-content-center'>
        <Col className='pl-0'>
          <Button outline block className='py-2 button-label' color='beplMagenta' onClick={reset}>Reset</Button>
        </Col>
        <Col className='pr-0'>
          <Button block className='py-2 button-label' color='beplMagenta' onClick={apply}>Apply</Button>
        </Col>
      </ModalFooter>
        </>
      }
    </Modal>
  );
};

export default RebatesFilterModal;