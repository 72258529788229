
import numeral from 'numeral';
import moment from 'moment';
const fromDate = new Date(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + 'T00:00:00');
const toDate = new Date(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') + 'T00:00:00');
let init = {
  fromDate: fromDate,
  toDate: toDate,
  // detailFromDate: fromDate,
  // detailToDate: toDate,
  clientListView: 'list',
  loaded: {
    opportunities: false,
    similarItems: false
  },
  loading: {
    opportunities: null,
    similarItems: null
  },
  loadFails: {
    dash: false,
    similarItemCounts: false,
    similarItems: false
  },
  loadSuccesses: {
    dash: false,
    similarItemCounts: false,
    similarItems: false
  },
  clientSearch: '',
  itemSearch: '',
  clientSortState: {
    page: 1,
    sortBy: 'compliantVolume',
    sortDesc: true
  },
  allClientSortState: {
    page: 1,
    sortBy: 'compliantVolume',
    sortDesc: true
  },
  itemSortState: {
    page: 1,
    sortBy: 'savingItems',
    sortDesc: true
  },
  similarItemSortState: {
    page: 1,
    sortBy: 'rebate',
    sortDesc: true
  },
  updatingItemDateRange: false,
  itemInViewForSimItems: {
    min: null,
    manufacturerProductId: null,
    manufacturerPlatformId: null,
    productId: null
  },
  clients: [],
  purchasesByClient: {},
  clientDateRanges: {},
  clientSimilarItems: {},
  clientSimilarItemCounts: {},
  clientTopFiveOpportunities: {},
  overviewStats: {
    totalClients: '...',
    complianceRate: '...',
    graph: {
      topClients: [],
      top25Opportunities: [],
    }
  },
}
export default function reducer(state = init, action) {
  switch (action.type) {
    case 'CLEAR_TOP_FIVE_OPPORTUNITIES': {
      return {
        ...state,
        clientTopFiveOpportunities: {}
      }
    }
    case 'SET_TOP_FIVE_OPPORTUNITIES': {
      let merchantId = action.payload.merchant_id;
      let topFive = action.payload.topFive;
      return {
        ...state,
        clientTopFiveOpportunities: { ...state.clientTopFiveOpportunities, [merchantId]: topFive }
      }
    }
    case 'SET_LOAD_FAIL': {
      return {
        ...state,
        loadFails: {
          ...state.loadFails,
          [action.payload.type]: action.payload.failed
        }
      }
    }
    case 'SET_LOAD_SUCCESS': {
      return {
        ...state,
        loadSuccesses: {
          ...state.loadSuccesses,
          [action.payload.type]: action.payload.success
        }
      }
    }
    case 'UPDATE_SIMILAR_ITEM': {
      const merchantId = action.payload.merchantId;
      const source = action.payload.source;
      const similarItem = action.payload.similarItem;
      const manufacturerProductId = source.manufacturer_product_id
      const index = action.payload.similarItemIndex;
      let similarItemsData;
      if(state.clientSimilarItems[merchantId] && state.clientSimilarItems[merchantId][manufacturerProductId]) {
        similarItemsData = JSON.parse(JSON.stringify(state.clientSimilarItems[merchantId][manufacturerProductId]));
        similarItem.loading = true;
        similarItemsData.savingItems.splice(index, 1, similarItem);
      }

      return {
        ...state,
        clientSimilarItems: { ...state.clientSimilarItems, [merchantId]: { [manufacturerProductId]: similarItemsData } }
      }
    }
    case 'SET_SIMILAR_ITEMS': {
      const merchantId = action.payload.merchantId;
      const similarItems = action.payload.similarItems;
      const manufacturerProductId = action.payload.manufacturerProductId
      return {
        ...state,
        clientSimilarItems: { ...state.clientSimilarItems, [merchantId]: { [manufacturerProductId]: similarItems } }
      }
    }

    case 'SET_SIMILAR_ITEM_COUNTS': {
      const merchantId = action.payload.merchantId;
      const clientSimilarItemCounts = action.payload.similarItemCounts;
      let similarItemsObj = {};
      clientSimilarItemCounts.forEach(item => {
        let source = state.purchasesByClient[merchantId].purchases.find(p => p.manufacturer_product_id.toString() === item.manufacturerProductId.toString());
        similarItemsObj[item.manufacturerProductId.toString()] = { savingItems: item.savingItems, source: source }
      });
      return {
        ...state,
        clientSimilarItemCounts: { ...state.clientSimilarItemCounts, [merchantId]: clientSimilarItemCounts },
        clientSimilarItems: { ...state.clientSimilarItems, [merchantId]: similarItemsObj },
        loaded: { ...state.loaded, similarItems: true }
      }
    }

    case 'SET_CLIENT_LIST_VIEW': {
      return {
        ...state,
        clientListView: action.payload
      }
    }
    case 'UPDATE_OPPORTUNITIES_PURCHASES_BY_CLIENT': {
      let merchantId = action.payload.merchantId;
      return {
        ...state,
        purchasesByClient: {
          ...state.purchasesByClient, [merchantId]: { ...state.purchasesByClient[merchantId], purchases: action.payload.data }
        }
      }
    }

    case 'SET_OPPORTUNITIES_DETAIL_DATE_RANGE':
      let merchantId = action.payload.merchantId;
      let startDate = action.payload.fromDate;
      let endDate = action.payload.toDate;
      return {
        ...state,
        clientDateRanges: { ...state.clientDateRanges, [merchantId]: { startDate, endDate } },
        // detailFromDate: action.payload.fromDate,
        // detailToDate: action.payload.toDate
      }
    case 'SET_OPPORTUNITIES_DATE_RANGE':
      return {
        ...state,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate
      }
    case 'SET_OPPORTUNITIES_CLIENT_SEARCH':
      return {
        ...state,
        clientSearch: action.payload
      }
    case 'SET_OPPORTUNITIES_ITEM_SEARCH':
      return {
        ...state,
        itemSearch: action.payload
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: { ...state.loading, [action.payload.type]: action.payload.state },
        loaded: { ...state.loaded, [action.payload.type]: false }
      }
    case 'SET_LOADED':
      return {
        ...state,
        loading: { ...state.loading, [action.payload.type]: false },
        loaded: { ...state.loaded, [action.payload.type]: true }
      }
    case 'SET_OPPORTUNITIES_ITEM_SORT_BY':
      let sortBy = action.payload.sortBy;
      let sortDesc = action.payload.sortDesc
      return {
        ...state,
        itemSortState: {
          sortBy: sortBy ? sortBy : state.itemSortState.sortBy,
          sortDesc: sortDesc ? sortDesc : sortBy === state.itemSortState.sortBy ? !state.itemSortState.sortDesc : state.itemSortState.sortDesc
        }
      }
    case 'SET_OPPORTUNITIES_SIMILAR_ITEM_SORT_BY': {
      let sortBy = action.payload.sortBy;
      let sortDesc = action.payload.sortDesc
      return {
        ...state,
        similarItemSortState: {
          sortBy: sortBy ? sortBy : state.itemSortState.sortBy,
          sortDesc: sortDesc ? sortDesc : sortBy === state.itemSortState.sortBy ? !state.itemSortState.sortDesc : state.itemSortState.sortDesc
        }
      }
    }
    case 'SET_OPPORTUNITIES_CLIENT_SORT_BY':
      let clientSortBy = action.payload.sortBy;
      let clientSortDesc = action.payload.sortDesc
      return {
        ...state,
        clientSortState: {
          sortBy: clientSortBy ? clientSortBy : state.clientSortState.sortBy,
          sortDesc: clientSortDesc ? clientSortDesc : clientSortBy === state.clientSortState.sortBy ? !state.clientSortState.sortDesc : state.clientSortState.sortDesc
        }
      }
    case 'SET_OPPORTUNITIES_DASHBOARD':
      let purchasesByClient = {};
      let clientDateRanges = {};
      action.payload.clients.forEach(client => {
        clientDateRanges[client.platformId] = { startDate: state.fromDate, endDate: state.toDate };
        purchasesByClient[client.platformId] = { purchases: client.items };
      })
      return {
        ...state,
        clients: action.payload.clients,
        purchasesByClient: purchasesByClient,
        clientDateRanges,
        overviewStats: {
          totalClients: action.payload.totalClients,
          complianceRate: numeral(action.payload.complianceRatePercentage).format('0,0.00') + '%',
          graph: {
            topClients: action.payload.graph.topClients.sort((a, b) => {
              return b.rebate - a.rebate
            }),
            top25Opportunities: action.payload.graph.topOpportunities.map(o => {
              let topPurchase = o.purchases[0]
              return {
                name: `${topPurchase.brand} ${topPurchase.description}`,
                displayName: topPurchase.brand,
                rebate: topPurchase.rebate
              }
            }).sort((a, b) => {
              return b.rebate - a.rebate;
            })
          }
        }
      };
      case 'SET_ITEM_TO_VIEW_SIMILAR_ITEMS_FOR': {
        return {
          ...state,
          itemInViewForSimItems: action.payload
        }
      }
    default:
      return state;
  }
}

