import React from 'react';
import useModal from '../../../sharedComponents/useModal/useModal';
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { MdClose } from 'react-icons/md';


const ConfirmModal = ({ id, action, Icon, actionName, title, text, buttonText }) => {
  const { modalProps } = useModal(id, { isMenu: true, isFullscreen: true });



  if (!isMobile) {
    // modalProps.className = 'img-view-upload-modal-wrapper';
    // modalProps.contentClassName = 'img-view-upload-modal';
  }

  const confirm = () => {
    modalProps.toggle();
    setTimeout(() => {
      action();
    }, 300);
  }

  return (
    <Modal {...modalProps} className='d-flex align-items-center' style={{ paddingTop: '150px' }}>
      <Row className="m-0 justify-content-between d-flex font-weight-bold p-3">
        <Col xs="8">{actionName}</Col>
        <MdClose onClick={modalProps.toggle} size={25} className='clickable' />
      </Row>
      <ModalBody style={{ marginTop: '200px !important', maxHeight: '80vh', overflowY: 'scroll' }} className={`${isMobile ? 'px-1' : ''} position-relative`} >
        <Row className='mx-0 d-flex justify-content-center' style={{ marginTop: '20px' }}>
          {Icon ? <Icon size={40} className='text-red' /> : false}
        </Row>
        <Row className='mx-0 d-flex justify-content-center' style={{ marginTop: '20px' }}>
          {title}
        </Row>
        <Row className='mx-0 d-flex justify-content-center text-size-14' style={{ marginTop: '20px' }}>
          {text}
        </Row>
        <Row className='mx-0 d-flex justify-content-center text-size-14' style={{ marginTop: '20px' }}>
          <Col xs={6}><Button outline onClick={modalProps.toggle} className='py-2 button-label w-100 btn-outline-red border-2'>Cancel</Button></Col>
          <Col xs={6}><Button onClick={confirm} className='py-2 button-label w-100 border-2' color={'red'}>{buttonText}</Button> </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmModal;