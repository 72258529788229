import React, { useState } from 'react';
import numeral from 'numeral';
import { Row, Col } from 'reactstrap';
import { If, Then, Else } from 'react-if';
import useDashboardChart from '../../../../sharedComponents/useDashboardChart/useDashboardChart';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { GoPrimitiveDot } from 'react-icons/go';
import { IoMdArrowDropdown, IoMdArrowDropup, IoIosArrowForward } from 'react-icons/io';
import { randomColors } from '../../../../config/constants';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { itemDrilldownFromDashboard, categoryDrilldownFromDashboard, redirectFromDashboardToItemDetails } from '../../../../reducers/DashboardReducer/DashboardActions';
import { isMobile } from 'react-device-detect';

// const MAXIMUM_DISPLAYED_ITEMS = 15;
const PAGE_SIZE_DISPLAYED_ITEMS = 5;
const SHOW_MORE = "Show more";
const SHOW_LESS = "Show less";

const DashboardTopChart = ({ data, sortBy, leftSide }) => {
  const [displayedItems, setDisplayedItems] = useState(PAGE_SIZE_DISPLAYED_ITEMS);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleShowMore = () => {
    setDisplayedItems(displayedItems + PAGE_SIZE_DISPLAYED_ITEMS);
  }

  const handleShowLess = () => {
    setDisplayedItems(displayedItems - PAGE_SIZE_DISPLAYED_ITEMS);
  }

  const handleClick = (element) => {
    element.item ? handleItemDrilldown(element) : handleCategoryDrilldown(element);
    dispatch(redirectFromDashboardToItemDetails(true, sortBy));
    history.push('/competitiveinsights');
  }

  const handleItemDrilldown = (element) => {
    dispatch(itemDrilldownFromDashboard([element.item]));
    //console.log('dashboard row clicked, label: ' + element.item);
  }

  const handleCategoryDrilldown = (element) => {
    dispatch(categoryDrilldownFromDashboard([element.category]));
    //console.log('dashboard row clicked, label: ' + element.category);
  }

  const sortByData = (data) => {
    if (sortBy) {
      if (sortBy === 'volume') {
        return data.volume ? data.volume : 0;
      }
      else if (sortBy === 'rebate') {
        return data.rebate ? data.rebate : 0;
      }
      else if (sortBy === 'deviation') {
        return data.deviation ? data.deviation : 0;
      }
      else {
        // default
        return data.volume ? data.volume : 0;
      }
    }
    else {
      return data.volume ? data.volume : 0;
    }
  }

  // Don't show any 0 entries in the chart or legend
  const filteredData = data.filter(i => sortByData(i) !== 0);

  const mapChartData = () => {
    const chartData = [];

    for (let i = 0; i < filteredData.length; i++) {
      if (i < displayedItems) {
        const label = filteredData[i].item ? filteredData[i].item : filteredData[i].category;
        chartData.push({
          name: label,
          volume: sortByData(filteredData[i]),
          color: randomColors[i]
        });
      }
      else break;
    }

    return chartData;
  }

  const { chartOptions } = useDashboardChart({ noData: false, data: mapChartData(), key: 'volume' });
  const legendNamesAndVolumesMap = filteredData.map((e, i) => {
    if (i < displayedItems) {
      const label = e.item ? e.item : e.category;
      const amount = numeral(sortByData(e)).format('$0,0.00');
      return (
        <Row
          onClick={() => handleClick(e)}
          key={`${label}-${i}`}
          style={{ padding: '.7rem 0 .7rem 0' }}
          className='clickable hover-row rounded-left chart-hover'>
          <Col xs='auto' className='pl-2 px-0 d-flex align-items-center' >
            <GoPrimitiveDot className='text-size-20' color={randomColors[i]} />
          </Col>
          <Col xs={isMobile ? 5 : 6} className='px-1'>
            {label}
          </Col>
          <Col xs={4} className='text-right'>
            {amount}
          </Col>
          <Col className="pl-2">
            <IoIosArrowForward />
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  });

  return (
    <Col className={`w-50 border-bottom position-relative ${leftSide ? 'border-right' : ''} ${isMobile ? 'pb-2' : ''}`}>
      <Row style={{ overflowY: 'scroll', maxHeight: '500px' }} className='align-items-top py-3'>
        <Col xs={isMobile ? 12 : 4} className='' style={{ position: 'sticky', top: 0, maxHeight: '400px' }}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={{ className: 'highcharts-parent' }} />
        </Col>
        <Col className='text-size-14'>
          {legendNamesAndVolumesMap}
          <Row>
            <If condition={
              (displayedItems < data.length - 1) && (displayedItems < filteredData.length)
            }>
              <Then>
                <Col>
                  <div className="see-details-label text-beplMagenta clickable"
                    onClick={handleShowMore}>
                    {SHOW_MORE} <IoMdArrowDropdown />
                  </div>
                </Col>
              </Then>
              <Else>
                <Col></Col>
              </Else>
            </If>
            <If condition={displayedItems > data.length - 1}>
              <Then>
                <Col>
                  <div className="see-details-label text-beplMagenta clickable"
                    onClick={handleShowLess}>
                    {SHOW_LESS} <IoMdArrowDropup />
                  </div>
                </Col>
              </Then>
              <Else>
                <Col></Col>
              </Else>
            </If>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default DashboardTopChart;
