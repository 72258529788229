import React from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useDashboardLineChart from './useDashboardLineChart';
import moment from 'moment';
import { BsSearch } from 'react-icons/bs';
import { colors } from '../../../../config/constants';

const MAX_SERIES = 6; // Max of 1 distributor + all competitors + top 4 competitors

const DashboardMonthlyVolume = (props) => {
  // Convert volume data to series data usable by chart
  const dist = [];
  const allComps = [];
  const comps = [];
  const compIDs = [];
  let distVolume = 0;

  if (props.volumeByMonth?.length > 0) {
    // Set up initial empty series
    dist.push({ name: 'You', data: [] });
    allComps.push({ name: 'All Competitors', data: [] });

    // Build & initialize the competitor series across all months
    for (const month of props.volumeByMonth) {
      for (const comp of month.competitors) {
        if (!compIDs.includes(comp.id)) {
          compIDs.push(comp.id);
          comps.push({ id: comp.id, data: new Array(props.volumeByMonth.length).fill(0), totalVolume: 0 });
        }
      }
    }

    // Populate series with monthly data
    for (let i = 0; i < props.volumeByMonth.length; i++) {
      // Distributor series
      dist[0].data.push(props.volumeByMonth[i].distributor);
      distVolume = distVolume + props.volumeByMonth[i].distributor;
      // All Comps series
      allComps[0].data.push(props.volumeByMonth[i].allCompVolume);
      // Individual Comp series
      for (const comp of props.volumeByMonth[i].competitors) {
        const matchComp = comps.find((c) => c.id === comp.id);
        if (matchComp) {
          matchComp.totalVolume = comp.volume;
          matchComp.data[i] = matchComp.data[i] + comp.volume;
        }
      }
    }

    // No point in showing All Competitors if there are 0 or 1 competitors
    if (comps.length < 2) {
      allComps.pop();
    }

    // Don't show distributor series if they have no volume
    if (distVolume === 0) {
      dist.pop();
    }

    // Sort competitors by total volume, descending
    comps.sort((a, b) => b.totalVolume - a.totalVolume);

    for (let i = 0; i < comps.length; i++) {
      comps[i].name = `Distributor ${i + 1}`;
    }
  }
  
  // Save only top 5 series, if there are more than that
  const data = [...dist, ...allComps, ...comps].slice(0, MAX_SERIES);

  const startDateMoment = new Date(moment().subtract(props.volumeChartPeriod.value, 'month').startOf('month'));
  const chartStartDate = Date.UTC(
    startDateMoment.getUTCFullYear(), 
    startDateMoment.getUTCMonth(), 
    startDateMoment.getUTCDate()
  );

  const { chartOptions } = useDashboardLineChart(props.volumeLoading ? null : data, chartStartDate, props.logarithmic);

  return (
    <Col className={'w-50 py-4 border-bottom'}>
      { props.volumeLoading ? 
        <Row className='text-center d-flex flex-column'>
          <Col><Spinner color='primary' /></Col>
        </Row>
        :
        <Row className='align-items-center'>
          { !props.volumeLoadFailed ? 
          <Col>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={{ className: 'highcharts-parent h-300px' }} /> 
          </Col>
          :
          <Col>
            <Row className='text-center justify-content-center p-5'>
              <BsSearch color={colors.beplMagenta} size={45} />
            </Row>
            <Row xs={12} className='text-center justify-content-center'>
              Chart data not available
            </Row>
          </Col>
          }
        </Row>
      }
    </Col>
  );
}

export default DashboardMonthlyVolume;