import { useDispatch } from "react-redux";
import userApi from "../../../api/userApi";
import { useState } from "react";
import { showModal } from "../../../reducers/ModalsReducer/ModalsActions";
import SuccessModal from './SuccessModal';
import ErrorModal from "./ErrorModal";
import { userRoles } from "../../../config/constants";
const useInviteModal = (selectedDistributor, addUserToList, modalProps) => {

  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState();
  const [displayIntel, setDisplayIntel] = useState(false);
  const [displayPaymentInfo, setDisplayPaymentInfo] = useState(false); 
  const [loading, setLoading] = useState(false);

  const roles = [...userRoles];

  const userInviteFunc = async () => {
    let payload = {
      contactName: name,
      email: email,
      locations: [],
      title: role.label,
      displayIntel: displayIntel,
      displayPaymentInfo: displayPaymentInfo,
      displayInvoice: false,
      isMultiHouse: false,
      distributorId: selectedDistributor.data.id,
      referredPartnerId: selectedDistributor.data.referredPartner.id,
      siteUrl: window.location.origin
    }

    try {
      setLoading(true);
      const { data } = await userApi.userInvite.post(payload);
      let user = {
        ...payload, id: data.id, 
        passwordResetToken: data.passwordResetToken,
        activeDistributor: {
        id: selectedDistributor.data.id,
        nickname: selectedDistributor.label
      }, 
      distributor: selectedDistributor.label 
    }
      addUserToList(user);
      modalProps.toggle();
      setLoading(false);

      dispatch(showModal(SuccessModal, {
        title: 'User Invited',
        text1: name +' was successfully invited!',
        text2: 'The invite will go to ' + email
      }))
    } catch (err) {
      setLoading(false);
      dispatch(showModal(ErrorModal, { title: 'Error', text1: 'Failed to invite user', text2: 'Try again' }))
    }
  }

  return {
    userInviteFunc,
    email, 
    role, 
    setRole,
    name,
    setName,
    setEmail,
    roles,
    displayIntel,
    displayPaymentInfo,
    setDisplayIntel,
    setDisplayPaymentInfo,
    loading
  }
}

export default useInviteModal;