import { createStore, combineReducers } from 'redux';
import user from './UserReducer/UserReducer';
import intel from './IntelReducer/IntelReducer';
import rebate from './RebateReducer/RebateReducer';
import ui from './UIReducer/UIReducer';
import modals from './ModalsReducer/ModalsReducer';
import dashboard from './DashboardReducer/DashboardReducer';
import notifications from './NotificationsReducer/NotificationsReducer'
import invoices from './InvoiceScanningReducer/InvoiceScanningReducer';
import opportunities  from './OpportunitiesReducer/OpportunitiesReducer'
import rebateChecks from './RebateChecksReducer/RebateChecksReducer';

const ENV = process.env.NODE_ENV;

const reducer = combineReducers({
  user,
  intel,
  rebate,
  ui,
  modals,
  dashboard,
  notifications,
  invoices,
  opportunities,
  rebateChecks
});

// Redux DevTools: removes large objects from the actions
const actionSanitizer = (action) => {
  switch (action.type) {
    case 'SET_INTEL_MERCHANT_DATA':
    case 'SET_COMPETITIVE_FILTERED_MERCHANTS':
      return action.payload ? { ...action, payload: '<<MERCHANT_PAYLOAD>>' } : action;
    case 'SET_COMPETITIVE_INSIGHT_DATA':
    case 'SET_COMPETITIVE_INSIGHT_ITEMS':
    case 'SET_COMPETITIVE_FILTERED_ITEMS':
      return action.payload ? { ...action, payload: '<<ITEM_PAYLOAD>>' } : action;
    case 'SET_COMPETITIVE_ORDER_HISTORY_PURCHASES':
      return action.payload ? { ...action, payload: '<<ORDER_HISTORY_PAYLOAD>>' } : action;
    case 'SET_UPLOADED_INVOICE_IMAGES':
      return action.payload ? { ...action, payload: '<<UPLOADED_INVOICE_IMAGES>>' } : action;
    default:
      return action;
  }
};

// Redux Devtools: removes large objects from the state
const stateSanitizer = (state) => (
  state.intel ? {
    ...state,
    invoices: {
      ...state.invoices,
      uploadedInvoiceImages: '<<UPLOADED_INVOICE_IMAGES>>'
    },
    intel: {
      ...state.intel,
      merchants: '<<MERCHANT_DATA>>',
      filteredCompMerchantsList: '<<MERCHANT_DATA>>',
      competitiveInsightData: '<<ITEM_DATA>>',
      filteredCompItemsList: '<<ITEM_DATA>>',
      competitiveInsightItems: '<<ITEM_DATA>>',
      competitiveOrderHistoryPurchases: '<<ORDER_HISTORY_DATA>>'
    }
  } : state
);

export default createStore(reducer,
  ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({
    actionSanitizer,
    stateSanitizer
  }));