import Axios from 'axios';

const url = '/rebate-deviation';
const url2 = '/full-rebate-deviation';

const rebateDeviationApi = {
  get: (params) => {
    return Axios.get(`${url}`, {
      params : params
    })
  },
  getAll: (params) => {
    return Axios.get(`${url2}`, {
      params : params
    })
  },
  getFilters: () => {
    return Axios.get(`${url}/filters`)
  }
}

export default rebateDeviationApi;
