import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { If, Then, Else } from 'react-if';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { MdOutlineTag, MdFitnessCenter } from 'react-icons/md';
import { FaPercent } from 'react-icons/fa';
import { IoIosPricetags } from 'react-icons/io'

const TableHeader = ({ sortBy, sortState }) => {
  const [popup, showPopup] = useState(false);
  const [sortType, setSortType] = useState('PERCENT_OF_COST');

  const setSort = (type) => {
    setSortType(type);
    sortBy(type, true);
  }
  let rebateTypeIcon = () => {
    return sortType === 'PERCENT_OF_COST' ? < FaPercent className='ml-2' size={9} /> :
      sortType === 'FIXED_AMOUNT_PER_WEIGHT' ? < MdFitnessCenter className='ml-2' size={10} /> :
        sortType === 'FIXED_AMOUNT_PER_QTY' ? <MdOutlineTag className='ml-2' size={11} /> :
          <IoIosPricetags className='ml-2' size={10} />
  }

  let rebateTypePopup = () => {
    return (
      <div style={{ whiteSpace: 'nowrap' }} className='bg-white rounded  position-absolute z-1050 text-size-16 shadow mt-1'>
        <div onClick={() => setSort('PERCENT_OF_COST')} className='p-3 align-items-center d-flex'>
          <FaPercent className='mr-2' size={11} color="#CE3F7C" />
          Percent Of Cost
        </div>
        <div onClick={() => setSort('PERCENT_OF_PRICE')} className='p-3 align-items-center d-flex border-top'>
          <IoIosPricetags className='mr-2' size={12} color="#CE3F7C" />
          Percent Of Price
        </div>
        <div onClick={() => setSort('FIXED_AMOUNT_PER_WEIGHT')} className='p-3 align-items-center d-flex border-top'>
          <MdFitnessCenter className='mr-2' size={12} color="#CE3F7C" />
          Fixed Amount Per Weight
        </div>
        <div onClick={() => setSort('FIXED_AMOUNT_PER_QTY')} className='p-3 align-items-center d-flex border-top'>
          <MdOutlineTag className='mr-2' size={13} color="#CE3F7C" />
          Fixed Amount Per Quantity
        </div>
      </div>
    )
  }
  return (
    <Col className='position-sticky' style={{top: 0, zIndex: 50}} >
      <Row className='text-muted text-size-10 line-height-11 bg-white py-2 border-bottom'>
        <Col xs={1} onClick={() => sortBy('din')} className={'clickable' + (sortState.sortBy === 'din' ? ' text-primary' : '')}>
          DIN
          <If condition={sortState.sortBy === 'din' && !sortState.sortDesc}>
            <Then>
              <IoMdArrowRoundUp className='ml-2' />
            </Then>
            <Else>
              <IoMdArrowRoundDown className='ml-2' />
            </Else>
          </If>
        </Col>
        <Col xs={1} onClick={() => sortBy('min')} className={'clickable' + (sortState.sortBy === 'min' ? ' text-primary' : '')}>
          MIN
          <If condition={sortState.sortBy === 'min' && !sortState.sortDesc}>
            <Then>
              <IoMdArrowRoundUp className='ml-2' />
            </Then>
            <Else>
              <IoMdArrowRoundDown className='ml-2' />
            </Else>
          </If>
        </Col>
        <Col xs={2} onClick={() => sortBy('description')} className={'clickable' + (sortState.sortBy === 'description' ? ' text-primary' : '')}>
          DESCRIPTION
          <If condition={sortState.sortBy === 'description' && !sortState.sortDesc}>
            <Then>
              <IoMdArrowRoundUp className='ml-2' />
            </Then>
            <Else>
              <IoMdArrowRoundDown className='ml-2' />
            </Else>
          </If>
        </Col>
        <Col xs={2} onClick={() => sortBy('manufacturer')} className={'clickable' + (sortState.sortBy === 'manufacturer' ? ' text-primary' : '')}>
          MANUFACTURER
          <If condition={sortState.sortBy === 'manufacturer' && !sortState.sortDesc}>
            <Then>
              <IoMdArrowRoundUp className='ml-2' />
            </Then>
            <Else>
              <IoMdArrowRoundDown className='ml-2' />
            </Else>
          </If>
        </Col>
        <Col xs={2} onClick={() => showPopup(popup ? false : true)} className={'clickable relative' + (sortState.sortBy === 'rebateType' ? ' text-primary' : '')}>
          REBATE TYPE
          {popup ? rebateTypePopup() : false}

          {rebateTypeIcon()}
        </Col>
        <Col xs={1} onClick={() => sortBy('rebateValue')} className={'px-0 clickable' + (sortState.sortBy === 'rebateValue' ? ' text-primary' : '')}>
          REBATE
          <If condition={sortState.sortBy === 'rebateValue' && !sortState.sortDesc}>
            <Then>
              <IoMdArrowRoundUp className='ml-2' />
            </Then>
            <Else>
              <IoMdArrowRoundDown className='ml-2' />
            </Else>
          </If>
        </Col>
        <Col xs={2} onClick={() => sortBy('deviationValue')} className={'px-0 clickable' + (sortState.sortBy === 'deviationValue' ? ' text-primary' : '')}>
          DEVIATED PRICE
          <If condition={sortState.sortBy === 'deviationValue' && !sortState.sortDesc}>
            <Then>
              <IoMdArrowRoundUp className='ml-2' />
            </Then>
            <Else>
              <IoMdArrowRoundDown className='ml-2' />
            </Else>
          </If>
        </Col>
        <Col xs={1} className={'px-0'}>
          SIMILAR ITEMS
        </Col>
      </Row>
    </Col>
  );
};

export default TableHeader;
