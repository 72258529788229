import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../reducers/ModalsReducer/ModalsActions';
import MatchFeedbackModal from '../../CompetitiveInsight/Subcomponents/Modals/ItemMatchesFeedback';
import toTitleCase from '../../../../sharedComponents/Helper/toTitleCase';
import truncateAfter96chars from '../../../../sharedComponents/Helper/truncateAfter96chars';
import numeral from 'numeral';
import { MdOutlineTag, MdFitnessCenter} from 'react-icons/md';
import { FaPercent } from 'react-icons/fa';
import { IoIosPricetags } from 'react-icons/io';

const RebatesItemMatchesRow = (props) => {
  const dispatch = useDispatch();
  let {
    min, description, manufacturerName, pack, rebateType, rebateValue, deviationValue, uom, inCatalog
  } = props.match;

  const showMatchFeedbackModal = (feedbackType, badMatchMin) => {
    dispatch(showModal(MatchFeedbackModal, {feedbackType, min: min, badMatchMin}));
  }

  description = toTitleCase(description);
  description = truncateAfter96chars(description);
  const rebateFormat = rebateValue ? numeral(rebateValue).format('0,0.00') : 'N/A';
  const rebateTypeSymbol = rebateType ? rebateType.toString().split('_') : '';
  const symbolPercentDollar = rebateTypeSymbol ? (rebateTypeSymbol.includes('PERCENT') ? '%' : '$') : '';
  const rebateWithSymbol = symbolPercentDollar === '$' ? symbolPercentDollar + rebateFormat : rebateFormat + symbolPercentDollar;
  const deviationDollar = deviationValue ? '$' + numeral(deviationValue).format('0,0.00') : 'N/A';
  const inCatalogString = inCatalog ? 'Y' : 'N';

  //renders a different symbol for each rebate type; defaults to N/A if there is no rebate type
  let rebateTypeIcon;
  switch (rebateType) {
    case 'FIXED_AMOUNT_PER_QTY':
      rebateTypeIcon = <MdOutlineTag title='FIXED_AMOUNT_PER_QTY' />;
      break;
    case 'FIXED_AMOUNT_PER_WEIGHT':
      rebateTypeIcon = <MdFitnessCenter title='FIXED_AMOUNT_PER_WEIGHT' />;
      break;
    case 'PERCENT_OF_COST':
      rebateTypeIcon = <FaPercent className='text-size-12' title='PERCENT_OF_COST' />;
      break;
    case 'PERCENT_OF_PRICE':
      rebateTypeIcon = <IoIosPricetags title='PERCENT_OF_PRICE' />;
      break;
    default:
      rebateTypeIcon = 'N/A';
      break;
  }
  
  if (isMobile) {
    return (
      <div className='p-3 border bg-white'>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>MIN</Col>
          <Col className='text-right'>
            {min}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Description</Col>
          <Col className='text-right'>
            {description}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Manufacturer</Col>
          <Col className='text-right'>
            {manufacturerName}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Pack</Col>
          <Col className='text-right'>
            {pack}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>In Catalog</Col>
          <Col className='text-right'>
            {inCatalogString}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Rebate Type</Col>
          <Col className='text-right'>
            {rebateTypeIcon}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Rebate Value</Col>
          <Col className='text-right'>
            {rebateWithSymbol}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Deviation</Col>
          <Col className='text-right'>
            {deviationDollar}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>UOM</Col>
          <Col className='text-right'>
            {uom}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-row align-items-center justify-content-end py-2" xs={12}>
            <div onClick={() => showMatchFeedbackModal('badMatch', min)} className='ml-1 text-size-14 text-bold reveal-label clickable text-beplMagenta'>Give Feedback</div>
          </Col>
        </Row>
      </div>
    );
  }
  else {
    return (
      <CardBody className='border-top py-2 px-3 hover-row'>
        <Row className='align-items-center'>
          <Col className='text-size-14'  xs={1}>
            {min}
          </Col>
          <Col className='text-size-14' xs={2}>
            {description}
          </Col>
          <Col className='text-size-14' xs={2}>
            {manufacturerName}
          </Col>
          <Col className='text-size-14' xs={1}>
            {pack}
          </Col>
          <Col className='text-size-14' xs={1}>
            {inCatalogString}
          </Col>
          <Col className='text-size-14' xs={1}>
            {rebateTypeIcon}
          </Col>
          <Col className='text-size-14' xs={1}>
            {rebateWithSymbol}
          </Col>
          <Col className='text-size-14' xs={1}>
            {deviationDollar}
          </Col>
          <Col className='text-size-14' xs={1}>
            {uom}
          </Col>
          <Col className="d-flex flex-row align-items-center" xs={1}>
            <div onClick={() => showMatchFeedbackModal('badMatch', min)} className='ml-1 text-size-14 text-bold reveal-label clickable text-beplMagenta'>Give Feedback</div>
          </Col>
        </Row>
      </CardBody>

    );
  }
}

export default RebatesItemMatchesRow;