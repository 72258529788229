import uniqid from 'uniqid';

export const showModal = (component, props) => {
  return {
    type: 'ADD_MODAL',
    payload: {
      component,
      props: {
        ...props,
        id: uniqid('modal-'),
        isOpen: true
      }
    }
  };
};

export const toggleModal = (id) => {
  return {
    type: 'TOGGLE_MODAL',
    payload: id
  };
};

export const hideModal = (id) => {
  return {
    type: 'HIDE_MODAL',
    payload: id
  };
};

export const removeModal = (id) => {
  return {
    type: 'REMOVE_MODAL',
    payload: id
  };
};

export const updateModal = (id, props) => {
  return {
    type: 'UPDATE_MODAL',
    payload: {
      id: id,
      props: props
    }
  }
};