import LocalStorage from '../LocalStorage/LocalStorage';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../reducers/UserReducer/UserActions';

const useLogin = () => {
  const dispatch = useDispatch();

  const login = (data) => {
    const { authkey, authKey, passwordResetToken, ...userData } = data;
    LocalStorage.authKey.set(authkey || authKey);
    LocalStorage.resetKey.set(passwordResetToken);
    LocalStorage.userData.set(userData);

    dispatch(setUserData(data));
  };

  return {
    login
  };
};

export default useLogin;