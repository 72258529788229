import React, { useState } from 'react';
import { Row, Col, Input, Button, FormText, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import useLoginForm from './useLoginForm';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { If } from 'react-if';
import authApi from '../../api/authApi';
import AlertModal from '../../sharedComponents/AlertModal/AlertModal';
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import useLogin from '../../sharedComponents/useLogin/useLogin';
import { spinner } from '../../reducers/UIReducer/UIActions';
import LocalStorage from '../../sharedComponents/LocalStorage/LocalStorage';
import { withMsal } from '@azure/msal-react';
import useMsal from '../../sharedComponents/useMsal/useMsal';

const LoginForm = (props) => {
  const activateMsg = props.activateMsg;
  const { email, password, validate } = useLoginForm();
  const [apiError, setApiError] = useState('');
  const { login } = useLogin();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loginPopup } = useMsal();

  const saveAdminCreds = (params) => {
    if (params.email.includes('@admin.com')) {
      params.name = 'distributor admin'
      LocalStorage.adminUserData.set(params);
    }
  }

  const onLogin = (e) => {
    e.preventDefault();
    setApiError('');
    const errors = validate();
    if (!errors) {
      const params = {
        email: email.value,
        password: password.value
      };
      dispatch(spinner.add());
      authApi.login.post(params).then(({ data }) => {
        dispatch(spinner.subtract());
        saveAdminCreds(params)
        data.initLogin = true;
        login(data);
      }).catch(err => {
        dispatch(spinner.subtract());
        let msg = `Something went wrong`
        if (err) {
          if (err.data && err.data.message) {
            msg = err.data.message;
          }
        }
        const modalProps = {
          title: msg,
          buttonClick: () => {
            history.push('/login');
          }
        };
        dispatch(showModal(AlertModal, modalProps));
        setApiError(msg);
      });
    }
  };

  const goToSignUp = () => {
    history.push('/signup');
  };

  const goToForgotPassword = () => {
    history.push('/forgot-password');
  };

  return (
    <Form className="text-size-14" onSubmit={onLogin}>
      <If condition={activateMsg}>
        <FormGroup>
          <FormText color='primary'>Your account has been activated! Please Login.</FormText>
        </FormGroup>
      </If>
      <FormGroup>
        <Label className='label-text-header' for='email'>Email</Label>
        <Input {...email} />
        <FormFeedback>{email.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header' for='password'>Password</Label>
        <span onClick={goToForgotPassword} className='text-beplMagenta form-label float-right clickable'>Forgot Password?</span>
        <Input {...password} type='password' />
        <FormFeedback>{password.message}</FormFeedback>
      </FormGroup>
      <Button type='submit' block color='beplMagenta' className='py-2 button-label'>Login</Button>
      <Button block color='blue' onClick={() => loginPopup(props.msalContext.instance, false)} className='py-2 button-label'>Login with Microsoft</Button>
      <FormGroup>
        <FormText color='red'>{apiError}</FormText>
      </FormGroup>
      <Row className='mb-5'>
        <Col onClick={goToSignUp} className='text-beplMagenta form-label text-center clickable'>Don't have an account? Sign Up</Col>
      </Row>
    </Form>
  );
};

export default withMsal(LoginForm);