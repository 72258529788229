import React from 'react';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { CardBody, Row, Col } from 'reactstrap';
import { If, Then, Else } from 'react-if';

const CompetitiveInsightMerchantSortHeader = ({ sortState, sortBy }) => {
  const sortableColumn = (shortName, longName) => {
    return (
      <Col onClick={() => sortBy(shortName)}
        className={'clickable' + (sortState.sortBy === shortName ? ' text-primary' : '')}
      >
        {longName}
        <If condition={sortState.sortBy === shortName && !sortState.sortDesc}>
          <Then>
            <IoMdArrowRoundUp className='ml-2' />
          </Then>
          <Else>
            <IoMdArrowRoundDown className='ml-2' />
          </Else>
        </If>
      </Col>
    );
  }

  return (
    <CardBody className='py-2 bg-white border-top border-left border-right position-sticky' style={{ top: '0px', zIndex: 50 }}>
      <Row className='text-muted text-size-10 line-height-11 text-uppercase'>
        {sortableColumn('dcn', 'Account Number')}
        {sortableColumn('merchant', 'Customer Name')}
        {sortableColumn('totalVolume', 'Total Volume')}
        {sortableColumn('competitorVolume', 'Competitor Volume')}
        {sortableColumn('competitorVolumePerc', '% Competitor Volume')}
        <Col></Col>{/* empty column to line up with "See details" links */}
      </Row>
    </CardBody>
  );
};

export default CompetitiveInsightMerchantSortHeader;