import Axios from "axios";

const url = '/match';
const url2 = '/full-rebate-deviation/matches'
const matchApi = {
  post: (params) => {
    return Axios.post(`${url}`, params);
  },
  full: {
    post: (params) => {
      return Axios.post(`${url2}`, params);
    }
  },
  feedback: {
    post: (params) => {
      return Axios.post(`${url}/feedback`, params);
    }
  }

};

export default matchApi;