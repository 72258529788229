import React from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import { IoIosArrowDown } from 'react-icons/io';
import { If, Then, Else } from 'react-if';
import { useSelector } from 'react-redux';

const IntelLineChartLoadMore = ({onClick}) => {
  const allLoaded = useSelector(({intel}) => intel.allLoaded);
  return (
    <Row className='text-primary clickable pb-5 justify-content-center align-items-center mt-3' onClick={onClick}>
      <Col className='w-fc pr-0'> Show More </Col>
      <Col className='w-fc'>
        <If condition={allLoaded}>
          <Then>
            <IoIosArrowDown className='w-fc' />
          </Then>
          <Else>
            <Spinner color='primary' size='sm' />
          </Else>
        </If>
      </Col>
    </Row>
  );
};

export default IntelLineChartLoadMore;