export const setUploadedInvoicesData = (data) => {
  return {
    type: 'SET_UPLOADED_INVOICES_DATA',
    payload: data
  }
}

export const setUploadedInvoiceImages = (images) => {
  return {
    type: 'SET_UPLOADED_INVOICE_IMAGES',
    payload: images
  }
}
export const setSortBy = (view, key, desc) => {
  return {
    type: 'SET_SORT',
    payload: {
      view, key, desc
    }
  }
};