import React, { Fragment } from 'react';
import AppMainRouter from './AppMainRouter';
import Header from '../../sharedComponents/Header/Header';
import { If, Then, Else } from 'react-if';
import { isMobile } from 'react-device-detect';
import HeaderLogo from '../../sharedComponents/Header/HeaderLogo';
import useInitUser from './useInitUser';
import useIntel from './Intel/useIntel';
import BlankContainer from '../../sharedComponents/BlankContainer/BlankContainer';
import useDashboard from './Dashboard/useDashboard';
import BurgerMenu from '../../sharedComponents/BurgerMenu/BurgerMenu';
import useTrackUserSession from '../../sharedComponents/Analytics/TrackUserSession';

const AppMain = () => {
  const ready = useInitUser(true);
  useTrackUserSession();
  useDashboard.useDashboardStats();
  useDashboard.useDashboardVolumeStats();
  useIntel.useIntelState();

  return (
    <Fragment>
      <If condition={isMobile}>
        <Then>
          <HeaderLogo logo={process.env.REACT_APP_COMPANY_LOGO} />
        </Then>
        <Else>
          <Header />
        </Else>
      </If>
      <If condition={ready}>
        <Then>
          <AppMainRouter />
        </Then>
        <Else>
          <BlankContainer />
        </Else>
      </If>
      <If condition={isMobile}>
        <Then>
          <BurgerMenu />
        </Then>
      </If>
    </Fragment>
  );
};

export default AppMain;