import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import DataTable from './DataTable/DataTable';
import ItemTable from './ItemTable/ItemTable';
import { showModal } from '../../../../reducers/ModalsReducer/ModalsActions';
import AlertModal from '../../../../sharedComponents/AlertModal/AlertModal';

const DebugDetails = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { pathname } = history.location;
    const merchantId = pathname.split('/')[2];
    const merchantSelected = useSelector(({intel}) => intel.merchants.filter(m => m.id === merchantId)[0]);
    const { id, merchant, data, totalVolume, competitorVolume, competitorVolumePerc, distributorVolume, distributorVolumePerc, dcn } = merchantSelected;

    const logMerchant = () => {
        dispatch(showModal(AlertModal, {title: `Details for ${merchantSelected.merchant} have been sent to the console.`}));
    }

    return (
        <Fragment>
            <Col className='pt-2 text-size-16 text-center'>
                <Row className='main-header text-size-24 text-beplBlue d-flex justify-content-center pb-2'>
                    Debug Details
                </Row>
                <Col>
                    <table className='table table-bordered table-striped'>
                        <thead>
                            <tr>
                                <th scope='col'>ID</th>
                                <th scope='col'>Merchant</th>
                                <th scope='col'>Total Volume</th>
                                <th scope='col'>Competitor Volume</th>
                                <th scope='col'>Competitor Volume %</th>
                                <th scope='col'>Distributor Volume</th>
                                <th scope='col'>Distributor Volume %</th>
                                <th scope='col'>DCN</th>
                                <th scope='col'>Send Merchant to Console</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{id}</td>
                                <td>{merchant}</td>
                                <td>{totalVolume}</td>
                                <td>{competitorVolume}</td>
                                <td>{competitorVolumePerc}</td>
                                <td>{distributorVolume}</td>
                                <td>{distributorVolumePerc}</td>
                                <td>{dcn}</td>
                                <td>
                                    <Col className='text-beplMagenta clickable font-weight-bold' onClick={() => logMerchant()}>
                                        Send
                                    </Col>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col>
                    <Row className='main-header text-size-24 text-beplBlue d-flex justify-content-center pt-0'>
                        Distributor Data
                    </Row>
                    <DataTable data={data}/>
                </Col>
                <Col>
                    <ItemTable data={data}/>
                </Col>
            </Col>
        </Fragment>
    );
}

export default DebugDetails;