import React from 'react';
import useModal from '../../../sharedComponents/useModal/useModal';
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { MdClose, MdError } from 'react-icons/md';



const ErrorModal = ({ id, title, text1, text2 }) => {
  const { modalProps } = useModal(id, { isMenu: true, isFullscreen: true });


  if (!isMobile) {
    // modalProps.className = 'img-view-upload-modal-wrapper';
    // modalProps.contentClassName = 'img-view-upload-modal';
  }


  return (
    <Modal {...modalProps} className='d-flex align-items-center' style={{ paddingTop: '150px' }}>
      {/* <ModalHeader {...headerProps}>Rebate Voucher</ModalHeader> */}
      <Row className="m-0 justify-content-between d-flex font-weight-bold p-3">
        <Col xs="8">{title}</Col>
        <MdClose onClick={modalProps.toggle}  size={25} className='clickable' />
      </Row>
      <ModalBody style={{ marginTop: '200px !important', maxHeight: '80vh', overflowY: 'scroll' }} className={`${isMobile ? 'px-1' : ''} position-relative`} >

        <Row className='mx-0 d-flex justify-content-center' >
          <MdError size={60} className="text-red" />
        </Row>
        <Row className='mx-0 d-flex justify-content-center text-size-16 font-weight-bold' style={{ marginTop: '20px' }}>
          {text1}
          {/* { 'Failed to invite ' + contactName} */}
        </Row>
        <Row className='mx-0 d-flex justify-content-center text-size-14' style={{ marginTop: '10px' }}>
          {text2}
        </Row>
        <Row className='mx-0 d-flex justify-content-center text-size-14' style={{ marginTop: '40px' }}>
          <Col xs={6}><Button onClick={modalProps.toggle} color='beplMagenta' className='py-2 button-label w-100 border-2'>Close</Button></Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ErrorModal;