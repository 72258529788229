import React from 'react';
import { Row, Col, CardBody, Spinner } from 'reactstrap';
import { IoIosArrowDown } from 'react-icons/io';
import { isMobile } from 'react-device-detect';
import { If, Then, Else } from 'react-if';
import { useSelector } from 'react-redux';

const IntelListRowLoadMore = ({onClick}) => {
  const allLoaded = useSelector(({intel}) => intel.allLoaded);
  return (
    <CardBody className={isMobile ? '' : 'border-top'}>
      <Row className='text-primary clickable justify-content-center align-items-center' onClick={onClick}>
        <Col className='w-fc pr-0'>Show More</Col>
        <Col className='w-fc'>
          <If condition={allLoaded}>
            <Then>
              <IoIosArrowDown className='w-fc' />
            </Then>
            <Else>
              <Spinner color='primary' size='sm' />
            </Else>
          </If>
        </Col>
      </Row>
    </CardBody>
  );
}

export default IntelListRowLoadMore;