import React from 'react';
import { Card, Row, Col, Container, Spinner } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import useIntelList from './useIntelList';
import IntelListRowHeader from './Subcomponents/IntelListRowHeader';
import IntelListRowLoadMore from './Subcomponents/IntelListRowLoadMore';

const IntelList = () => {
  const { intelListMap, sortBy, sortState, loadMoreList, showMore } = useIntelList.useApi();

  if (isMobile) {
    return (
      <Col className='pb-3 p-0 mt-3'>
        <Card>
          {intelListMap}
          <IntelListRowLoadMore onClick={loadMoreList} />
        </Card>
      </Col>
    );
  }

  return (
    <Container fluid className='pb-3'>
      <Row className='mb-3'>
        <Col>
          {intelListMap.length === 0 ? <Row className="d-flex justify-content-center align-items-center h-25vh w-100"><Spinner color='primary' /></Row> :
            <Card>
              <IntelListRowHeader sortBy={sortBy} sortState={sortState} />
              {intelListMap}
              {showMore && <IntelListRowLoadMore onClick={loadMoreList} />}
            </Card>
          }

        </Col>
      </Row>
    </Container>
  );
};

export default IntelList;