import React, { useEffect } from "react";
import { Card, Row, Spinner, Col, CardBody, Button } from 'reactstrap';
import { If } from "react-if";
import { isMobile } from "react-device-detect";
import useSameDayOrder from "./useSameDayOrder";
import CompetitiveInsightSameDayOrderRow from "./CompetitiveInsightSameDayOrderRow";
import CompetitiveInsightSameDayOrderSortHeader from "./CompetitiveInsightSameDayOrderSortHeader";
import InputSearch from "../../../../../sharedComponents/InputSearch/InputSearch";
import { BsSearch } from "react-icons/bs";
import { colors } from "../../../../../config/constants";
import numeral from "numeral";
import { useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import useOrderHistory from "../OrderHistory/useOrderHistory";
let loadedPage = false;
// component renders 4 times, using useEffect with empty array isnt working to execute only once
const CompetitiveInsightSameDayOrderView = () => {
  const { loading } = useOrderHistory.useProcessing();
  const { filteredAndSortedOrderItems, sortBy, sortState, search, setSearch, clearSearchValue, orderVolume, goToItemMatches } = useSameDayOrder.useProcessing();
  const { exportOrderItemsList } = useSameDayOrder.useCsvExport();
  const isInternalAdmin = useSelector(({ user }) => user.isInternalAdmin);
  const { date } = useParams();
  const itemsMap = filteredAndSortedOrderItems.map((item, i) => {
    return <CompetitiveInsightSameDayOrderRow goToItemMatches={goToItemMatches} key={item.min + i} {...item} />
  })
  useEffect(() => {
    if (!loadedPage) {
      loadedPage = true;
    }
    // eslint-disable-next-line
  }, []);

  if (loading === 'loaded') {
    return (
      <>
        <CardBody className="px-3 pt-1 pb-0">
          <Row className={`pl-0 main-header text-size-20 d-flex align-items-center`} style={{ marginTop: 16, paddingBottom: 12, paddingLeft: 15 }}>
            {orderVolume ? 'Estimated Invoice Volume On ' + moment.utc(date).format("MMM Do YYYY") + ': ' + numeral(orderVolume).format('$0,0.00') :
              'Estimated Invoice Volume On' + moment.utc(date).format("MMM Do YYYY") + ': -.--'}
          </Row>
          <Row>
            <Col style={{ marginBottom: '16px' }} className={`text-size-18  align-items-end justify-content-between px-0 ${isMobile ? '' : 'd-flex flex-row'}`}>
              <Col className={`px-0`} xs={isMobile ? 12 : 3} >
                <InputSearch value={search} clearValue={clearSearchValue} onChange={(e) => setSearch(e.target.value)} on placeholder="Search Order Items" />

              </Col>
              <Col className={`px-0 justify-content-end ${isMobile ? 'mt-2' : 'd-flex'}`}>
                <If condition={isInternalAdmin}>
                  <Button type="button" disabled={loading === true} onClick={exportOrderItemsList} color="beplMagenta" className="py-2 px-2 btn btn-beplMagenta btn-sm button-label" style={{ float: 'right' }}>Export Page Details</Button>
                </If>
              </Col>
            </Col>
          </Row>
        </CardBody>
        {!isMobile && <CompetitiveInsightSameDayOrderSortHeader sortState={sortState} sortBy={sortBy} />}
        <Card className="mb-5">
          {itemsMap}
        </Card>
      </>
    );
  } else if ((loading === 'loaded' && filteredAndSortedOrderItems.length === 0) || loading === 'failed') {
    return (
      <Row style={{ position: 'relative' }}>
        <Col xs={12} className='text-center p-5'>
          <BsSearch color={colors.beplMagenta} size={45} />
          <div className='mt-3 main-header text-center'>Failed to load,</div>
          <div className='mt-3 main-header text-center'>Try Refreshing.</div>
        </Col>
      </Row>
    )

  } else {
    return (
      <Row className="d-flex justify-content-center align-items-center h-25vh"><Spinner color='primary' /></Row>
    )
  }
}

export default CompetitiveInsightSameDayOrderView;