import React, { Fragment } from 'react';
import Router from  './Router';
import './App.scss';
import useAxios from '../../sharedComponents/useAxios/useAxios';
import { Container } from 'reactstrap';
import Spinner from '../../sharedComponents/Spinner/Spinner';
import Modals from '../../sharedComponents/Modals/Modals';
import Notifications from '../../sharedComponents/Notifications/Notifications';

function App() {
  const ready = useAxios();
  if (!ready) {
    return (
      <Container />
    );
  }
  return (
    <Fragment>
      <Router />
      <Modals />
      <Notifications />
      <Spinner />
    </Fragment>
  );
};

export default App;