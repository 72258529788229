import React, { Fragment } from 'react';
import { AiFillRightCircle, AiFillLeftCircle } from 'react-icons/ai';
import { Row, Col } from 'reactstrap';
import TopBarLinks from './TopBarLinks';
import TopBarOptions from './TopBarOptions';
import useTopBar from './useTopBar';
import { useSelector } from 'react-redux';
const TopBar = ({ toggle }) => {
  const { goToLinks, links } = useTopBar.useLinks();
  const menuExpanded = useSelector(({ ui }) => ui.menuExpanded);
  const linksMap = links.map((link, i) => (
    <Fragment key={`topBar-${i}`}>
      <TopBarLinks
        id={i}
        onClick={() => goToLinks(link)}
        title={link.title}
        icon1={link.icon}
        icon2={link.iconComingSoon}
        isActive={link.isActive}
        showSeparation={i !== links.length - 1}
      />
    </Fragment>
  ));

  return (
    <Row className={`m-0 p-0 text-white text-size-14 clickable align-items-center flex-column justify-content-between h-100 position-relative`}>
      <div className='bg-white clickable' style={{ right: -10, top: 20, position: 'absolute', height: '20px', width: '20px', borderRadius: '100%' }} onClick={toggle}></div>
      {menuExpanded ? <AiFillLeftCircle style={{ right: -10, top: 20, position: 'absolute' }} size={20} className="text-beplMagenta clickable z-1050" onClick={toggle} />
        : <AiFillRightCircle style={{ right: -10, top: 20, position: 'absolute' }} size={20} className="text-beplMagenta clickable z-1050" onClick={toggle} />}
      <Col className='mx-0 px-0 d-block'>
        <Col className='px-0 py-2 mx-0 d-flex justify-content-center' >
          <img src='/BepCompanyLogoMagenta.png' alt='Company Logo' className='responsive-company-logo' />
        </Col>
        {linksMap}
      </Col >
      <TopBarOptions />
    </Row>
  );
};


export default TopBar;