import React from 'react';
import { useHistory } from 'react-router-dom';
import IntelListRow from './IntelListRow';
import { useSelector, useDispatch } from 'react-redux';
import sortArrayByKey from '../../../../sharedComponents/Helper/sortArrayByKey';
import { setListSortByDesc, setListPage } from '../../../../reducers/IntelReducer/IntelActions';

const useApi = () => {
  const dispatch = useDispatch();
  const merchantsList = useSelector(({intel}) => intel.merchants);
  const search = useSelector(({intel}) => intel.searchApply);
  const allLoaded = useSelector(({intel}) => intel.allLoaded);
  const sortState = useSelector(({intel}) => { return { sortBy: intel.listOptions.sortBy, sortDesc: intel.listOptions.sortDesc }});
  const page = useSelector(({intel}) => intel.listOptions.page);

  const sortBy = (key) => {
    let order = key === sortState.sortBy ? !sortState.sortDesc : true;
    order = (key === 'merchant' && key !== sortState.sortBy) ? !order : order;
    dispatch(setListSortByDesc(key, order));
  };

  // this sorts the results, then filters for any matches
  // in the "merchant" and "dcn" fields
  const filterMap = 
    sortArrayByKey(merchantsList, 
                   sortState.sortBy, 
                   sortState.sortDesc)
      .filter(m => 
        m.merchant.toLowerCase().search(search.toLowerCase()) !== -1 ||
        m.dcn.search(search) !== -1
      );

  const intelListMap = filterMap.slice(0, page*16).map(mer => (
    <IntelListRow key={`merchant-${mer.id}-list`} {...mer} name={mer.merchant}/>
  ));

  const loadMoreList = () => {
    if (allLoaded) {
      dispatch(setListPage(page+1));
    }
  };

  return {
    intelListMap, sortBy,
    sortState, loadMoreList,
    showMore: intelListMap.length !== filterMap.length,
    filterMap
  }
};

const useRow = ({id, competitorVolume}) => {
  const history = useHistory();

  const goToSeeDetails = () => {
    history.push(`/intel/detail/${id}/${competitorVolume === 0 ? 'distributor' : 'competitive'}`);
  };
  
  return { goToSeeDetails };
};

export default {
  useApi,
  useRow
};