import React from 'react';
import { Spinner, Card, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';

const LoadingMerchantsModal = () => {
  const merchants = useSelector(({intel}) => intel.merchants);
  const total = merchants.length;
  const loaded = merchants.filter(m => !m.loading).length;
  const show = merchants.some(m => m.loading);

  return (
    <Card className={'loading-merchants-modal' + (show ? ' show' : '')}>
      <CardBody className='px-5 py-3'>
        <Spinner color='primary' size='sm' className='mr-2' /> {`${loaded}/${total} Merchants Loaded`}
      </CardBody>
    </Card>
  );
};

export default LoadingMerchantsModal;