
let init = {
  rebateList: [],
  allRebateList: [],
  rebateLoaded: false,
  allRebatePageCount: null,
  filtersLoadFailed: false,
  manufacturerGroup: [],
  manufacturerChecked: [],
  categoryGroup: [],
  categoryChecked: [],
  search: '',
  searchManufacturer: '',
  searchCategory: '',
  rebateView: [],
  filtersLoaded: false,
  listOptions: {
    page: 1,
    sortBy: 'manufacturer',
    sortDesc: false
  },
  rebatesItemMatchesSort: {
    sortBy: 'inCatalog',
    sortDesc: true,
  },
  itemMatches: [],
  filteredSortedItemMatches: [],
  itemMatchesLoading: null,
  itemMatchesForAllItems: [],
  itemRelatedToMatches: null,
  rebateViewType: 'yourRebates',
  scrollYPos: 0,
  rebateLoadStatus: false,
  allRebateLoadStatus: 'idle'
}

export default function reducer(state = init, action) {
  switch (action.type) {
    case 'CLEAR_REBATE_SEARCH':
      return {
        ...state,
        search: ''
      };
    case 'CLEAR_REBATE_DATA':
      return {
        ...state,
        rebateLoaded: false,
        rebateList: [],
        rebateLoadStatus: false,

      };
    case 'CLEAR_ALL_REBATE_DATA':
      return {
        ...state,
        allRebateList: [],
        allRebateLoadStatus: false
      };
    case 'SET_REBATE_SEARCH':
      return {
        ...state,
        search: action.payload
      };
    case 'SET_REBATE_DATA':
      return {
        ...state,
        rebateLoaded: true,
        rebateList: action.payload.data,
        rebateView: []
      };
    case 'SET_ALL_REBATE_DATA':
      return {
        ...state,
        allRebateLoaded: true,
        allRebateList: action.payload.data,
        rebateView: []
      };
    case 'CLEAR_SEARCH_MANUFACTURER':
      return {
        ...state,
        searchManufacturer: ''
      };
    case 'SET_SEARCH_MANUFACTURER':
      return {
        ...state,
        searchManufacturer: action.payload
      };
    case 'SET_MANUFACTURER_DATA':
      return {
        ...state,
        manufacturerGroup: action.payload.data
      };
    case 'SET_MANUFACTURER_CHECKED':
      return {
        ...state,
        manufacturerChecked: state.manufacturerChecked.some(m => m === action.payload) ? state.manufacturerChecked.filter(m => m !== action.payload) : [...state.manufacturerChecked, action.payload]
      };
    case 'CLEAR_SEARCH_CATEGORY':
      return {
        ...state,
        searchCategory: ''
      };
    case 'SET_SEARCH_CATEGORY':
      return {
        ...state,
        searchCategory: action.payload
      };
    case 'SET_CATEGORY_DATA':
      return {
        ...state,
        categoryGroup: action.payload.data
      };
    case 'SET_CATEGORY_CHECKED':
      return {
        ...state,
        categoryChecked: state.categoryChecked.some(c => c === action.payload) ? state.categoryChecked.filter(c => c !== action.payload) : [...state.categoryChecked, action.payload]
      };
    case 'RESET_REBATE_FILTERS':
      return {
        ...state,
        manufacturerChecked: [],
        categoryChecked: []
      };
    case 'CLEAR_REBATE_VIEW':
      return {
        ...state,
        rebateView: []
      };
    case 'SET_REBATE_VIEW':
      return {
        ...state,
        rebateView: action.payload.data
      };
    case 'SET_REBATE_LIST_SORT_BY':
      return {
        ...state,
        listOptions: {
          ...state.listOptions,
          sortBy: action.payload.key,
          sortDesc: action.payload.order
        }
      }
    case 'SET_FILTERS_LOADED':
      return {
        ...state,
        filtersLoaded: action.payload.data
      };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        categoryGroup: [],
        manufacturerGroup: []
      }
    case 'CLEAR_ALL_REBATES_DATA':
      return init
    case 'ITEM_MATCHES_LOADING':
      return {
        ...state,
        itemMatchesLoading: action.payload
      }
    case 'ITEM_MATCHES_LOADING_COMPLETE':
      return {
        ...state,
        itemMatchesLoading: null
      }
    case 'ITEM_MATCHES_FOR_ALL_ITEMS':
      if (state.itemMatchesForAllItems.findIndex(m =>
        m.min === action.payload.min &&
        m.manufacturerPlatformId === action.payload.manufacturerPlatformId) < 0) {

        // add matches for this min to our state, if we don't have matches for it already
        return {
          ...state,
          itemMatchesForAllItems: [...state.itemMatchesForAllItems, {
            min: action.payload.min,
            manufacturerPlatformId: action.payload.manufacturerPlatformId,
            matches: action.payload.data,
            visible: false
          }]
        };
      }
      else {
        return state;
      }
    case 'FILTERS_LOAD_FAILED':
      return {
        ...state,
        filtersLoadFailed: true
      }
    case 'SET_REBATE_VIEW_TYPE':
      return {
        ...state,
        rebateViewType: action.payload
      }
    case 'CLEAR_ALL_REBATE_VIEW':
      return {
        ...state,
        rebateView: []
      };
    case 'SET_FILTERED_SORTED_ITEM_MATCHES':
      return {
        ...state,
        filteredSortedItemMatches: action.payload
      }
    case 'SET_ITEM_MATCHES':
      return {
        ...state,
        itemMatches: action.payload
      }
    case 'SET_ITEM_MATCHES_SORT':
      return {
        ...state,
        rebatesItemMatchesSort: {
          sortBy: action.payload.key,
          sortDesc: action.payload.order
        }
      }
    case 'SET_Y_SCROLL_POS':
      return {
        ...state,
        scrollYPos: action.payload
      }
    case 'SET_REBATE_LOAD_STATUS':
      return {
        ...state,
        rebateLoadStatus: action.payload
      }
    case 'SET_ALL_REBATE_LOAD_STATUS':
      return {
        ...state,
        allRebateLoadStatus: action.payload
      }
    case 'SET_ALL_REBATE_PAGE_COUNT':
      return {
        ...state,
        allRebatePageCount: action.payload
      }
    default:
      return state;
  };
};