
import React, { useEffect } from "react"
import { Row, Container, Col } from "reactstrap";
import HeaderLogo from '../../sharedComponents/Header/HeaderLogo';
import useAdminDashboard from "./useAdminDashboard"
import { isMobile } from "react-device-detect";
import InputSearch from '../../sharedComponents/InputSearch/InputSearch';
import { Button } from "reactstrap";
import SortableTableHeader from "../../sharedComponents/SortableTableHeader/SortableTableHeader";
import ScrollableList from "../../sharedComponents/ScrollableList/ScrollableList";
import TableRow from "../../sharedComponents/TableRow/TableRow";
import EditUserSlideOut from "./Subcomponents/EditUserSlideOut";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom/cjs/react-router-dom";
import MultiDimensionDropdown from "../../sharedComponents/MultiDimensionDropdown/MultiDimensionDropdown";


const AdminDashboard = () => {

  const { userRowActions, usersTableHeaderColumns, userSearch, setUserSearch, getDistributors, distributorsSelectOps,
    selectedParentDistributor, setSelectedParentDistributor, editUser, setEditUser, inviteUserModal, sortState, setUsers, users,
    sortBy, openExportModal, sortedUsers, childrenDistributors, selectedChildDistributor, setSelectedChildDistributor } = useAdminDashboard();

  useEffect(() => {
    getDistributors();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <HeaderLogo logo={process.env.REACT_APP_COMPANY_LOGO} />
      <Container fluid className='flex-grow-1 px-4 m-0'>
        <Col className='py-3 px-0'>
          <Row className="m-0 p-0 text-center text-size-24 main-header">
            <Link to="/admin-login">
              <Button color='beplMagenta' className="mr-4">
                <IoIosArrowBack /> Back
              </Button>
            </Link>
            <div className="mt-1">Admin Dashboard</div>
          </Row>
        </Col>
        <Row className="m-0 d-flex justify-content-between">
          <Col xs={isMobile ? 12 : 6} className={`m-0 p-0 d-flex ${isMobile ? 'flex-column' : ''}`}>
            <div xs={isMobile ? 12 : 6} className="p-0" style={{ width: !isMobile ? '50%' : 'auto' }}>
              <Col className="p-0 ml-1">
                Distributor
              </Col>

              <Col className="p-0 ml-1">
                <MultiDimensionDropdown
                  firstList={distributorsSelectOps}
                  secondList={childrenDistributors}
                  setSelectedFromFirstList={setSelectedParentDistributor}
                  selectedFromFirstList={selectedParentDistributor}
                  setSelectedFromSecondItem={setSelectedChildDistributor}
                  selectedFromSecondList={selectedChildDistributor}
                />
              </Col>
            </div>

            <Col xs={isMobile ? 12 : 6} className={`p-0 ${isMobile ? 'mt-2' : 'ml-2'}`}>
              <Col className="p-0 ml-1">
                User
              </Col>
              <Col className="p-0">
                <InputSearch onChange={(e) => setUserSearch(e.target.value)} value={userSearch} placeholder={"Search User"} />
              </Col>
            </Col>
          </Col>
          <Col xs={6} className={`p-0 m-0 align-items-end d-flex justify-content-end ${isMobile ? 'mt-2' : ''}`}>
            <Button color='beplMagenta' disabled={!selectedParentDistributor} onClick={openExportModal} className='py-2 button-label mr-2'>Export Users</Button>
            <Button color='beplMagenta' disabled={!selectedChildDistributor || (selectedChildDistributor && !selectedChildDistributor.data.referredPartner)} onClick={inviteUserModal} className='py-2 button-label'>Invite User</Button>
          </Col>
        </Row>

        <Col className="m-0 p-0 mt-3">
          {!isMobile ? <SortableTableHeader sortBy={sortBy} sortState={sortState} columns={usersTableHeaderColumns} /> : false}
          <ScrollableList actions={userRowActions} Row={TableRow} list={sortedUsers} keyPath={['email', 'value']} emptyMessage={"Select Distributor to Display Users"} />
        </Col>

      </Container>
      <EditUserSlideOut setUsers={setUsers} setUser={setEditUser} user={editUser} users={users}
      />
    </div>
  )
}

export default AdminDashboard;