import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import { selectStyles } from '../../../config/constants';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { If } from 'react-if';
import useRebates from './useRebates';
import InputSearch from '../../../sharedComponents/InputSearch/InputSearch';
import useDistributor from '../../../sharedComponents/useDistributor/useDistributor';
import stringify from 'csv-stringify';
import fileDownload from 'js-file-download';
import numeral from 'numeral';
import { setRebateViewType } from '../../../reducers/RebateReducer/RebateActions';
import { IoIosOptions } from 'react-icons/io';

const RebatesTop = (props) => {
  const { search } = useRebates.useTop(props);
  const { onClickSearch, rebateList, allRebateList } = props;
  const { selectActiveDistributor, activeDistributor, distributorsList } = useDistributor();
  const checkM = useSelector(({ rebate }) => rebate.manufacturerChecked);
  const checkC = useSelector(({ rebate }) => rebate.categoryChecked);
  const isInternalAdmin = useSelector(({ user }) => user.isInternalAdmin);
  const rebateLoaded = useSelector(({ rebate }) => rebate.rebateLoaded);
  const dispatch = useDispatch();
  const viewType = useSelector(({rebate}) => rebate.rebateViewType);

  const apply = () => {
    onClickSearch(checkM, checkC);
  }

  // Export the current view in CSV format and download it.
  // This functionality should only be available to admin accounts.
  const onExport = () => {
    const csvData = [];
    let fileName = '';
    let rebatesList;
    if(viewType === 'all') {
      rebatesList = allRebateList;
    } else{
      rebatesList = rebateList;
    }
    if (!rebatesList || rebatesList.length === 0) {
      return;
    }

    // Header Row
    csvData.push([
      'DIN',
      'MIN',
      'Description',
      'Manufacturer',
      'Rebate Type',
      'Rebate',
      'Deviated Price'
    ]);

    rebatesList.forEach(e => {
      csvData.push([
        e.din,
        e.min,
        e.description,
        e.manufacturer,
        e.rebateType,
        numeral(e.rebate).format('0,0.00'),
        numeral(e.deviationValue).format('0,0.00')
      ]);
    });

    fileName = 'Rebates - For internal use only.csv';

    // Prepare the CSV data
    if (csvData.length > 0) {
      stringify(csvData, (err, output) => {
        fileDownload(output, fileName);
      });
    }
  }

  let distributorSelect = null;
  if (distributorsList && distributorsList.length === 1) {
    // Show a single distributor as a label
    distributorSelect = <div className='main-header text-size-20 truncate'>{distributorsList[0].label}</div>;
  }
  else {
    // Show multiple distributors as a select
    distributorSelect =
      <Select
        options={distributorsList}
        onChange={selectActiveDistributor}
        styles={selectStyles}
        value={activeDistributor}
      />
    ;
  }

  return (
    <Col className='bg-grayBg px-0' >
      <Row className='px-0 mx-0' style={{ marginBottom: 6, paddingTop: 16 }}>
        <Col className='px-0' xs={8} style={{zIndex: 60}}>
        {distributorSelect}
        </Col>
     
        <Col xs={4} className="px-0 d-flex justify-content-end">
          <Col onClick={() => dispatch(setRebateViewType('yourRebates'))} 
          className={'w-fc border-right text-size-16 pb-1 clickable text-' + (viewType === 'yourRebates' ? 'beplMagenta font-weight-bold' : 'beplBlue')} >
            Your Rebates
          </Col>
          <Col onClick={() => dispatch(setRebateViewType('all'))}  
          className={'w-fc border-left text-size-16 pb-1 clickable text-' + (viewType === 'all' ? 'beplMagenta font-weight-bold' : 'beplBlue')} >
            All Rebates
          </Col>
        </Col>

      </Row>
      <Row className='px-0 mx-0' style={{ paddingTop: 6, paddingBottom: 16 }} >
        <Col className='px-0 mw-300px'>
          <InputSearch  placeholder='Search for MIN, description or manufacturer...' {...search} />
        </Col>
        
        <IoIosOptions onClick={props.showFiltersOnSmallerDesktops} className="mt-1 ml-2 smaller-screen-size-filters-btn" size="30" />
        <Col className="d-flex px-0 justify-content-end">
          <Col xs={2} className="px-0 mr-2">
            <Button onClick={apply} block className='p-2 button-label' size='sm' color='beplMagenta'>Apply</Button>
          </Col>
          <If condition={isInternalAdmin}>
            <Button disabled={!rebateLoaded} className='py-2 button-label' size='sm' onClick={onExport} color='beplMagenta'>Export Page Details</Button>
          </If>
        </Col>
      </Row>
    </Col>

  )
}

export default RebatesTop;
