import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import invoiceApi from '../../../../api/invoiceApi';
import { setUploadedInvoicesData, setUploadedInvoiceImages } from '../../../../reducers/InvoiceScanningReducer/InvoiceScanningActions';

let syncProgressPercentages = {};
let syncFailedUploads = [];
let returnedRecordId = null;

const useUploadModal = () => {
  const [invoiceName, setInvoiceName] = useState('');
  const [invoiceLocation, setInvoiceLocation] = useState({ value: '', label: '' });
  const [uploadProgressPercenteges, setUploadProgressPercenteges] = useState({});
  const [uploadStatus, setUploadModalStatus] = useState(null);
  const [failedUploads, setFailedUploads] = useState([]);
  const [uploadFiles, setInvoiceUploadFiles] = useState([]);
  const merchants = useSelector(({ intel }) => intel.merchants);
  const uploadedInvoicesData = useSelector(({ invoices }) => invoices.uploadedInvoicesData);
  const uploadedInvoiceImages = useSelector(({invoices}) => invoices.uploadedInvoiceImages);

  const dispatch = useDispatch();
  // const syncProgressPercentages = createRef();
  // const syncFailedUploads = createRef();

  useEffect(() => {
    return () => {
      returnedRecordId = null;
      syncProgressPercentages = {};
      syncFailedUploads = [];
      setFailedUploads([]);
      setUploadProgressPercenteges({});
      setUploadModalStatus(null);
      setInvoiceUploadFiles([]);
      setInvoiceLocation('');
      setInvoiceName('');
    }
  }, []);

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const clearImages = () => {
    syncFailedUploads = [];
    setFailedUploads([]);
    setUploadModalStatus(null);
    setInvoiceUploadFiles([]);
  }

  const clearImage = (name, input) => {
    let filtered = uploadFiles.filter(file => {
      return file.name !== name;
    })
    setInvoiceUploadFiles(filtered);
  }


  const setInitialProgressPercentages = (images) => {
    let progressBarsObj = syncProgressPercentages === null ? {} : syncProgressPercentages;
    images.forEach(image => {
      let exists = failedUploads.findIndex(failedUpload => failedUpload.name === image.name);
      if (exists !== -1) {
        progressBarsObj[image.name] = 0;
      }
    });

    syncProgressPercentages = progressBarsObj;
    setUploadProgressPercenteges(progressBarsObj);
    return progressBarsObj;
  }

  const saveFilesToState = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    let allFiles = [...files, ...uploadFiles];
    let dups = [];
    let filtered = allFiles.filter((file, ind, arr) => {
      let index = arr.findIndex(f => f.name === file.name);
      if (file.size > 10000000) {
        setUploadModalStatus('Max file size is 10mb');
      }
      if (index !== ind) {
        dups.push(file.name)
      }
      return index === ind && file.size <= 10000000;
    });
    setInvoiceUploadFiles(filtered);
    if (dups.length > 0) {
      setUploadModalStatus('You have already uploaded the following files: ' + dups);
    }
  }

  const uploadImages = async (images, merchant, description, uploadId = null, reupload=null) => {
    if (invoiceLocation.value.length === 0 || invoiceName.length === 0) {
      setUploadModalStatus('Name and location are required.');
    } else {
      if (images.length > 0) {
        setUploadModalStatus(null);
        setFailedUploads([]);
        syncFailedUploads = [];
        setInitialProgressPercentages(images);
        const executeUpload = (ind) => {
          if (!syncProgressPercentages[images[ind].name]) {
            const data = new FormData();
            data.append('imageFile', images[ind]);
            data.set('merchant', merchant);
            data.set('description', description || 'No Name');
            const config = {
              timeout: 30000,
              onUploadProgress: (e) => {
                let progressBarsObj = JSON.parse(JSON.stringify(syncProgressPercentages));
                const percentCompleted = (e.loaded / e.total) * 100;
                progressBarsObj[images[ind].name] = percentCompleted;
                syncProgressPercentages = progressBarsObj;
                setUploadProgressPercenteges(progressBarsObj);
              }
            }

            invoiceApi.post(data, config, uploadId !== null ? uploadId : returnedRecordId).then(res => {
              if (res) {
                returnedRecordId = res.data.id ? res.data.id : returnedRecordId;
              } else {
                // let failedUploadsArr = syncFailedUploads === null ? [] : syncFailedUploads
                // setFailedUploads([...failedUploadsArr, images[ind]]);
                // syncFailedUploads = [failedUploadsArr, images[ind]];
                // setUploadModalStatus('Failed to upload ' + images[ind].name);
              }
              if (ind !== images.length - 1) {
                executeUpload(ind + 1);
              } else {
                if (syncFailedUploads.length === 0) {
                  // setProgressBars({});

                  // setInvoiceName('');
                  // setInvoiceLocation('');
                  if(reupload) {
                    let imageObjs = images.map(image => {return {filename: image.name, attachment_id: res.data.attachment_id }});
                    dispatch(setUploadedInvoiceImages([...imageObjs, ...uploadedInvoiceImages]));
                  }
                }
                let upload = {
                  id: returnedRecordId,
                  attachment_grouping_id: returnedRecordId,
                  description: description || 'No Name',
                  merchant_name: merchant,
                  createdAt: Date.now(),
                  status: 'InProcess'
                }

                dispatch(setUploadedInvoicesData([upload, ...uploadedInvoicesData]));
                setTimeout(() => {
                  if (syncFailedUploads.length > 0) {
                    setUploadModalStatus('partial-success');
                  } else {
                    setUploadModalStatus('success');
                  }
                }, 500);
              }
            }).catch(err => {
              if (ind !== images.length - 1) {
                executeUpload(ind + 1);
              }
              let failedUploadsArr = syncFailedUploads === null ? [] : syncFailedUploads
              setFailedUploads([...failedUploadsArr, images[ind]]);
              syncFailedUploads = [...failedUploadsArr, images[ind]];
              if (images.length === 1) {
                setUploadModalStatus('Failed to upload ' + images[ind].name);
              } else {
                setUploadModalStatus('partial-success');
              }
            });
          }

        }
        executeUpload(0);
      }
    }

  }


  return {
    uploadImages,
    uploadFiles,
    formatBytes,
    clearImage,
    clearImages,
    saveFilesToState,
    invoiceName,
    invoiceLocation,
    setInvoiceName,
    setInvoiceLocation,
    setInvoiceUploadFiles,
    merchants,
    uploadStatus,
    setUploadModalStatus,
    uploadProgressPercenteges,
    failedUploads
  }
}

export default useUploadModal;