import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { setDetailSortBy, setOrderItems } from "../../../../../reducers/IntelReducer/IntelActions";
import sortArrayByKey from "../../../../../sharedComponents/Helper/sortArrayByKey";
import { useState, useEffect } from "react";
import numeral from "numeral";
import stringify from 'csv-stringify';
import fileDownload from 'js-file-download';
import moment from "moment";

const processData = (items, sortState, search) => {
  let filtered = items.filter(item => {
    let min = item.min ? item.min.toLowerCase() : '';
    let description = item.description ? item.description.toLowerCase() : '';
    let manufacturer = item.manufacturer ? item.manufacturer.toLowerCase() : '';
    return min.includes(search.toLowerCase()) || description.includes(search.toLowerCase()) ||
      manufacturer.includes(search.toLowerCase());
  });
  let sorted = sortArrayByKey(
    filtered,
    sortState.sortBy,
    sortState.sortDesc
  );
  return sorted;
}

const useProcessing = () => {
  const dispatch = useDispatch();
  const intel = useSelector(({ intel }) => intel.competitiveOrderHistoryPurchases)
  const { merchant, merchantId, distributorId, date } = useParams();
  const history = useHistory();
  let filteredAndSortedOrderItems = useSelector(({intel}) => intel.filteredAndSortedOrderItems);
  const orderItems = useSelector(({intel}) => intel.orderItems);
  const sortState = useSelector(({ intel }) => intel.detailOptions.competitiveInsightOrderItemsSort);
  const [search, setSearch] = useState('');
  const clearSearchValue = () => setSearch('');
  const[orderVolume, setOrderVolume] = useState();
  filteredAndSortedOrderItems = processData(orderItems, sortState, search);


  useEffect(() => {
    let month = moment(date).toDate().getMonth() + 1;
    let indexOfMerchant = intel.findIndex((merchant) => {
      if(merchant.data && merchant.data.length > 0 && merchant.data[0].items.length > 0){
        let dateSplit = merchant.data[0].items[0].invoice_date.slice(0, 10).split('-');
        let invoiceMonth = dateSplit[1]
        return merchant.id === merchantId && Number(invoiceMonth) === Number(month);
      } else {
        return false;
      }
    });
    let merchantObj = intel[indexOfMerchant];
    let itemsOrderedOnGivenDate = [];
    let orderVolume = 0
    if (merchantObj) {
      merchantObj.data.forEach(dist => {
        if (dist.distributorPlatformId === distributorId) {
          dist.items.forEach(item => {
            if (item.invoice_date === date) {
              let averagePrice = (item.startGuidanceRange + item.endGuidanceRange) / 2; 
              orderVolume += averagePrice * item.quantity;
              itemsOrderedOnGivenDate.push(item);
            }
          })
        }
      });
    }
    setOrderVolume(orderVolume);
    dispatch(setOrderItems(itemsOrderedOnGivenDate));
    // eslint-disable-next-line
  }, [intel]);



  const goToItemMatches = (min, manufacturerPlatformId, manufacturerGroupPlatformId, name) => {
    // Use the group platform ID if the regular platform ID is unavailable
    const platformIdToUse =
      manufacturerPlatformId === "" ?
        manufacturerGroupPlatformId :
        manufacturerPlatformId
      ;
    history.push('/competitiveinsights/matches/' + merchant + '/' + name.replace('/', '-').replace('#', '') + '/' + min + '/' + merchantId + '/' + platformIdToUse);
  }


  const sortBy = (key) => {
    let order = key === sortState.sortBy ? !sortState.sortDesc : true;
    order = ((key === 'description' && key !== sortState.sortBy) || (key === 'manufacturer' && key !== sortState.sortBy)) ? !order : order;
    dispatch(setDetailSortBy('competitiveInsightOrderItemsSort', key, order));
  };

  return {
    filteredAndSortedOrderItems,
    sortState,
    sortBy,
    search,
    setSearch,
    clearSearchValue,
    orderVolume,
    goToItemMatches,
  }
}


const useCsvExport = () => {
  const orderItems = useSelector(({intel}) => intel.orderItems);  
  let prepareItemsList = (data) => {
    let csvData = [];
    csvData.push(['description', 'manufacturer', 'packsize', 'market index', 'rebate', 'deviation',
      'quantity', 'uom', 'order volume']);

    data.forEach(({ description, manufacturer, packsize, 
      startGuidanceRange, endGuidanceRange,
      rebateValue, deviationValue, quantity, uom,  }) => {
        const volumeRangeLow = startGuidanceRange * quantity;
        const volumeRangeHi = endGuidanceRange * quantity;
      csvData.push([
        description, manufacturer, packsize, numeral(startGuidanceRange).format('$0,0.00') + '-' + numeral(endGuidanceRange).format('$0,0.00'),
        numeral(rebateValue).format('0,0.00'), numeral(deviationValue).format('0,0.00'), quantity, uom,
        numeral(volumeRangeLow).format('$0,0.00') + '-' + numeral(volumeRangeHi).format('$0,0.00')
      ])
    });
    return csvData;
  }

  const exportCsv = (data, fileName) => {
    let preCsvArray = prepareItemsList(data);
    if (preCsvArray.length > 0) {
      stringify(preCsvArray, (err, output) => {
        fileDownload(output, fileName);
      });
    }
  }

  const exportOrderItemsList = () => {
    let data = orderItems;
    if (data.length > 0) {
      exportCsv(data, 'Order Items - For internal use only.csv');
    }
  }
  return {
    exportOrderItemsList
  }
}
export default {
  useProcessing,
  useCsvExport
}