import React from 'react';
import numeral from 'numeral';
import { Row, Col } from 'reactstrap';
import { If, Then } from 'react-if';
import useDashboardChart from '../../../../sharedComponents/useDashboardChart/useDashboardChart';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IoMdArrowDropright } from 'react-icons/io';
import { GoPrimitiveDot } from 'react-icons/go';
import { colors } from '../../../../config/constants';
import { isMobile } from 'react-device-detect';

const VOLUME_INCREASE_HEADER = "You could increase your volume";
const DETAILS_LINK = "See details";
const YOUR_VOLUME = "Your Volume";
const COMP_VOLUME = "Competitor Volume";

const DashboardVolumeOverview = (props) => {
  const data = [
    {
      name: YOUR_VOLUME,
      volume: props.totalVolume - props.competitorVolume,
      color: colors.beplBlue
    },
    {
      name: COMP_VOLUME,
      volume: props.competitorVolume,
      color: colors.beplMagenta
    }
  ];

  const { chartOptions, goToSeeIntelDetails } = useDashboardChart({noData: false, data, key: 'volume'});

  const yourVolumePercentage = () => {
    if (props.totalVolume > 0) {
      return numeral(
        (props.totalVolume - props.competitorVolume) / props.totalVolume)
        .format('0.0 %');
    }
    else {
      return '';
    }
  }

  const compVolumePercentage = () => {
    if (props.totalVolume > 0) {
      return numeral(
        props.competitorVolume / props.totalVolume)
        .format('0.0 %');
    }
    else {
      return '';
    }
  }

  return (
    <Col className={`w-50 border-bottom border-right ${isMobile ? 'pb-4': ''}`}>
      <Row className='align-items-center'>
        <Col xs={isMobile ? 12 : 4}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={{ className: 'highcharts-parent' }} />
        </Col>
        <Col className='text-size-14'>
          <Row>
            <Col xs={8}>
              <div style={{paddingBottom: '1rem'}}>
                <GoPrimitiveDot className='text-size-20' color={data[0].color}/> {data[0].name}
              </div>
              <div style={{paddingBottom: '1rem'}}>
                <GoPrimitiveDot className='text-size-20' color={data[1].color}/> {data[1].name}
              </div>
            </Col>
            <Col>
              <div style={{paddingBottom: '1rem'}}>
                {yourVolumePercentage()}
              </div>
              <div style={{paddingBottom: '1rem'}}>
                {compVolumePercentage()}
              </div>
            </Col>
          </Row>
          <If condition={props.competitorVolume > 0}>
            <Then>
              <div 
                className='text-size-18 text-beplMagenta' 
                style={{paddingBottom: '1rem', paddingTop: '1rem'}}>
                {VOLUME_INCREASE_HEADER}
              </div>
              <div className="see-details-label text-beplMagenta clickable"
                onClick={goToSeeIntelDetails}>
                {DETAILS_LINK} <IoMdArrowDropright />
              </div>
            </Then>
          </If>
        </Col>
      </Row>
    </Col>
  );
}

export default DashboardVolumeOverview;