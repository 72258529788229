import React from 'react';
import { Row, Col, CardBody, Button } from 'reactstrap';
import numeral from 'numeral';
import { isMobile } from 'react-device-detect';
import toTitleCase from '../../../../../sharedComponents/Helper/toTitleCase';
import truncateAfter96chars from '../../../../../sharedComponents/Helper/truncateAfter96chars';

const CompetitiveInsightSameDayOrderRow = ({
  description, manufacturer, packsize, startGuidanceRange, endGuidanceRange, min, manufacturerPlatformId,
  rebateValue, rebateType, deviationValue, quantity, uom, goToItemMatches, manufacturerGroupPlatformId
}) => {

  let matchLabel = 'Similar Items';

  description = description ? toTitleCase(description): '';
  description = description ? truncateAfter96chars(description): '';
  packsize = packsize ? packsize.toLowerCase() : 'N/A';
  const volumeRangeLow = startGuidanceRange * quantity;
  const volumeRangeHi = endGuidanceRange * quantity;

  // Determine if we show '$X.XX' or 'X.XX%' for the rebate value based on the rebate type
  const rebateFormat = rebateValue !== 'N/A' ? numeral(rebateValue).format('0.00') : 'N/A';
  const rebateWords = rebateType !== 'N/A' ? rebateType.toString().split('_') : '';
  const rebateSymbol = rebateWords ? (rebateWords.includes('PERCENT') ? '%' : '$') : '';
  const rebateValueWithSymbol = rebateSymbol === '$' ? rebateSymbol + rebateFormat : rebateFormat + rebateSymbol;

  if (isMobile) {
    return (
      <CardBody className='border-bottom p-3'>
        <Row className='pb-3'>
          <Col className='text-left font-weight-bold'>
            {description}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Manufacturer</Col>
          <Col className='text-right'>
            {manufacturer}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Packsize</Col>
          <Col className='text-right'>
            {packsize}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Market Index</Col>
          <Col className='text-right'>
            {numeral(startGuidanceRange).format('$0,0.00')} - {numeral(endGuidanceRange).format('$0,0.00')}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Rebate</Col>
          <Col className='text-right'>
            {rebateValueWithSymbol}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Deviation</Col>
          <Col className='text-right'>
            {deviationValue ? numeral(deviationValue).format('$0,0.00') : 'N/A'}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Quantity</Col>
          <Col className='text-right'>
            {numeral(quantity).format('0,0.00')}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>UOM</Col>
          <Col className='text-right'>
            {uom}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Volume Est.</Col>
          <Col className='text-right'>
            {numeral(volumeRangeLow).format('$0,0.00')} - {numeral(volumeRangeHi).format('$0,0.00')}
          </Col>
        </Row>
        <Row className='align-items-center py-2 d-flex justify-content-end'>
          <Button disabled={!min} className='border-0 bg-transparent w-fc text-beplMagenta text-right clickable reveal-label' onClick={()=>goToItemMatches(min, manufacturerPlatformId, manufacturerGroupPlatformId, description)}>
            { matchLabel }
          </Button>
        </Row>
      </CardBody>
    )
  } else {
    return (
      <CardBody className='border-top py-2 px-3 hover-row'>
        <Row className='align-items-center'>
          <Col xs={2} className='text-size-14'>
            {description}
          </Col>
          <Col xs={1} className='text-size-14'>
            {manufacturer}
          </Col>
          <Col xs={1} className='text-size-14'>
            {packsize}
          </Col>
          <Col xs={1} className='text-size-14'>
            {numeral(startGuidanceRange).format('$0,0.00')} - {numeral(endGuidanceRange).format('$0,0.00')}
          </Col>
          <Col xs={1} className='text-size-14'>
            {rebateValueWithSymbol}
          </Col>
          <Col xs={1} className='text-size-14'>
            {deviationValue ? numeral(deviationValue).format('$0,0.00') : 'N/A'}
          </Col>
          <Col xs={1} className='text-size-14'>
            {numeral(quantity).format('0,0.00')}
          </Col>
          <Col xs={1} className='text-size-14'>
            {uom}
          </Col>
          <Col xs={2} className='text-size-14'>
            {numeral(volumeRangeLow).format('$0,0.00')} - {numeral(volumeRangeHi).format('$0,0.00')}
          </Col>
          <Button disabled={!min} className='border-0 pl-0 bg-transparent text-beplMagenta text-left clickable reveal-label text-size-14' onClick={()=>goToItemMatches(min, manufacturerPlatformId, manufacturerGroupPlatformId, description)}>
            { matchLabel }
          </Button>
        </Row>
      </CardBody>
    );
  }
}

export default CompetitiveInsightSameDayOrderRow;