import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AiFillHome as IconHome } from 'react-icons/ai';
// import IconNew from '../Icons/IconNew';
import { IoIosPricetags } from 'react-icons/io'
// import {BiBarChartAlt2} from 'react-icons/bi';
import { FiSettings, FiTrendingUp } from 'react-icons/fi';
import {FaFileInvoiceDollar, /*FaRegLightbulb */} from 'react-icons/fa';
import { FaMoneyCheck } from 'react-icons/fa';
import { colors } from '../../config/constants';
import { isMobile } from 'react-device-detect';
import { clearAllIntelData } from '../../reducers/IntelReducer/IntelActions';
import { clearAllDashboardData } from '../../reducers/DashboardReducer/DashboardActions';
import { clearAllRebatesData } from '../../reducers/RebateReducer/RebateActions';
import { clearAllUiData } from '../../reducers/UIReducer/UIActions';
import { clearAllRebateChecksData } from '../../reducers/RebateChecksReducer/RebateChecksActions';
import { setAdmin, applyActiveDistributor, clearImpersonate } from '../../reducers/UserReducer/UserActions';
import authApi from '../../api/authApi';
import LocalStorage from '../LocalStorage/LocalStorage';
import useLogin from '../useLogin/useLogin';
import useMsal from '../useMsal/useMsal';

const useLinks = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = history.location;
  // const displayIntel = useSelector(({ user }) => user.displayIntel);
  const isInternalAdmin = useSelector(({user}) => user.isInternalAdmin);
  const displayInvoice = useSelector(({ user }) => user.displayInvoice);
  const user = useSelector(({user}) => user);
  const { login } = useLogin();
  const { loginPopup } = useMsal()
  const goToLinks = (link) => {
    if (link.button) {
      history.push(link.url);
    }
  };
  
  const goToSettings = () => {
    history.push('/settings');
  };

  const logOut = () => {
    history.push('/logout');
  };

  const goToFaq = () => {
    history.push('/faq');
  };

  const goToSelectDistributor = (msalInstance) => {
    let adminUserData = LocalStorage.adminUserData.get();
    if (adminUserData.authKey) {
      let adminToken = adminUserData.accessToken;
      authApi.mslogin.post({ accessToken: adminToken, switchingDist: true }).then(({ data }) => {
        login(data);
        dispatch(setAdmin(true));
        dispatch(clearAllIntelData());
        dispatch(clearAllDashboardData());
        dispatch(clearAllRebatesData());
        dispatch(applyActiveDistributor(null));
        dispatch(clearImpersonate());
        dispatch(clearAllUiData());
        dispatch(clearAllRebateChecksData());
        history.push('/admin-login');
      }).catch(err => {
        loginPopup(msalInstance, true);
      })
    } else {
      authApi.login.post(adminUserData).then(({ data }) => {
        login(data);
        dispatch(setAdmin(true));
        dispatch(clearAllIntelData());
        dispatch(clearAllDashboardData());
        dispatch(clearAllRebatesData());
        dispatch(applyActiveDistributor(null));
        dispatch(clearImpersonate());
        dispatch(clearAllUiData());
        dispatch(clearAllRebateChecksData());
        history.push('/admin-login');
      })
    }
  };



  // const desktopStyle = {
  //   position: 'absolute',
  //   top: '8px',
  //   right: '-8px',
  //   zIndex: '200'
  // };
  // const mobileStyle = {
  //   position: 'absolute',
  //   top: '2px',
  //   right: '3px',
  //   zIndex: '200'
  // };

  let links = [];


  links.push(
    {
      button: 'Home',
      title: 'Home',
      url: '/dashboard',
      isActive: pathname === '/dashboard',
      icon: (css) => () => { return <IconHome color={colors.white} className={css} /> },
      iconComingSoon: () => { return null }
    },
    // {
    //   button: 'Opportunities',
    //   title: 'Opportunities',
    //   url: '/opportunities',
    //   isActive: pathname.includes('/opportunities'),
    //   icon: (css) => () => { return <FaRegLightbulb color={colors.white} className={css} /> },
    //   iconComingSoon: () => { return null }
    // }
  );

  // if (displayIntel) {
  //   links.push({
  //     button: 'Intel',
  //     title: 'Intel',
  //     url: '/intel/list',
  //     isActive: pathname.substring(0, 6) === '/intel',
  //     icon: (css) => () => { return <BiBarChartAlt2 color={colors.white} className={css} /> },
  //     iconComingSoon: () => { return null }
  //   })
  // }

  links.push(
    {
      button: 'Rebates',
      title: 'Rebates & Deviations',
      url: '/rebates',
      isActive: pathname.includes('/rebates'),
      icon: (css) => () => { return  <IoIosPricetags color={colors.white} className={css} /> },
      iconComingSoon: () => { return null }
    },
    {
      button: 'Competitive Insights',
      title: 'Competitive Insights',
      url: '/competitiveinsights',
      isActive: pathname.includes('/competitiveinsights'),
      icon: (css) => () => { return <FiTrendingUp color={colors.white} className={css} /> },
      iconComingSoon: () => { return null }
    }
  );
  if (isInternalAdmin || displayInvoice) {
    links.push(
      {
        button: 'Invoice Scanning',
        title: 'Invoice Scanning',
        url: '/invoice',
        isActive: pathname.includes('/invoice'),
        icon: (css) => () => { return <FaFileInvoiceDollar color={colors.white} className={css} /> },
        iconComingSoon: () => { return null }
      }
    )
  }
  if(user.displayPaymentInfo) {
    links.push(
      {
        button: 'Payment Information',
        title: 'Payment Information',
        url: '/paymentinformation',
        isActive: pathname.includes('/paymentinformation'),
        icon: (css) => () => { return <FaMoneyCheck color={colors.white} className={css} /> },
        iconComingSoon: () => { return null }
      }
    )
  }

  // <IconNew size={32} style={isMobile ? mobileStyle : desktopStyle} /> 
  const settingsLink = {
    button: 'Settings',
    title: 'Settings',
    url: '/settings',
    icon: (css) => () => { return <FiSettings color={colors.white} className={css} /> },
    iconComingSoon: () => { return null }
  };

  return {
    goToLinks, goToSettings, logOut, goToSelectDistributor, goToFaq,
    links: !isMobile ? links : [...links, settingsLink]
  };
};

export default {
  useLinks
};