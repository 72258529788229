import { useEffect, useState } from 'react';
import distributorApi from '../../api/distributorApi';
import { useDispatch } from 'react-redux';
import { spinner } from '../../reducers/UIReducer/UIActions';
import { setActiveDistributor, setDistributorsList, setUserRolData } from '../../reducers/UserReducer/UserActions';
import { useHistory } from 'react-router-dom';
import userApi from '../../api/userApi';
import authApi from '../../api/authApi';
import startVersionCheck from '../../sharedComponents/Helper/versionCheck'
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import AlertModal from '../../sharedComponents/AlertModal/AlertModal';
import LocalStorage from "../../sharedComponents/LocalStorage/LocalStorage";
import logger from '../../sharedComponents/Logger/LogglyLogger';

const useInitUser = (initLoad) => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = LocalStorage.userData.get();


  useEffect(() => {
    startVersionCheck(dispatch);
    // Load User Data
    if(!initLoad) {
      dispatch(spinner.add());
    }
    const reqTs = new Date();
    userApi.currentDistributor.get().then(({ data }) => {
      const resTs = new Date();
      logger.push({
        tag: process.env.REACT_APP_LOGGLY_TAG,
        requestName: 'getCurrentDistributor',
        time: resTs - reqTs,
        reqTimestamp: reqTs,
        resTimestamp: resTs
      });
      setReady(true);
      if (data) {
        dispatch(setActiveDistributor(data));
        distributorApi.get().then(({ data }) => {
          if(!initLoad){
            dispatch(spinner.subtract());
          }
          dispatch(setDistributorsList(data));
        }).catch(err => {
          if(!initLoad) {
            dispatch(spinner.subtract());
          }
          console.error(err);
        });
      } else {
        if(!initLoad) {
          dispatch(spinner.subtract()); 
        }
        history.push('/logout');
        dispatch(showModal(AlertModal, {title: "This user cannot log in because there is no channel partner available."}));
      }
    }).catch(err => {
      console.error(err);
    });
    //Load Invite User
    const reqTsTwo = new Date();
    userApi.userInvite.get().then(({ data }) => {

      if (userData && !(userData.exportUser || userData.internalAdmin)) {
        // Notify backend that a session has started for this user.
        // A session can start at login or when the app loads with a user already logged in.
        authApi.session.post({id: userData.id}).catch(err => console.log(err));
      }
      
      const resTsTwo = new Date();
      logger.push({
        tag: process.env.REACT_APP_LOGGLY_TAG,
        requestName: 'usersAndRoles',
        time: resTsTwo - reqTsTwo,
        reqTimestamp: reqTsTwo,
        resTimestamp: resTsTwo
      });
      dispatch(setUserRolData(data))
    }).catch(err => {
      console.error(err);
    });
    // eslint-disable-next-line
  }, []);

  return ready;
};

export default useInitUser;