import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { showModal } from "../../../reducers/ModalsReducer/ModalsActions";
import RebateCheckModal from "./Subcomponents/RebateCheckModal";
import { setSortBy, setCheckTimeframe, setCheckLoading, setSearch, setCheck, setMerchants, setMerchantsWithChecks, setMerchantsLoading, setRebateChecksFilter } from "../../../reducers/RebateChecksReducer/RebateChecksActions";
import rebateChecksApi from "../../../api/rebateChecksApi";
import { useParams } from "react-router-dom";
import toTitleCase from "../../../sharedComponents/Helper/toTitleCase";
import sortArrayByKey from '../../../sharedComponents/Helper/sortArrayByKey';
import merchantApi from '../../../api/merchantApi';
import printJS from "print-js";
// import fileDownload from "js-file-download";
// import numeral from "numeral";
// import stringify from "csv-stringify";


const useRebateChecks = {
  useChecks: () => {
    const dispatch = useDispatch();
    const merchants = useSelector(({ rebateChecks }) => rebateChecks.merchants);
    let merchantsWithChecks = useSelector(({ rebateChecks }) => rebateChecks.merchantsWithChecks);
    const sortState = useSelector(({ rebateChecks }) => rebateChecks.rebateChecksSort);
    const search = useSelector(({ rebateChecks }) => rebateChecks.search);
    const merchantsLoadStatus = useSelector(({ rebateChecks }) => rebateChecks.merchantsLoadStatus);
    const merchantsLoading = useSelector(({ rebateChecks }) => rebateChecks.merchantsLoading);
    const distributor = useSelector(({ user }) => user.activeDistributor);
    const timeframe = useSelector(({ rebateChecks }) => rebateChecks.timeframe);
    const filters = useSelector(({ rebateChecks }) => rebateChecks.rebateChecksFilters);
    const [rowsSelected, setRowsSelected] = useState({});
    const [allSelected, setAllSelected] = useState(false);
    const [downloadVouchers, setDownloadVouchers] = useState(false);
    const [downloadCheckDetails, setDownloadCheckDetails] = useState(false);
    const [bulkExportDownloading, setBulkExportDownloading] = useState(false);
    const [bulkPrintDownloading, setBulkPrintDownloading] = useState(false);
    const [downloadingPayNoPaySummary, setDownloadingPayNoPaySummary] = useState(false);
    const impersonatedDistributorId = useSelector(({user}) => user.impersonateAs);
    

    const initialRender = useRef(false);
    // const intelMerchants = useSelector(({intel}) => intel.merchants);

    //////////////////////////////////////////////////////////////////

    // let done = false;
    // useEffect(() => {
    //   if(distributor && !done) {
    //     done = true;
    //     getBulk();
    //   }
    // }, [distributor])



    const assignChecksToMerchants = (merchants, checks) => {
      let assigned = checks.map(check => {
        let merchant = merchants.find(merchant => check.merchantid === merchant.id);
        if (merchant) {
          check = {
            ...merchant,
            check,
            checkAmount: check.amount,
            distributordistrict: check.distributordistrict
          }
        }
        return check;
      })
      assigned = assigned.filter(ass => ass.check && ass.checkAmount);
      return assigned;
    }
    //////////////////////////////////////////////////////////////////

    const getMerchants = async () => {
      dispatch(setMerchantsLoading(true));
      try {
        const merchants = await merchantApi.getIncludingParents();
        const checks = await rebateChecksApi.getChecks({ 
          timeframe, 
          distributorId: impersonatedDistributorId,
          distributorPlatformId: distributor.platform_id, 
          merchantIds: merchants.data.map(m => m.id) 
        });
        dispatch(setMerchants(merchants.data));

        if (!checks.data.message) {
          const merchantsWithChecks = assignChecksToMerchants(merchants.data, checks.data);
          dispatch(setMerchantsWithChecks(merchantsWithChecks));
        } else {
          dispatch(setMerchantsLoading(false, 'success'));
          dispatch(setMerchantsWithChecks([]));
        }
        dispatch(setMerchantsLoading(false, 'success'));
      } catch (err) {
        console.log(err)
        dispatch(setMerchantsLoading(false, 'failed'));
      }
    }

    //////////////////////////////////////////////////////////////////

    const downloadCheck = async (check) => {
      dispatch(showModal(RebateCheckModal, { check }));
    }

    //////////////////////////////////////////////////////////////////


    const getChecks = async () => {
      try {
        dispatch(setMerchantsLoading(true));
        const merchants = await merchantApi.getIncludingParents();

        const { data } = await rebateChecksApi.getChecks({ 
          timeframe, 
          distributorId: impersonatedDistributorId,
          distributorPlatformId: distributor.platform_id, 
          merchantIds: merchants.data.map(m => m.id) 
        });
        if (!data.message) {
          const merchantsWithChecks = assignChecksToMerchants(merchants.data, data);
          dispatch(setMerchantsLoading(false, 'success'));
          dispatch(setMerchantsWithChecks(merchantsWithChecks));
        } else {
          dispatch(setMerchantsLoading(false, 'success'));
          dispatch(setMerchantsWithChecks([]));
        }

      } catch (err) {
        console.log(err)
        dispatch(setMerchantsLoading(false, 'failed'));
      }
    }


    //////////////////////////////////////////////////////////////////

    useEffect(() => {
      if (merchants.length === 0 && distributor && distributor.id) {
        getMerchants();
      }
      // eslint-disable-next-line
    }, [distributor]);

    //////////////////////////////////////////////////////////////////

    useEffect(() => {
      if (distributor && distributor.id && merchants.length > 0 && initialRender.current === true) {
        getChecks();
      }
      // eslint-disable-next-line
    }, [timeframe]) 
 
    //////////////////////////////////////////////////////////////////

    useEffect(() => {
      initialRender.current = true;
    }, [])
    //////////////////////////////////////////////////////////////////

    const sortBy = (key) => {
      let order = key === sortState.sortBy ? !sortState.sortDesc : true;
      order = (key === 'merchant' && key !== sortState.sortBy) ? !order : order;
      dispatch(setSortBy('rebateChecks', key, order));
    };

    //////////////////////////////////////////////////////////////////

    if (merchantsWithChecks.length > 0) {
      merchantsWithChecks = merchantsWithChecks.filter(m => {
        return m.merchant.toLowerCase().includes(search.toLowerCase()) || m.id.toLowerCase().includes(search.toLowerCase())
      })
    }

    merchantsWithChecks = sortArrayByKey(merchantsWithChecks, sortState.sortBy, sortState.sortDesc);
    for (let filter in filters) {
      merchantsWithChecks = merchantsWithChecks.filter(m => {
        return filters[filter] === 'all' ? true : !m.check[filter] ? false : m.check[filter].toLowerCase() === filters[filter].toLowerCase()
      })
    }
    //////////////////////////////////////////////////////////////////

    const setMerchantsSearch = (keyword) => {
      dispatch(setSearch(keyword))
    }

    //////////////////////////////////////////////////////////////////

    const setTimeframe = (timeframe) => {
      dispatch(setCheckTimeframe(timeframe.value));
    }

    //////////////////////////////////////////////////////////////////
    const setFilter = (filter) => {
      dispatch(setRebateChecksFilter('paymentrouting', filter.value));
    }
    //////////////////////////////////////////////////////////////////
    let paymentRoutingOptions = [{
      label: 'All',
      value: 'all',
    },
    {
      label: 'Distributor',
      value: 'distributor',
    },
    {
      label: 'Sales',
      value: 'sales',
    },
    {
      label: 'Member',
      value: 'member',
    },
    {
      label: 'Portal',
      value: 'portal',
    },
    {
      label: 'Offset',
      value: 'offset',
    }
    ]
    //////////////////////////////////////////////////////////////////

    const generatePayNoPayReport = async () => {
      if (merchantsWithChecks.length > 0) {
        setDownloadingPayNoPaySummary(true);
        let response = await rebateChecksApi.payNoPaySum({ timeframe, distributorId: distributor.platform_id, merchantIds: merchants.map(m => m.id) });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const accountmaprelationship = merchantsWithChecks[0].check.accountmaprelationship;
        const a = document.createElement('a');
        a.href = url;
        a.download = `${timeframe} - Pay-No Pay Summary - ${accountmaprelationship}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setDownloadingPayNoPaySummary(false);
      }
    }
    //////////////////////////////////////////////////////////////////

    const selectAll = () => {
      if (allSelected) {
        setAllSelected(false);
        setRowsSelected({});
      } else {
        const selected = {};
        merchantsWithChecks.forEach(m => {
          selected[m.id] = m.check;
        })
        setAllSelected(true);
        setRowsSelected(selected);
      }
    }
    //////////////////////////////////////////////////////////////////

    const triggerBulkExport = async () => {
      if (downloadVouchers || downloadCheckDetails) {
        setBulkExportDownloading(true)
        let merchantIds = [];
        for (let check in rowsSelected) {
          merchantIds.push(rowsSelected[check].merchantid)
        }
        const response = await rebateChecksApi.bulkDataExport(
          {
            timeframe, distributorId: distributor.platform_id, merchantIds, getVouchers: downloadVouchers, getCheckDetails: downloadCheckDetails
          });
        setBulkExportDownloading(false)

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `${timeframe} - BulkExport.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

    }

    //////////////////////////////////////////////////////////////////

    const triggerBulkPrint = async () => {
      if (downloadVouchers || downloadCheckDetails) {
        setBulkPrintDownloading(true);
        // setBulkExportDownloading(true)
        let merchantIds = [];
        for (let check in rowsSelected) {
          merchantIds.push(rowsSelected[check].merchantid)
        }
        const response = await rebateChecksApi.bulkDataPrint(
          {
            timeframe, distributorId: distributor.platform_id, merchantIds, getVouchers: downloadVouchers, getCheckDetails: downloadCheckDetails
          });
        setBulkPrintDownloading(false);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        printJS({ printable: url, type: 'pdf', });




        // setBulkExportDownloading(false)

        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = `${timeframe} - BulkExport.zip`;
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);
      }

    }


    return {
      triggerBulkExport,
      triggerBulkPrint,
      rowsSelected,
      setRowsSelected,
      allSelected,
      selectAll,
      sortState,
      sortBy,
      search,
      setMerchantsSearch,
      merchantsLoadStatus,
      merchantsLoading,
      timeframe,
      setTimeframe,
      merchantsWithChecks,
      downloadCheck,
      paymentRoutingOptions,
      generatePayNoPayReport,
      setFilter,
      setDownloadVouchers,
      setDownloadCheckDetails,
      downloadCheckDetails,
      downloadVouchers,
      bulkExportDownloading,
      setBulkExportDownloading,
      bulkPrintDownloading,
      setBulkPrintDownloading,
      downloadingPayNoPaySummary
    }
  },

  useCheckDetails: () => {
    const timeframe = useSelector(({ rebateChecks }) => rebateChecks.timeframe);
    const distributor = useSelector(({ user }) => user.activeDistributor);
    const loading = useSelector(({ rebateChecks }) => rebateChecks.checkLoading);
    const loadStatus = useSelector(({ rebateChecks }) => rebateChecks.checkLoadStatus)
    const fetchedCheck = useRef(false);
    const check = useSelector(({ rebateChecks }) => rebateChecks.check);
    // const [check, setCheck] = useState(false);
    const [toMerchantsBreakdown, setToMerchantsBreakdown] = useState([]);
    const [fromManufBreakdown, setFromManufBreakdown] = useState([]);
    const { merchantid } = useParams();
    const dispatch = useDispatch();

    //////////////////////////////////////////////////////////////////

    const getCheck = async () => {
      dispatch(setCheckLoading(true));
      let check;
      try {
        check = await rebateChecksApi.getCheck({ timeframe, distributorId: distributor.platform_id, merchantId: merchantid });
        if (check && check.data.sfid) {
          dispatch(setCheckLoading(false, 'success'));
        } else {
          dispatch(setCheckLoading(false, 'nonexistent'));
        }
      } catch (err) {
        dispatch(setCheckLoading(false, 'failure'));
      }

      if (check && check.data.sfid) {
        dispatch(setCheck(check.data));
        const toMerchantsBd = check.data.toMerchantsCheckBreakdown.map(rebatesGottenByMerch => {
          return {
            name: toTitleCase(rebatesGottenByMerch.merchant),
            amount: rebatesGottenByMerch.amount
          }
        })
        const fromManufBd = check.data.fromManufacturersCheckBreakdown.map(rebatesGivenByManuf => {
          return {
            name: rebatesGivenByManuf.manufacturer,
            amount: rebatesGivenByManuf.amount
          }
        })
        setToMerchantsBreakdown(toMerchantsBd);
        setFromManufBreakdown(fromManufBd);
      }
    }

    //////////////////////////////////////////////////////////////////

    useEffect(() => {
      if (distributor !== null && fetchedCheck.current === false) {
        fetchedCheck.current = true;
        getCheck();
      }
      // eslint-disable-next-line
    }, [distributor]);

    //////////////////////////////////////////////////////////////////

    useEffect(() => {
      if (distributor !== null) {
        setCheck(false);
        getCheck();
      }
      // eslint-disable-next-line
    }, [timeframe]);

    //////////////////////////////////////////////////////////////////

    const setTimeframe = (timeframe) => {
      dispatch(setCheckTimeframe(timeframe.value));
    }

    return {
      check,
      toMerchantsBreakdown,
      fromManufBreakdown,
      timeframe,
      setTimeframe,
      loading,
      loadStatus,
    }
  },

  useModal: () => {
    const [checkPicUrl, setCheckPicUrl] = useState(false);
    const getCheckPic = async (check) => {
      const { data } = await rebateChecksApi.getCheckPic(check, { responseType: 'text' });
      setCheckPicUrl(data);
    }

    return {
      getCheckPic,
      checkPicUrl
    }
  },

  useExportData: () => {
    const distributor = useSelector(({ user }) => user.activeDistributor);
    const timeframe = useSelector(({ rebateChecks }) => rebateChecks.timeframe);
    const exportCheckData = async (merchantId, merchant) => {

      const response = await rebateChecksApi.checkDetails({ timeframe, distributorId: distributor.platform_id, merchantId });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `${timeframe} - Check Details - ${merchant}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    // const prepareItemsList = (type, data) => {
    //   let csvData = [];
    //   csvData.push([type === 'location' ? 'locationName' : 'manufacturerName', 'amount']);
    //   data.forEach(({ name, amount }) => {
    //     csvData.push([
    //       name, numeral(amount).format('$0,0.00')
    //     ])
    //   });
    //   return csvData;
    // }
    // const exportCsv = (locationData, manufacturerData, fileName) => {
    //   let preCsvLocationsArray = prepareItemsList('location', locationData);
    //   let preCsvManufacturerArray = prepareItemsList('manufacturer', manufacturerData);
    //   let combined = preCsvLocationsArray.concat(preCsvManufacturerArray)

    //   if (combined.length > 0) {
    //     stringify(combined, (err, output) => {
    //       fileDownload(output, fileName);
    //     });
    //   }
    // }
    return {
      exportCheckData
    }
  }
}


export default useRebateChecks;