import React from 'react';
import { Row, Col, CardBody,
   ButtonGroup, Button 
  } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import stringSanitizer from 'string-sanitizer';
import numeral from 'numeral';

const RebateChecksRow = ({ id, dcn, merchant, date, checkAmount, check, downloadCheck, exportCheckData, selectRow, rowsSelected }) => {
  let history = useHistory();
  const onClick = () => {
    merchant = stringSanitizer.sanitize.keepSpace(merchant);
    history.push('/paymentinformation/' + id + '/' + merchant);
  }

  if (dcn === '') {
    dcn = '--';
  }

  const download = () => {
    downloadCheck(check)
  }

  const exportData = () => {
    exportCheckData(id, merchant);
  }

  const selectRowFunc = () => {
    let clone = JSON.parse(JSON.stringify(rowsSelected))
    if (clone[id]) {
      delete clone[id];
      selectRow(clone)
    } else {
      selectRow({ ...rowsSelected, [id]: check })
    }
  }

  if (isMobile) {
    return (
      <CardBody className='border-bottom p-3'>
        <Row className='pb-3 d-flex justify-content-between'>
          <Col xs={11} className='font-weight-bold text-capitalize'>
            {check && check.merchantid ? check.merchantid : ''}
          </Col>
          <Col xs={1} className="d-flex justify-content-end">
            <ButtonGroup>
              <Button
                color="primary p-2"
                outline
                onClick={selectRowFunc}
                active={rowsSelected.hasOwnProperty(id)}
                style={{ height: '10px', width: '10px' }}
              >
              </Button>

            </ButtonGroup>
          </Col>

        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Customer Name</Col>
          <Col className='text-right'>
            {merchant}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Distributor District</Col>
          <Col className='text-right'>
            {check.distributordistrict}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Amount</Col>
          <Col className='text-right'>
            {numeral(checkAmount).format('$0,0.00')}
          </Col>
        </Row>

        <Col onClick={exportData} className={'px-0 pt-3 see-details-label text-right text-beplMagenta clickable d-flex justify-content-end align-items-center'}>
          Export
        </Col>
        <Col onClick={download} className={'px-0 pt-3 see-details-label text-right text-beplMagenta clickable d-flex justify-content-end align-items-center'}>
          Download
        </Col>
        <Col onClick={onClick} className={'px-0 pt-3 see-details-label text-right text-beplMagenta clickable d-flex justify-content-end align-items-center'}>
          Details
        </Col>

      </CardBody>
    )
  } else {
    return (
      <CardBody className='border-top py-2 px-3 hover-row'>

        <Row className='align-items-center'>
          <ButtonGroup>
            <Button
              color="primary p-2 ml-3"
              outline
              onClick={selectRowFunc}
              active={rowsSelected.hasOwnProperty(id)}
            >
            </Button>

          </ButtonGroup>
          <Col xs={1}>
            {check && check.merchantid ? check.merchantid : ''}
          </Col>
          <Col xs={2}>
            {merchant}
          </Col>
          <Col xs={2}>
            {check.distributordistrict}
          </Col>
          <Col xs={2}>
            {numeral(checkAmount).format('$0,0.00')}
          </Col>
          <Col xs={4} className={'see-details-label text-right text-beplMagenta clickable d-flex  align-items-center justify-content-end'}>
            <div onClick={onClick} className="mr-3">
              View Details
            </div>
            <div className='mr-3' onClick={exportData} >
              Download Details
            </div>
            <div className='' onClick={download}>
              Voucher
            </div>
          </Col>
        </Row>
      </CardBody>
    );
  }

}

export default RebateChecksRow;