import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, CardBody, Col, Row } from 'reactstrap';
import { showModal } from '../../../../reducers/ModalsReducer/ModalsActions';
import FaqVideoModal from './VideoModal';
function VideoListItem({ title, url, image, content }) {
  const dispatch = useDispatch();
  const openVideoModal = () => {
    dispatch(showModal(FaqVideoModal, { title, url }));
  }
  return (
    <Col className="m-0 p-0">
      <CardBody className="px-3 pt-0 pb-3">
        <Row>
          <Col xs={5} className="p-0 m-0">
            <img style={{ width: '100%' }} src={image} alt={title} />
          </Col>
          <Col xs={7} className="d-flex flex-column">
            <Row className="m-0 justify-content-between align-items-center text-size-20 mb-3" style={{ lineHeight: '15px' }}>
              {title}
            </Row>
            <Col className="m-0 p-0">{content}</Col>
            <Button onClick={openVideoModal} className="border-beplMagenta border-2 bg-white text-beplMagenta align-self-start">Watch Now</Button>
          </Col>
        </Row>
      </CardBody>
    </Col>
  );
}

export default VideoListItem;