import userApi from "../../api/userApi";
import merchantApi from "../../api/merchantApi";
import authApi from '../../api/authApi';
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { spinner } from "../../reducers/UIReducer/UIActions";
import { useReducer } from "react";
import { useHistory } from "react-router-dom";
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import AlertModal from '../../sharedComponents/AlertModal/AlertModal';
import analyticsApi from '../../api/analyticsApi';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER_LIST':
      return {
        ...state,
        users: action.payload.map(u => {
          u.displayIntel = u.displayIntel && u.displayIntel !== null ? 1 : 0;
          u.displayInvoice = u.displayInvoice && u.displayInvoice !== null ? 1 : 0;
          u.isMultiHouse = u.isMultiHouse && u.isMultiHouse !== null ? 1 : 0;
          return {
            label: `${u.contactName} ${u.email}`,
            value: {
              ...u,
              isAdmin: false
            }
          };
        }),
        selectedUser: null,
        editUser: null
      }
    case 'SELECT_OPTION':
      return {
        ...state,
        selectedUser: action.payload,
        editUser: action.payload.value
      }
    case 'SET_EDIT_USER':
      return {
        ...state,
        editUser: action.payload
      }
    case 'EDIT_PASSWORD':
      return {
        ...state,
        editPassword: action.payload
      }
    case 'SET_ANALYTICS':
      return {
        ...state,
        analytics: {...state.analytics, ...action.payload }
      }
    default:
      return state;
  }
};

const useAdminPanel = () => {
  // const [isGroupUser, setIsGroupUser] = useState(false);
  const [{ users, selectedUser, editUser, editPassword, analytics }, set] = useReducer(
    reducer,
    {
      users: [],
      selectedUser: null,
      editUser: null,
      editPassword: '',
      analytics: null
    }
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const fetchUserSessions = async () => {
    function filterSessionsLast30days(sessions) {
      const currentDate = new Date();
      const thirtyDaysAgo = currentDate.setDate(currentDate.getDate() - 30);
      sessions = sessions.filter(item => {
        const endTime = new Date(item.end_time);
        return endTime >= thirtyDaysAgo;
      });
      return sessions;
    }
    const { data } = await analyticsApi.userSessions.get({ user_id: editUser.id });
    // const { data } = await analyticsApi.userSessions.get({ user_id: 4 });
    let sessions = filterSessionsLast30days(data);
    const totalTimeSpent = sessions.reduce((a, b) => { 
      b = b.time ? b.time : 0;
      return a + b;
    } , 0)
    setAnalytics({numOfSessions: sessions.length, totalTime: totalTimeSpent})

  }

  useEffect(() => {
    if (editUser !== null) {
      fetchUserSessions()
    }
  // eslint-disable-next-line
  }, [editUser])

  useEffect(() => {
    dispatch(spinner.add());
    userApi.get().then(({ data }) => {
      dispatch(spinner.subtract());
      set({ type: 'SET_USER_LIST', payload: data });
    });
    // eslint-disable-next-line
  }, []);

  const onChange = async (opt) => {
    set({ type: 'SELECT_OPTION', payload: opt });
  };

  const onLogout = () => {
    history.push('/logout');
  };

  const patchUser = (e) => {
    e.preventDefault();

    // Get all locations for this user
    dispatch(spinner.add());
    merchantApi.available.get(selectedUser.value.id).then(({ data }) => {
      if (data.locations.length > 0) {
        const locations = data.locations.map(loc => loc.id);

        const params = {
          contactName: editUser.contactName,
          email: editUser.email,
          title: selectedUser.value.title,
          locations: data.inviteAll ? [] : locations,
          siteUrl: window.location.origin,
          displayIntel: selectedUser.value.displayIntel ? true : false,
          displayInvoice: !!editUser.displayInvoice,
          displayPaymentInfo: editUser.displayPaymentInfo ? true : false,
          isMultiHouse: !!editUser.isMultiHouse,
        };

        // Update this user
        userApi.userInvite.patch(selectedUser.value.id, params).then(() => {
          // load the user list again
          userApi.get().then(({ data }) => {
            dispatch(spinner.subtract());
            set({ type: 'SET_USER_LIST', payload: data });
            dispatch(showModal(AlertModal, { title: "User updated successfully!" }));
          });
        }).catch(err => {
          dispatch(spinner.subtract());
          let msg = "An unknown error occurred when trying to update this user (see console for possible reasons)";
          if (err) {
            if (err.data) {
              // Validation errors from backend
              msg = err.data.message;
            }
            dispatch(showModal(AlertModal, { title: msg }));
            console.log(err);
          }
        })
      }
    }).catch(err => {
      dispatch(spinner.subtract());
      dispatch(showModal(AlertModal, { title: "Couldn't load location list for this user. Update failed." }));
      console.log(err);
    });
  }

  const changeUserPassword = (e) => {
    e.preventDefault();

    const params = {
      password: editPassword,
      newPassword: editPassword
    };

    dispatch(spinner.add());
    authApi.resetPassword(editUser.passwordResetToken).post(params).then(() => {

      // load the user list again 
      userApi.get().then(({ data }) => {
        dispatch(spinner.subtract());
        set({ type: 'SET_USER_LIST', payload: data });
        dispatch(showModal(AlertModal, { title: "User's password has been changed successfully." }));
      });

    }).catch(err => {
      dispatch(spinner.subtract());
      let msg = 'An unknown error occurred when trying to update this user (see console for possible reasons)';
      if (err.data) {
        if (err.data.message === 'token expired') {
          msg = "User's password reset link has expired. Click the button below to request a new link.";
          dispatch(showModal(AlertModal, {
            title: msg,
            buttonClick: () => history.push('/forgot-password'),
            buttonText: 'Forgot Password'
          }));
        }
        else { // an unknown error
          dispatch(showModal(AlertModal, { title: msg }));
        }
      }
    });
  }



  const setUserEmail = (newVal) => {
    set({ type: 'SET_EDIT_USER', payload: { ...editUser, email: newVal } });
  }

  const setUserContactName = (newVal) => {
    set({ type: 'SET_EDIT_USER', payload: { ...editUser, contactName: newVal } });
  }

  const toggleUserDisplayInvoice = () => {
    set({ type: 'SET_EDIT_USER', payload: { ...editUser, displayInvoice: !editUser.displayInvoice } });
  }

  const toggleUserMultiHouse = () => {
    set({ type: 'SET_EDIT_USER', payload: { ...editUser, isMultiHouse: !editUser.isMultiHouse } });
  }

  const setUserPassword = (newVal) => {
    set({ type: 'EDIT_PASSWORD', payload: newVal });
  }

  const setAnalytics = (fields) => {
    set({ type: 'SET_ANALYTICS', payload: fields });
  }

  return {
    selectProps: {
      onChange,
      value: selectedUser,
      options: users
    },
    onLogout,
    editUser,
    patchUser,
    changeUserPassword,
    setUserEmail,
    setUserContactName,
    setUserPassword,
    toggleUserDisplayInvoice,
    toggleUserMultiHouse,
    analytics
  };
};

export default useAdminPanel;