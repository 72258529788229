import React, { useState } from 'react';
import useModal from '../../../sharedComponents/useModal/useModal';
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { MdClose } from 'react-icons/md';
import { selectStyles } from '../../../config/constants';
import Select from 'react-select';
import distributorApi from '../../../api/distributorApi';
import stringify from 'csv-stringify';
import fileDownload from 'js-file-download';
import moment from 'moment';

const ExportModal = ({ id, distributors }) => {
  const { modalProps } = useModal(id, { isMenu: true, isFullscreen: true });
  const [childDistributorForUserExport, setChildDistributorForUserExport] = useState();
  if (!isMobile) {
    // modalProps.className = 'img-view-upload-modal-wrapper';
    // modalProps.contentClassName = 'img-view-upload-modal';
  }



  const prepareItemsList = (data) => {
    let csvData = [];
    csvData.push(['Distributor', 'Name', 'Email', 'Role',
      'Intel', 'Payment Info', 'Created Date', 'Last Login Date', 'Houses']);

    data.forEach(({ activeDistributor, contactName, email, title, displayIntel, displayPaymentInfo, createdDate, lastLoginDateTime, distributors }) => {
      csvData.push([
        activeDistributor.nickname, contactName, email, title, displayIntel === 1 ? 'true' : 'false', displayPaymentInfo === 1 ? 'true' : 'false',
        moment(createdDate).calendar(), moment(lastLoginDateTime).calendar(), '[' + distributors.map(d => d.nickname) + ']'
      ])
    });
    return csvData;
  }


  
  const exportUsers = async () => {
    let usersToExport;
    if(childDistributorForUserExport.value === 'all') {
      let parent = distributors.find((d) => d.data.nickname.includes('(PA)') && d.data.parent_id === null);
      let users = await distributorApi.getUsersOfFamily(parent.data.platform_id);
      usersToExport = users.data;
    } else {
      let users = await distributorApi.getUsersOfChildDistributor(childDistributorForUserExport.data.platform_id);
      usersToExport = users.data
    }

    if(usersToExport.length > 0) {
      let housesForUsersPromises = usersToExport.map(u=>{
        return distributorApi.houses(u.id);
      })
      let houses = await Promise.all(housesForUsersPromises);
      const users = usersToExport.map((u, i) => {
        u.distributors = houses[i].data;
        return u;
      })
      let preCsvArray = prepareItemsList(users);
      if (preCsvArray.length > 0) {
        stringify(preCsvArray, (err, output) => {
          fileDownload(output, 'UsersExport.csv');
        });
      }
    }
  }

  return (
    <Modal {...modalProps} className='d-flex align-items-center' style={{ paddingTop: '150px' }}>
      <Row className="m-0 justify-content-between d-flex font-weight-bold p-3">
        <Col xs="8">Export Users</Col>
        <MdClose onClick={modalProps.toggle} size={25} className='clickable' />
      </Row>
      <ModalBody className={`${isMobile ? 'px-1' : ''} position-relative mt-0 pt-0`} >
        <Row className="font-weight-bold text-size-20 justify-content-center mb-4">Pick a Distributor</Row>
        <Select
          styles={selectStyles}
          placeholder='Distributors'
          options={[{label: 'All Houses For Family', value: 'all'}, ...distributors]}
          onChange={setChildDistributorForUserExport}
          value={childDistributorForUserExport}
        />
        <Button color='beplMagenta' onClick={exportUsers} className='py-2 button-label mr-2 w-100 mt-3'>Export</Button>
      </ModalBody>
    </Modal>
  )
}

export default ExportModal;