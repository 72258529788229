import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Input, Button } from 'reactstrap';
import useModal from '../../sharedComponents/useModal/useModal';
import useAnnouncements from './useAnnouncements';
import { BsFillTrashFill } from 'react-icons/bs';

const AnnouncementModal = ({ id }) => {
  const { modalProps, headerProps } = useModal(id, { isMenu: true, isFullscreen: true });
  const { 
    announcements, 
    announcementInput, 
    setAnnouncementInput,
    onCreate,
    onDelete
  } = useAnnouncements();

  const announceElements = announcements.map((a) => {
    return (
      <Row className='py-2 border' key={`announcement-row-${a.id}`}>
        <Col>
          <div>{a.text}</div>
        </Col>
        <Col className="w-fc">
        <BsFillTrashFill 
          onClick={() => onDelete(a.id)} 
          className='' 
          size={17} 
          color="grey"
          onMouseOver={({ target }) => target.style.color = "#DD3444"}
          onMouseOut={({ target }) => target.style.color = "grey"} />
        </Col>
      </Row>
    );
  });
  
  return (
    <Modal  {...modalProps}>
      <ModalHeader {...headerProps} >System Announcements</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={9}>
            <Input 
              type="text"
              value={announcementInput} 
              onChange={(e) => setAnnouncementInput(e.target.value)}
              placeholder="Your announcement text here"
            />
          </Col>
          <Col>
            <Button 
              outline 
              disabled={announcementInput.length === 0}
              onClick={onCreate} 
              block 
              color='beplMagenta' 
              className='py-2 button-label'>
              Create
            </Button>
          </Col>
        </Row>
        {announcements.length > 0 && <Row className='py-4'>Current Announcements:</Row>}
        {announceElements}
      </ModalBody>
    </Modal>
  );

}

export default AnnouncementModal;