import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AppMain from '../AppMain/AppMain';
import SignUp from '../SignUp/SignUp';
import Login from '../Login/Login';
import { useSelector } from 'react-redux';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import Activate from '../Activate/Activate';
import CreatePassword from '../CreatePassword/CreatePassword';
import Logout from '../AppMain/Logout/Logout';
import AdminLogin from '../AdminLogin/AdminLogin';
import ChangePasswordToken from '../ChangePasswordToken/ChangePasswordToken';
import AdminPanel from '../AdminPanel/AdminPanel';
import { withMsal } from '@azure/msal-react';
import Maintenance from '../AppMain/Maintenance/Maintenance';
import BusinessPanel from '../BusinessPanel/BusinessPanel';
import AdminDashboard from '../AdminDashboard/AdminDashboard';


const Router = () => {
  const isLogin = useSelector(({ user }) => user.isLogin);
  const isAdmin = useSelector(({ user }) => user.isAdmin);

  if (process.env.REACT_APP_MAINTENANCE) {
    return (
      <div style={{ marginTop: '81px', width: '100%' }}>
        <Switch>
          <Route exact path='/maintenance' component={Maintenance} />
          <Route>
            <Redirect to='/maintenance' />
          </Route>
        </Switch>
      </div>
    )
  }
  if (isLogin) {
    if (isAdmin) {
      return (
        <div style={{ marginTop: '81px', width: '100%' }}>
          <Switch>
            <Route exact path='/logout' component={Logout} />
            <Route exact path='/admin-login' component={AdminLogin} />
            <Route exact path='/admin-panel' component={AdminPanel} />
            <Route exact path='/business-panel' component={BusinessPanel} />
            <Route exact path='/admin-dashboard' component={AdminDashboard} />
            <Route>
              <Redirect to='/admin-login' />
            </Route>
          </Switch>
        </div>
      );
    }
    return (
      <Switch>
        <Route exact path='/logout' component={Logout} />
        <Route path='/' component={AppMain} />
      </Switch>
    );
  }
  return (
    <div style={{ marginTop: '81px', width: '100%' }}>
      <Switch>
        <Route exact path='/signup' component={SignUp} />
        <Route exact path='/activate/:token+' component={Activate} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/create-password/:token+' component={CreatePassword} />
        <Route exact path='/change-password/:token+' component={ChangePasswordToken} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/admin-dashboard' component={AdminDashboard} />

        <Route>
          <Redirect to='/login' />
        </Route>
      </Switch>
    </div>

  );
};

export default withMsal(Router);