import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CompetitiveInsightMerchantRow from './CompetitiveInsightMerchantRow';
import sortArrayByKey from '../../../../../sharedComponents/Helper/sortArrayByKey';
import { setDetailSortBy, setFilteredCompMerchantList, setOrderHistoryCalendarDate } from '../../../../../reducers/IntelReducer/IntelActions';
import stringify from 'csv-stringify';
import fileDownload from 'js-file-download';
import numeral from 'numeral';
import { useHistory } from 'react-router-dom';
import stringSanitizer from 'string-sanitizer';
import moment from 'moment';

const useApi = (categories, items) => {
  const dispatch = useDispatch();
  const sortState = useSelector(({intel}) => intel.detailOptions.competitiveInsightMerchantSort);
  const merchants = useSelector(({intel}) => intel.merchants);
  const allLoaded = useSelector(({intel}) => intel.allLoaded);
  const redirectFromDashboardToItemDetails = useSelector(({dashboard}) => dashboard.redirectFromDashboardToItemDetails)
  const history = useHistory();
  //console.log(merchants);
  //console.log(`CATEGORIES: ${categories} (${categories.length})`);

  const sortBy = (key) => {
    let order = key === sortState.sortBy ? !sortState.sortDesc : true;
    order = (key === 'merchant' && key !== sortState.sortBy) ? !order : order;
    dispatch(setDetailSortBy('competitiveInsightMerchantSort', key, order));
  };

  let filteredMerchants = [];
  let compItems = [];

  merchants.forEach(merchant => {
    let filteredDistribs = [];

    merchant.data.forEach(distributor => {
      let filteredItems = [];

      // filter this distributor's items by category
      filteredItems = categories.length > 0 ? 
        distributor.items.filter(item => categories.includes(item.category)) : 
        distributor.items;

      // filter this distributor's items by item desc
      if (items.length > 0) {
        filteredItems = filteredItems.filter(item => items.includes(item.description))
      }
      
      // recalculate this distributor's volume
      const filteredVolume = filteredItems.reduce((prev, cur) => { return prev + cur.purchase}, 0);

      compItems.push(filteredItems);

      filteredDistribs.push({...distributor, volume: filteredVolume, items: filteredItems});
    });

    // recalculate this merchant's volume statistics
    const totalVolume = filteredDistribs.reduce((prev, cur) => { return prev + cur.volume}, 0);

    // now that total volume is known, go back and update this merchant's distributor percentages
    filteredDistribs = filteredDistribs.map(distributor => {
      return {
        ...distributor,
        percentage: totalVolume > 0 ? (distributor.volume / totalVolume) * 100 : 0
      };
    });

    const competitorVolume = filteredDistribs
      .filter(d => d.competitor === true)
      .reduce((prev, cur) => { return prev + cur.volume}, 0);
    const competitorVolumePerc = filteredDistribs
      .filter(d => d.competitor === true)
      .reduce((prev, cur) => { return prev + cur.percentage/100}, 0);
    const distributorVolume = filteredDistribs
      .filter(d => d.competitor === false)
      .reduce((prev, cur) => { return prev + cur.volume}, 0);
    const distributorVolumePerc = filteredDistribs
      .filter(d => d.competitor === false)
      .reduce((prev, cur) => { return prev + cur.percentage/100}, 0);
    filteredMerchants.push({
      ...merchant,
      data: filteredDistribs,
      totalVolume,
      competitorVolume,
      competitorVolumePerc,
      distributorVolume,
      distributorVolumePerc
    });
  });

  // sort merchants
  filteredMerchants = sortArrayByKey(filteredMerchants, 
    sortState.sortBy, 
    sortState.sortDesc
  );
 
  const filterMerchantsBy = (merchants, filterBy) => {
    if(filterBy === 'volume') {
      merchants = merchants.filter(merchant => {      
        return merchant.competitorVolume > 0;
      });
    } else {
      merchants = merchants.filter(merchant => {
        let distributors = merchant.data;
        let hasDesiredValue = false;
        distributors.forEach(distributor => {
          distributor.items.forEach(item => {
            if(item[filterBy] > 0) {
              hasDesiredValue = true
            }
          })
        });
        return hasDesiredValue;
      });
    }
    return merchants;
  }

  useEffect(() => {
    dispatch(setOrderHistoryCalendarDate(new Date(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + 'T00:00:00')));
   // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(allLoaded && redirectFromDashboardToItemDetails.redirecting) {
      if(redirectFromDashboardToItemDetails.type === 'rebate') {
        // eslint-disable-next-line
        filteredMerchants = filterMerchantsBy(filteredMerchants, 'rebate');
      } else if(redirectFromDashboardToItemDetails.type === 'deviation') {
        // eslint-disable-next-line
        filteredMerchants = filterMerchantsBy(filteredMerchants, 'deviationValue');
      } else {
        // eslint-disable-next-line
        filteredMerchants = filterMerchantsBy(filteredMerchants, 'volume')
      }
      if(filteredMerchants.length === 1) {
        let merchant = stringSanitizer.sanitize.keepSpace(filteredMerchants[0].merchant);
        let cleanUrl = '/competitiveinsights/items/' + merchant + '/' + filteredMerchants[0].id;
        history.push(cleanUrl);
        // dispatch(setCompetitiveInsightItemView(filteredMerchants[0].id));
      }
    }

    if(allLoaded) {
      dispatch(setFilteredCompMerchantList(filteredMerchants));
    }
    // eslint-disable-next-line
  }, [filteredMerchants, allLoaded, redirectFromDashboardToItemDetails]);

  // create merchant rows
  const merchantMap = filteredMerchants.map(mer => (
    <CompetitiveInsightMerchantRow key={`merchant-${mer.id}-list`} {...mer} name={mer.merchant}/>
  ));

  
  const totalCompVolume = filteredMerchants.reduce(
    (prev, cur) => { return prev + cur.competitorVolume}, 0
  );

  // combine all merchants' items into one array
  compItems = compItems.flat();

  return {
    merchantMap,
    compItems,
    totalCompVolume,
    sortState,
    sortBy
  };
};

const useCsvExport = () => {
  const filteredMerchantsList = useSelector(({intel}) => intel.filteredCompMerchantsList, (prev, next) => prev.length === next.length);
  let prepareMerchantsList = (data) => {
    let csvData = [];
    csvData.push([
      'id', 'dcn', 'merchant', 'totalVolume', 'competitorVolume', 'competitorVolumePerc'
    ])
    data.forEach(({id, dcn, merchant, totalVolume, competitorVolume, competitorVolumePerc}) => {
      csvData.push([
        id, dcn, merchant, numeral(totalVolume).format('0,0.00'),  numeral(competitorVolume).format('0,0.00'), numeral(competitorVolumePerc).format('0%')
      ])
    });
    return csvData;
  }
 
  const exportCsv = (data, fileName) => {
    let preCsvArray = prepareMerchantsList(data);
    if (preCsvArray.length > 0) {
      stringify(preCsvArray, (err, output) => {
        fileDownload(output, fileName);
      });
    }
  }

  const exportMerchantsList = () => {
    let data = filteredMerchantsList;
    if(data.length > 0) {
      exportCsv(data, 'competitiveMerchantList - For internal use only.csv');
    }
  }

  return {
    exportMerchantsList
  }
}

export default {
  useApi, useCsvExport
};