import React, { useState } from 'react';
import numeral from 'numeral';
import { Row, Col } from 'reactstrap';
import { If, Then, Else } from 'react-if';
import useDonutChart from './useDonutCharts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { GoPrimitiveDot } from 'react-icons/go';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { randomColors } from '../../../../config/constants';
import { isMobile } from 'react-device-detect';
import sortArrayByKey from '../../../../sharedComponents/Helper/sortArrayByKey';

const PAGE_SIZE_DISPLAYED_ITEMS = 15;
const SHOW_MORE = "Show more";
const SHOW_LESS = "Show less";

const RebateChecksTopChart = ({ topData }) => {
  const MAXIMUM_DISPLAYED_ITEMS = topData.length;
  const [displayedItems, setDisplayedItems] = useState(PAGE_SIZE_DISPLAYED_ITEMS);
  topData = topData.map(d => { 
    d.amount = (d.amount < 0 || d.amount === null || !d.amount) ? 0 : d.amount;
    return d;
  });
  let data = topData.slice(0, MAXIMUM_DISPLAYED_ITEMS);
  const [sortBy, setSortBy] = useState('amount');
  data = sortArrayByKey(data, sortBy, true );

  const handleShowMore = () => {
    setDisplayedItems(displayedItems + PAGE_SIZE_DISPLAYED_ITEMS);
  }

  const handleShowLess = () => {
    setDisplayedItems(displayedItems - PAGE_SIZE_DISPLAYED_ITEMS);
  }

  const mapChartData = () => {
    const chartData = [];
    for (let i = 0; i < data.length; i++) {
      if (i < displayedItems) {
        const name = data[i].name;
        chartData.push({
          name: name,
          amount: data[i].amount,
          color: randomColors[i]
        });
      } else {
        break;
      }
    }
    return chartData;
  }

  const { chartOptions } = useDonutChart({ noData: false, data: mapChartData() });

  const legendNamesAndVolumesMap = data.map((e, i) => {
    if (i < displayedItems) {
      const name = e.name;
      const amount = numeral(e.amount).format('$0,0.00');
      return (
        <Row
          key={`${name}-${i}`}
          style={{ padding: '.7rem 0 .7rem 0' }}
          className='clickable hover-row rounded-left'>
          <Col xs='auto' className='pl-2 px-0 d-flex align-items-center' >
            <GoPrimitiveDot className='text-size-20' color={randomColors[i]} />
          </Col>
          <Col xs={isMobile ? 5 : 6} className='px-1'>
            {name}
          </Col>
          <Col xs={4} className='text-right'>
            {amount}
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  });

  return (
    <Row className='align-items-top py-3'>
      <Col xs={isMobile ? 12 : 4} >
        <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={{ className: 'highcharts-parent' }} />
      </Col>
      <Col className='text-size-14 pt-2'>
        <Row className="d-flex justify-content-start">
          <Col xs="auto" className="" style={{ paddingLeft: '12px' }}></Col>
          <Col xs={6} className="px-1 text-size-10 text-uppercase justify-content-start d-flex">
            <div onClick={() => setSortBy('name')} className={`px-2 py-2 mx-0 clickable ${sortBy === 'name' ? 'sort-active-line sort-active-text' : ' '}`}>
              Name
            </div>
          </Col>
          <Col xs={4} className="px-1 text-size-10 text-uppercase justify-content-end d-flex">
            <div onClick={() => setSortBy('volume')} className={`px-2 py-2 mx-0 clickable ${sortBy === 'volume' ? 'sort-active-line sort-active-text' : ' '}`}>
              Amount
            </div>
          </Col>
        </Row>
        {legendNamesAndVolumesMap}
        <Row>
          <If condition={
            (displayedItems < MAXIMUM_DISPLAYED_ITEMS) && (displayedItems < topData.length)
          }>
            <Then>
              <Col>
                <div className="see-details-label text-beplMagenta clickable"
                  onClick={handleShowMore}>
                  {SHOW_MORE} <IoMdArrowDropdown />
                </div>
              </Col>
            </Then>
            <Else>
              <Col></Col>
            </Else>
          </If>
          <If condition={displayedItems > PAGE_SIZE_DISPLAYED_ITEMS}>
            <Then>
              <Col>
                <div className="see-details-label text-beplMagenta clickable"
                  onClick={handleShowLess}>
                  {SHOW_LESS} <IoMdArrowDropup />
                </div>
              </Col>
            </Then>
            <Else>
              <Col></Col>
            </Else>
          </If>
        </Row>
      </Col>
    </Row>
  );
}

export default RebateChecksTopChart;
