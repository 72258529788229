import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { setMenuExpanded } from '../../reducers/UIReducer/UIActions';
import TopBar from '../TopBar/TopBar';

const Header = () => {
  const dispatch = useDispatch();
  const menuExpanded = useSelector(({ui}) => ui.menuExpanded);
  const toggle = () => {
    dispatch(setMenuExpanded(menuExpanded ? false : true));
  }

  return (
    <Container style={{zIndex: '1120'}} fluid className={`${menuExpanded ? 'expanded-side-bar' : 'side-bar'} bg-beplBlue m-0 p-0 position-fixed left-0`}>
      <TopBar toggle={toggle}/>
    </Container>
  );
};

export default Header;