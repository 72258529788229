import React from "react";
import { Alert } from "reactstrap";
import useAnnouncements from "./useAnnouncements";

const Announcements = () => {
  const { announcements, clearAnnouncement } = useAnnouncements();
  
  const alerts = announcements.map((a) => {
    return (
      <Alert key={`announcement-${a.id}`} color="warning" toggle={() => clearAnnouncement(a.id)} className="w-100 d-flex justify-content-center primary">{a.text}</Alert>
    );
  });

  return (<>{alerts}</>);
}

export default Announcements;