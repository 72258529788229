
import React, { useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showModal } from '../../../../reducers/ModalsReducer/ModalsActions';
import { setUserRolData, clearSearchInviteLocation, clearSearchInvitedLocation, setSearchInviteLocation, setSearchInvitedLocation, setLocationChecked, setLocationCheckedInit, setLocationCheckedAll, setLocationUncheckedAll, editUsersRole, setInviteAll, toggleInviteAll, setMerchantsAll } from '../../../../reducers/UserReducer/UserActions';
import AlertModal from '../../../../sharedComponents/AlertModal/AlertModal';
import Joi from '@hapi/joi';
import useForm from '../../../../sharedComponents/useForm/useForm';
import InviteUserModal from '../Modals/InviteUserModal';
import UsersRolesRow from '../UsersRoles/UsersRolesRow';
import userApi from '../../../../api/userApi';
import merchantApi from '../../../../api/merchantApi';
import { spinner } from "../../../../reducers/UIReducer/UIActions";
import * as XLSX from 'xlsx'

const initialState = {
  contactName: '',
  email: '',
  rol: { label: 'Read Only', value: 1 }
};

const reducer = (state, action) => {
  return {
    ...state,
    [action.field] : action.value
  };
};

const useApi = () => {
  const dispatch = useDispatch();
  const userId = useSelector(({user}) => user.id);
  const userRol = useSelector(({user}) => user.userRol);
  const isInternalAdmin = useSelector(({user}) => user.isInternalAdmin);
  const checkedLocations = useSelector(({user}) => user.locationChecked);
  const locationM = useSelector(({user}) => user.merchantsAll.map(m => { return { id: m.id, label: m.merchant, checked:false }}));
  const checkedLocationM = locationM.filter(o => checkedLocations.indexOf(o.id) > -1);
  const [error, setError] = useState(false);

  const getUsers = () => {
    userApi.userInvite.get().then(({data}) => {
      dispatch(setUserRolData(data));
    }).catch(err => {
      console.error(err);
    });
  }

  const getAllMerchants = () => {
    dispatch(spinner.add());
    merchantApi.getAll().then(({ data }) => {
      dispatch(spinner.subtract());
      dispatch(setMerchantsAll(data));
    }).catch(err => {
      dispatch(spinner.subtract());
      console.error(err);
    });
  }

  const userDelete = (user) => {
    const currentUser = JSON.parse(localStorage.getItem('userData'));
    const title= 'Delete User';
    if(currentUser.email === user.email) {
      dispatch(showModal(AlertModal, {title, message: `You can't delete your own user`, buttonText:`GOT IT`}));
    } else {
      const modalProps = {
        title,
        message: 'Do you want to delete this account?',
        showTwoButtons: true,
        buttonLeftText: 'Cancel',
        buttonRightText: 'Delete',
        buttonLeftColor: 'beplMagenta',
        buttonLeftOutline: true,
        buttonRightClick: () => deleteUser(user.id),
        user
      }
      dispatch(showModal(AlertModal, modalProps));
    }
  }

  const deleteUser = (id) => {
    userApi.userInvite.delete(id).then(({data}) => {
      getUsers();
    }).catch(err => {
      console.error(err);
    });
  }

  const userInvite = (user, isMobile) => {
    const props = isMobile ? {
      isEdit: user ? true : false, 
      title: user ? 'Edit User' : 'Invite User',
      user,
      size:'sm',
      locations: locationM,
      getUsers  
    } : {
      isEdit: user ? true : false, 
      title: user ? 'Edit User' : 'Invite User',
      user,
      locations: locationM,
      checkedLocations: checkedLocationM
    } ;
    if(props.isEdit) {
      dispatch(spinner.add());
      merchantApi.available.get(user.id).then(({data}) => {
        dispatch(spinner.subtract());
        const locations = data.locations;
        const inviteAll = data.inviteAll;

        if(locations && locations.length > 0) {
          const chek = locations.map(data => data.id)
          dispatch(setLocationCheckedInit(chek));
        }

        dispatch(setInviteAll(inviteAll));

        if(isMobile) {
          dispatch(showModal(InviteUserModal, props));
        } else {
          dispatch(editUsersRole(props));
        }
      }).catch(err => {
        dispatch(spinner.subtract());
        console.error(err);
      });
    } else {
      dispatch(setLocationCheckedAll([]));
      if(isMobile) {
        dispatch(showModal(InviteUserModal, props));
      } else {
        dispatch(editUsersRole(props))
      }

    }
  }

  const uploadMerchantsToUsersList = async (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();

    function assignUserIdsToXLRows(list) {
      let assignedList = [];
      list = list.forEach(xlrow => {
        let user = userRol.find(rol => rol.email === xlrow.Email || rol.email === xlrow.email);
        if(user) {
          xlrow.id = user.id;
          assignedList.push(xlrow);
        }
      })
      return assignedList;
    }

    function seperateByUser(list) {
      let byUserObj = {};
      list.forEach(xlrow => {
        if(byUserObj[xlrow.id]) {
          let location = xlrow.platformclientid;
          byUserObj[xlrow.id].locations.push(location);
        } else {
          byUserObj[xlrow.id] = {
            email: xlrow.Email || xlrow.email,
            locations: [xlrow.platformclientid]
          }
        }
      })
      return byUserObj;
    }

    function checkForFields(list) {
      let error = false;
      list.forEach(row => {
        if(!row.platformclientid && (!row.email || row.Email)) {
          error = true
        }
      })
      return error;
    }


    reader.onload = function (e) {
      let data = e.target.result;
      let excel = XLSX.read(data, {
        type: 'binary'
      });

      return excel.SheetNames.forEach(async(sheetName) => {
        let XL_row_object = XLSX.utils.sheet_to_row_object_array(excel.Sheets[sheetName]);
        let error = checkForFields(XL_row_object);
        if(error) {
          return setError('File is missing fields');
        }
        let withIds = assignUserIdsToXLRows(XL_row_object);
        let seperatedByUser = seperateByUser(withIds);
        let promises = [];
        dispatch(spinner.add());
        for(let user in seperatedByUser) {
          promises.push(userApi.merchantBatch.patch(user, seperatedByUser[user]));
        }
        try {
          await Promise.all(promises);
          dispatch(spinner.subtract());
        } catch (err) {
          setError('Could not upload merchants list.')
          dispatch(spinner.subtract());
        } 
      })
    }

    reader.readAsBinaryString(file);
  }


  const currentUser = userRol.find((u) => u.id === userId);
  const currentUserRole = currentUser?.title;

  const userMap = userRol.map((list, i) => (
    <UsersRolesRow 
      key={`user-${i}`} 
      data={list} 
      userInvite={userInvite} 
      userDelete={userDelete} 
      currentUserRole={currentUserRole} 
      isInternalAdmin={isInternalAdmin}
    />
  ));

  return { userInvite, userDelete, userMap, getUsers, getAllMerchants, uploadMerchantsToUsersList, error, setError  }
}

const useUser = (props) => {
  const locations = props.locations;
  const getUsers = props.getUsers;
  const dispatch = useDispatch();
  const [checkAll, setCheckAll] = useState(false);
  const [uncheckAll, setUncheckAll] = useState(false);
  const [checkIntel, setCheckIntel] = useState(props.user.displayIntel);
  const [checkPaymentInfo, setCheckPaymentInfo] = useState(props.user.displayPaymentInfo);
  const searchInviteLocation = useSelector(({user}) => user.searchInviteLocation);
  const searchInvitedLocation = useSelector(({user}) => user.searchInvitedLocation);
  const locationCheckedInit = useSelector(({user}) => user.locationCheckedInit);
  const inviteAll = useSelector(({ user }) => user.inviteAll);

  const locationChecked = useSelector(({user}) => user.locationChecked);

  const onChangeInviteSearch = (e) => {
    dispatch(setSearchInviteLocation(e.target.value));
  };
  
  const onChangeInvitedSearch = (e) => {
    dispatch(setSearchInvitedLocation(e.target.value));
  };

  const clearInviteSearchValue = () => {
    dispatch(clearSearchInviteLocation());
  };
  const clearInvitedSearchValue = () => {
    dispatch(clearSearchInvitedLocation());
  };

  const checkLocation = (oLocation) => {
    dispatch(setLocationChecked(oLocation.id));
  };

  useEffect(() => {
   if(locationChecked.length === locations.length) {
    setCheckAll(true);
   } else {
    setCheckAll(false);
   }
  //eslint-disable-next-line
  }, [locationChecked])

  const checkLocationAll = (checkAll) => {
    setCheckAll(!checkAll)
    if(!checkAll) {
      const loc = locations.map(loc => {
        return loc.id
      });
      dispatch(setLocationCheckedAll(loc));
    } else {
      dispatch(setLocationCheckedAll(locationCheckedInit));
    }
  }

  const uncheckLocationAll = (uncheckAll) => {
    setUncheckAll(!uncheckAll)
    if(!uncheckAll) {
      const loc = locations.map(loc => {
        return loc.id
      });
      dispatch(setLocationUncheckedAll(loc));
    } else {
      dispatch(setLocationUncheckedAll(locationCheckedInit));
    }
  }

  const checkInviteAll = () => {
    dispatch(toggleInviteAll());
  }

  const onCheckAllowIntel = (displayIntel) => {
    setCheckIntel(!displayIntel)
  }
  const onCheckAllowPaymentInfo = (paymentInfo) => {
    setCheckPaymentInfo(!paymentInfo)
  }

  const userSave = (user, locations) => {
    const errors = user.validate();
    if (!errors) {

      // Covers case where the user is being saved with zero locations.
      // Because a [] location list implies Invite All, we can work around this
      // by sending a one-location list with an invalid location ID.
      if (locations.length === 0 && !inviteAll) {
        locations.push('NONE');
      }

      const params = {
        contactName: user.contactName,
        email: user.email,
        title: user.rol,
        locations: locations,
        siteUrl: window.location.origin,
        displayIntel: checkIntel ? true : false,
        displayInvoice: user.displayInvoice,
        isMultiHouse: user.isMultiHouse,
        displayPaymentInfo: user.displayPaymentInfo,
      };
      if(user.isEdit) {
        userApi.userInvite.patch(user.id, params).then(({data}) => {
          getUsers();
          user.isMobile ?? user.toggle();
        }).catch(err => { 
          user.isMobile ?? user.toggle();
          let msg = `Something went wrong`;
          if (err) {
            if (err.data && err.data.message) {
              msg = err.data.message;
            }
            dispatch(showModal(AlertModal, {title: msg}));
          }
        })
      } else {
        userApi.userInvite.post(params).then(({data}) => {
          getUsers();
          user.isMobile ?? user.toggle();
        }).catch(err => { 
          user.isMobile ?? user.toggle();
          let msg = `Something went wrong`;
          if (err) {
            if (err.data && err.data.message) {
              msg = err.data.message;
            }
            dispatch(showModal(AlertModal, {title: msg}));
          }
        })
      }
    }
  }


  return { 
    userSave, 
    checkInviteAll,
    checkLocationAll,
    uncheckLocationAll,
    checkLocation, 
    checkAll,
    uncheckAll,
    onCheckAllowIntel,
    checkIntel,
    onCheckAllowPaymentInfo,
    checkPaymentInfo,
    searchInviteLocations: {
      onChange: onChangeInviteSearch,
      value: searchInviteLocation,
      clearValue: clearInviteSearchValue
    },
    searchInvitedLocations: {
      onChange: onChangeInvitedSearch,
      value: searchInvitedLocation,
      clearValue: clearInvitedSearchValue
    },
  }

}


const schema = Joi.object({
  contactName: Joi.string().min(3).required(),
  email: Joi.string().min(3).required(),
  rol: Joi.required()
});

const useUserForm = (isEdit, initValues) => {
  const { form, validate, dispatch } = useForm(reducer, initialState, schema);
  useEffect(() => {
    if (isEdit) {
      dispatch({field: 'contactName', value: initValues.contactName});
      dispatch({field: 'email', value: initValues.email});
      dispatch({field: 'rol', value: { label : initValues.title || '' } });
    }
    //eslint-disable-next-line
  }, []);

  form.rol.onChange = (valueSelected) => {
    dispatch({field: 'rol', value: valueSelected || ''})
  }
 
  return {
    ...form,
    validate
  }
}

export default {
  useApi,
  useUserForm,
  useUser
} 
