import React, { useState, createRef, useEffect } from 'react';
import { Form, FormGroup, Label, Input, FormFeedback, Button, FormText, Row, Col, InputGroup } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import authApi from '../../api/authApi';
import { useDispatch } from 'react-redux';
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import AlertModal from '../../sharedComponents/AlertModal/AlertModal';
import { spinner } from '../../reducers/UIReducer/UIActions';
import useChangePasswordTokenForm from './useChangePasswordTokenForm';

const ChangePasswordTokenForm = ({ token }) => {
  const {
    validate, password, passwordRepeat
  } = useChangePasswordTokenForm();

  const history = useHistory();
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState('');
  const newPasswordInput = createRef();
  const confirmPasswordInput = createRef();

  const onSubmit = (e) => {
    e.preventDefault();
    setApiError('');
    const errors = validate();
    if (!errors) {
      const params = {
        password: password.value,
        newPassword: passwordRepeat.value,
      };
      dispatch(spinner.add());
      authApi.resetPassword(token).post(params).then(({ data }) => {
        dispatch(spinner.subtract());
        const modalProps = {
          title: 'Your password has been changed successfully.',
          buttonClick: () => history.push('/login')
        };
        dispatch(showModal(AlertModal, modalProps));
      }).catch(err => {
        dispatch(spinner.subtract());
        let msg = 'Something went wrong';
        if (err.data) {
          if (err.data.message === 'token expired') {
            msg = 'Oops! Looks like your password reset link has expired. Click the button below to request a new link.';
            dispatch(showModal(AlertModal, {
              title: msg,
              buttonClick: () => history.push('/forgot-password'),
              buttonText: 'Forgot Password'
            }));
          }
          else { // an unknown error
            dispatch(showModal(AlertModal, { title: msg }));
            setApiError(msg);
          }
        }
      });
    }
  };

  const [passwordVisibilityState, setState] = useState({
    toggledPassword: '',
    newPasswordToggle: 'Show',
    confirmPasswordToggle: 'Show',
    newPasswordInputType: 'password',
    confirmPasswordInputType: 'password'
  });
  useEffect(()=>{
  if (passwordVisibilityState.toggledPassword === 'newPassword' && newPasswordInput.current!==null) {
      let position = newPasswordInput.current.selectionStart;
      newPasswordInput.current.focus();
      newPasswordInput.current.selectionStart = position;
      newPasswordInput.current.selectionEnd = position;
    } else if(passwordVisibilityState.toggledPassword === 'confirmPassword' && confirmPasswordInput.current!==null) {
      let position = confirmPasswordInput.current.selectionStart;
      confirmPasswordInput.current.focus();
      confirmPasswordInput.current.selectionStart = position;
      confirmPasswordInput.current.selectionEnd = position;
    }
  // eslint-disable-next-line
  }, [passwordVisibilityState])


  const togglePassword = (type) => {
    let stateClone = JSON.parse(JSON.stringify(passwordVisibilityState));
    stateClone[type + 'InputType'] = stateClone[type + 'InputType'] === 'password' ? 'text' : 'password';
    stateClone[type + 'Toggle'] = stateClone[type + 'Toggle'] === 'Show' ? 'Hide' : 'Show';
    stateClone.toggledPassword = type;
    setState(stateClone);
  }

  const goToLogin = () => {
    history.push('/login');
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup className="position-relative">
        <Label className='label-text-header' for='password'>Create Password *</Label>
        <InputGroup>
          <Input innerRef={newPasswordInput} type={passwordVisibilityState.newPasswordInputType} {...password} placeholder='Password' />
          <div onClick={() => togglePassword('newPassword')} className="font-weight-bold font-sm text-black-50 clickable position-absolute-top-end mt-.55">
            {passwordVisibilityState.newPasswordToggle}
          </div>
          <FormFeedback>{password.message}</FormFeedback>
        </InputGroup>
      </FormGroup>
      <FormGroup className="position-relative">
        <Label className='label-text-header' for='passwordRepeat'>Confirm Password *</Label>
        <InputGroup>
          <Input innerRef={confirmPasswordInput} type={passwordVisibilityState.confirmPasswordInputType} {...passwordRepeat} placeholder='Repeat Password' />
          <div onClick={() => togglePassword('confirmPassword')} className="font-weight-bold font-sm text-black-50 clickable position-absolute-top-end mt-.55">
            {passwordVisibilityState.confirmPasswordToggle}
          </div>
          <FormFeedback>{passwordRepeat.message}</FormFeedback>
        </InputGroup>
      </FormGroup>
      <Button type='submit' block color='beplMagenta' className='py-2'>CHANGE PASSWORD</Button>
      <FormGroup>
        <FormText color='red'>{apiError}</FormText>
      </FormGroup>
      <Row className='mb-5'>
        <Col onClick={goToLogin} className='text-beplMagenta text-center clickable'>Already have an account? Login</Col>
      </Row>
    </Form>
  );
};

export default ChangePasswordTokenForm;