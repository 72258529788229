import { useEffect, useState } from "react";
import announcementApi from "../../../../api/announcementApi";

const useAnnouncements = () => {
  const [announcements, setAnnouncements] = useState([]);

  const clearAnnouncement = (id) => {
    setAnnouncements(announcements.filter((a) => a.id !== id));
  }

  useEffect(() => {
    announcementApi.get().then((res) => {
      setAnnouncements(res.data);
    }).catch((error) => {
      console.log(error);
    });
    //eslint-disable-next-line
  }, []);

  return {
    announcements,
    clearAnnouncement
  };
}

export default useAnnouncements;