import React from 'react';
import { Card } from 'reactstrap';
import { CardBody } from 'reactstrap';


const ScrollableList = ({ Row, emptyMessage, list, maxHeight, actions, keyPath}) => {

  const rows = list.map((li, ind) => {
    li.index = ind;
    return <Row key={li[keyPath[0]][keyPath[1]]} actions={actions ? actions : false} listItem={li} />
  })
  return (
    <Card className='bg-white  border-left border-right' style={{ minHeight: list.length === 0 ? '300px' : 'auto', overflow: 'auto', borderTop: 'none', maxHeight, borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}>
      {list && list.length > 0 ? rows : <CardBody className="mx-0 p-0 text-center text-size-16 main-header" style={{marginTop: '70px'}}>{emptyMessage}</CardBody>}
    </Card >
  );
}

export default ScrollableList;