import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { If, Else, Then } from 'react-if';
import { IoMdArrowRoundUp, IoMdArrowRoundDown } from 'react-icons/io';
import useItemMatchesData from './useItemMatchesData';


const CompetitiveInsightItemMatchesSortHeader = () => {
  const { sortBy, sortState } = useItemMatchesData.useApi();
  const sortableColumn = (shortName, longName, colSize) => {
    return (
      <Col xs={colSize} onClick={() => sortBy(shortName)}
        className={'clickable' + (sortState.sortBy === shortName ? ' text-primary' : '')}
      >
        {longName}
        <If condition={sortState.sortBy === shortName && !sortState.sortDesc}>
          <Then>
            <IoMdArrowRoundUp className='ml-2' />
          </Then>
          <Else>
            <IoMdArrowRoundDown className='ml-2' />
          </Else>
        </If>
      </Col>
    );
  }


  return (
    <CardBody className='py-2 bg-white border-top border-left border-right position-sticky' style={{ top: '0px', zIndex: 50 }}>
      <Row className='text-muted text-size-10 line-height-11 text-uppercase'>
        {sortableColumn('min', 'Min', 1)}
        {sortableColumn('description', 'Description', 2)}
        {sortableColumn('manufacturerName', 'manufacturer', 2)}
        {sortableColumn('pack', 'Pack', 1)}
        {sortableColumn('inCatalog', 'In Catalog', 1)}
        {sortableColumn('rebateType', 'Rebate Type', 1)}
        {sortableColumn('rebateValue', 'Rebate', 1)}
        {sortableColumn('deviationValue', 'Deviation', 1)}
        {sortableColumn('uom', 'UOM', 1)}
        <Col xs={1}>Feedback</Col>
      </Row>
    </CardBody>
  );
}

export default CompetitiveInsightItemMatchesSortHeader;