import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { showModal } from "../../../../../reducers/ModalsReducer/ModalsActions"
import { momentLocalizer } from 'react-big-calendar'
import OrderHistoryModal from "../Modals/OrderHistoryModal"
import moment from "moment"
import { useHistory, useParams } from "react-router-dom"
import { setOrderHistoryCalendarDate, captureOrderHistoryMonth, setCompetitiveOrderHistoryPurchases, setLoadingOrderHistory } from "../../../../../reducers/IntelReducer/IntelActions"
import stringSanitizer from 'string-sanitizer';
import logger from "../../../../../sharedComponents/Logger/LogglyLogger"
import { cancelIntelApi, intelApi } from "../../../../../api/intelApi"

const useProcessing = () => {
  const intel = useSelector(({ intel }) => intel.competitiveOrderHistoryPurchases);
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const { merchantId, merchant } = useParams();
  const history = useHistory();
  const date = useSelector(({ intel }) => intel.orderHistoryCalendarDate);
  const loading = useSelector(({ intel }) => intel.loadingOrderHistory);
  const [error, setError] = useState(false)

  const capturedOrderHistoryMonths = useSelector(({ intel }) => intel.capturedOrderHistoryMonths);

  // For calendar navigation
  const setDate = (navigationDate) => {
    cancelIntelApi()
    let currentMonth = new Date().getMonth() + 1;
    let navigatedMonth = navigationDate.getMonth() + 1;
    if (navigatedMonth <= currentMonth) {
      if (!capturedOrderHistoryMonths[merchantId] || !capturedOrderHistoryMonths[merchantId].includes(navigationDate.getMonth() + 1)) {
        let daysInMonth = getDaysInMonth(navigationDate);
        if (navigationDate > date) {
          let fromDate = new Date(navigationDate.getTime());
          navigationDate.setDate(daysInMonth);
          fromDate.setDate(1);
        } else {
          let toDate = new Date(navigationDate.getTime());
          navigationDate.setDate(1);
          toDate.setDate(daysInMonth);
        }
      }
      dispatch(setOrderHistoryCalendarDate(navigationDate));
    }
  }

  function getDaysInMonth(date) {
    return new Date(date.getFullYear(),
      date.getMonth() + 1,
      0).getDate();
  }
  // For date range picker



  const showOrderHistoryModal = (date, ordersOnDate) => {
    const filterOrdersByDate = (date, orders) => {
      let filtered = orders.filter(order => {
        return order.start.toString() === date.toString()
      });
      return filtered;
    }
    let orders = filterOrdersByDate(date, ordersOnDate);
    dispatch(showModal(OrderHistoryModal, { orders, date }));
  }

  const showOrderedItems = (event) => {
    let merchant = stringSanitizer.sanitize.keepSpace(event.resource.merchant);
    history.push('/competitiveinsights/ordertocompetitor/' +
      merchant.replace('/', '-') + '/' + event.resource.merchantId + '/' + event.resource.distributorId + '/' + event.resource.nonFormattedDate);
  }
  const events = [];

  intel.forEach(merchant => {
    if (merchant.id === merchantId) {
      let iteratedDates = [];
      merchant.data.forEach(dist => {
        if (dist.competitor) {
          dist.items.forEach(item => {
            let existingDate = iteratedDates.indexOf(item.invoice_date + dist.distributorPlatformId);
            let averagePrice = (item.startGuidanceRange + item.endGuidanceRange) / 2;
            let volume = averagePrice * item.quantity;
            if (existingDate === -1) {
              let dateSplitForAccurateDay = item.invoice_date.slice(0, 10).split('-');
              let eventObj = {
                title: 'Order Placed to ' + dist.distributor,
                start: new Date(dateSplitForAccurateDay[0], dateSplitForAccurateDay[1] - 1, dateSplitForAccurateDay[2]),
                end: new Date(dateSplitForAccurateDay[0], dateSplitForAccurateDay[1] - 1, dateSplitForAccurateDay[2]),
                resource: {
                  nonFormattedDate: item.invoice_date,
                  volume: volume,
                  distributor: dist.distributor,
                  distributorId: dist.distributorPlatformId,
                  merchant: merchant.merchant,
                  merchantId,
                  items: [item]
                }
              }
              iteratedDates.push(item.invoice_date + dist.distributorPlatformId);
              events.push(eventObj);
            } else {
              events[existingDate].resource.volume += volume;
              events[existingDate].resource.items.push(item);
            }
          })
        }
      })
    }
  });

  // date gets reset in useMerchantData
  const dataCall = () => {
    dispatch(setLoadingOrderHistory(true))
    let daysInMonth = getDaysInMonth(date);
    let toDate = date.setDate(daysInMonth);
    let startDate = date.setDate(1);

    const params = {
      startDate: moment(new Date(startDate)).format('YYYY-MM-DD'),
      endDate: moment(new Date(toDate)).format('YYYY-MM-DD'),
      showLineItems: true,
      merchants: [{
        id: merchantId,
        merchant
      }]
    };
    const reqTs = new Date();
    intelApi.post(params).then(({ data }) => {
      dispatch(setLoadingOrderHistory('loaded'))
      dispatch(setCompetitiveOrderHistoryPurchases(data));
      const resTs = new Date();
      logger.push({
        tag: process.env.REACT_APP_LOGGLY_TAG,
        requestName: 'order-history-calendar-intel',
        time: resTs - reqTs,
        reqTimestamp: reqTs,
        resTimestamp: resTs
      });

    }).catch(err => {
      dispatch(setLoadingOrderHistory('failed'))
      setError('Failed to load current months data, try to reload page');
      console.error(err);
    });
  }

  useEffect(() => {
    let callAgain = false;
    let dateMonth = date.getMonth() + 1;
    let capturedMonth;

    if (capturedOrderHistoryMonths[merchantId]) {
      for (let i = 0; i < capturedOrderHistoryMonths[merchantId].length; i++) {
        if (!capturedOrderHistoryMonths[merchantId].includes(dateMonth)) {
          capturedMonth = dateMonth;
          callAgain = true
        }
      }
    } else {
      capturedMonth = dateMonth
      callAgain = true
    }

    if (capturedMonth && callAgain) {
      dataCall()
      dispatch(captureOrderHistoryMonth(capturedMonth, merchantId));
    }

    // eslint-disable-next-line
  }, [date])

  return {
    events,
    localizer,
    showOrderedItems,
    showOrderHistoryModal,
    date,
    setDate,
    error,
    setError,
    loading,
  }
}

export default {
  useProcessing,
}