import React from 'react';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { CardBody, Row, Col } from 'reactstrap';
import { If, Then, Else } from 'react-if';

const IntelListRowHeader = ({ sortState, sortBy }) => {
  return (
    <CardBody style={{ top: '0px', zIndex: 50 }} className='py-2 bg-white position-sticky'>
      <Row xs={6} className='text-muted text-size-10 line-height-11 text-uppercase'>
        <Col onClick={() => sortBy('dcn')} className={'clickable' + (sortState.sortBy === 'dcn' ? ' text-primary' : '')}>
          Account Number
          <If condition={sortState.sortBy === 'dcn' && !sortState.sortDesc}>
            <Then>
              <IoMdArrowRoundUp className='ml-2' />
            </Then>
            <Else>
              <IoMdArrowRoundDown className='ml-2' />
            </Else>
          </If>
        </Col>
        <Col onClick={() => sortBy('merchant')} className={'clickable' + (sortState.sortBy === 'merchant' ? ' text-primary' : '')}>
          Customer Name
          <If condition={sortState.sortBy === 'merchant' && !sortState.sortDesc}>
            <Then>
              <IoMdArrowRoundUp className='ml-2' />
            </Then>
            <Else>
              <IoMdArrowRoundDown className='ml-2' />
            </Else>
          </If>
        </Col>
        <Col onClick={() => sortBy('totalVolume')} className={'clickable' + (sortState.sortBy === 'totalVolume' ? ' text-primary' : '')}>
          Total Volume
          <If condition={sortState.sortBy === 'totalVolume' && !sortState.sortDesc}>
            <Then>
              <IoMdArrowRoundUp className='ml-2' />
            </Then>
            <Else>
              <IoMdArrowRoundDown className='ml-2' />
            </Else>
          </If>
        </Col>
        <Col onClick={() => sortBy('competitorVolume')} className={'clickable' + (sortState.sortBy === 'competitorVolume' ? ' text-primary' : '')}>
          Competitor Volume
          <If condition={sortState.sortBy === 'competitorVolume' && !sortState.sortDesc}>
            <Then>
              <IoMdArrowRoundUp className='ml-2' />
            </Then>
            <Else>
              <IoMdArrowRoundDown className='ml-2' />
            </Else>
          </If>
        </Col>
        <Col onClick={() => sortBy('competitorVolumePerc')} className={'clickable' + (sortState.sortBy === 'competitorVolumePerc' ? ' text-primary' : '')}>
          % Competitor Volume
          <If condition={sortState.sortBy === 'competitorVolumePerc' && !sortState.sortDesc}>
            <Then>
              <IoMdArrowRoundUp className='ml-2' />
            </Then>
            <Else>
              <IoMdArrowRoundDown className='ml-2' />
            </Else>
          </If>
        </Col>
      </Row>
    </CardBody>
  );
};

export default IntelListRowHeader;