import useForm from "../../sharedComponents/useForm/useForm"
import Joi from "@hapi/joi";

const initialState = {
  password: '',
  passwordRepeat: ''
};

const reducer = (state, action) => {
  return {
    ...state,
    [action.field]: action.value
  };
};

const schema = Joi.object({
  password: Joi.string().min(6).max(20).required().label('Create Password'),
  passwordRepeat:  Joi.string().valid(Joi.ref('password')).messages({'any.only': `Passwords don't match`}),
});

const useChangePasswordTokenForm = () => {
  let { form, validate } = useForm(reducer, initialState, schema);

  return {
    ...form,
    validate
  };
};

export default useChangePasswordTokenForm;