import React from 'react';
import { Row, Col, CardBody,
} from 'reactstrap';
import { If, Else, Then } from 'react-if';
import { IoMdArrowRoundUp, IoMdArrowRoundDown } from 'react-icons/io';


const SortableTableHeader = ({ sortState, sortBy, columns, colorClass }) => {
  const sortableColumn = (shortName, longName, colSize) => {
    return (
      <Col key={shortName} xs={colSize} onClick={() => sortBy(shortName)}
        className={`clickable d-flex align-items-center` + (sortState.sortBy === shortName ? ' text-primary' : '')}
      >
        {longName}
        <If condition={sortState.sortBy === shortName && !sortState.sortDesc}>
          <Then>
            <IoMdArrowRoundUp className='ml-2' />
          </Then>
          <Else>
            <IoMdArrowRoundDown className='ml-2' />
          </Else>
        </If>
      </Col>
    );
  }

  const columnElements = columns.map(c => {
    return sortableColumn(c.value, c.label, c.size)
  })

  return (
    <CardBody className={`py-3 border-top border-left border-right ${colorClass ? colorClass : ''}`}>
      <Row className='text-muted text-size-12 line-height-11 text-uppercase'>
        {columnElements}
      </Row>
    </CardBody>
  );
}

export default SortableTableHeader;