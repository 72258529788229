import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CompetitiveInsightHeader from './Subcomponents/CompetitiveInsightHeader';
import CompetitiveInsightResults from './Subcomponents/CompetitiveInsightResults';
import LoadingMerchantsModal from '../../../sharedComponents/LoadingMerchantsModal.js/LoadingMerchantsModal';
import useDashboard from '../Dashboard/useDashboard';
import useDistributor from '../../../sharedComponents/useDistributor/useDistributor';
import { intelVisible } from '../../../reducers/IntelReducer/IntelActions';
import { Container } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const CompetitiveInsight = () => {
  const dispatch = useDispatch();

  // Allow Intel API to begin loading data when this component mounts
  useEffect(() => {
    dispatch(intelVisible(true));

    // Clean up; runs on unmount
    return () => {
      dispatch(intelVisible(false));
    };    
  }, [dispatch]);
  
  useEffect(()=> {
    window.scrollTo({
      top: 0,
    });
  },[]);
  // For the date pickers
  const {
    fromDate, toDate, setDateRange, onApplySearch
  } = useDashboard.useDates();

  // For the distributor dropdown
  const {
    selectActiveDistributor, 
    activeDistributor,
    distributorsList
  } = useDistributor();

  // Drilldown state. These are used when the user navigates here from 
  // the top category/item links in the dashboard.
  const categoryDrilldown = useSelector(({dashboard}) => dashboard.categoryDrilldown);
  const itemDrilldown = useSelector(({dashboard}) => dashboard.itemDrilldown);
  const {pathname} = useLocation();

  return (
    <Container fluid className={`bg-grayBg flex-grow-1 ${isMobile ? 'pl-3' : 'pl-4'}`}>
      <CompetitiveInsightHeader 
        fromDate={fromDate} 
        toDate={toDate} 
        setDateRange={setDateRange}
        onApplySearch={onApplySearch} 
        selectActiveDistributor={selectActiveDistributor}
        activeDistributor={activeDistributor}
        distributorsList={distributorsList}
        categoryDrilldown={categoryDrilldown}
        itemDrilldown={itemDrilldown}
      />
      <CompetitiveInsightResults 
        categoryDrilldown={categoryDrilldown} 
        itemDrilldown={itemDrilldown}
      />
      {pathname.includes('merchants') ? <LoadingMerchantsModal /> : false}
    </Container>
  );
}

export default CompetitiveInsight;