import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { IoMdClose } from 'react-icons/io';
import { Button } from "reactstrap";
import { selectStyles } from '../../../config/constants';
import Select from 'react-select';
import { Input } from 'reactstrap';
import SortableTableHeader from '../../../sharedComponents/SortableTableHeader/SortableTableHeader';
import ScrollableList from '../../../sharedComponents/ScrollableList/ScrollableList';
import TableRow from '../../../sharedComponents/TableRow/TableRow';
import { FaUserSlash } from "react-icons/fa";
import useEditUser from './useEditUser';
import { isMobile } from 'react-device-detect';
import { Spinner } from "reactstrap";
import { userRoles } from '../../../config/constants';
import { CustomInput } from 'reactstrap';
import MultiDimensionDropdown from '../../../sharedComponents/MultiDimensionDropdown/MultiDimensionDropdown';

const roles = [...userRoles];

const EditUserSlideOut = ({ user, setUser, setUsers, users }) => {

  const { Option,
    // goBackInSelect,
     visible, name, email, role, setEmail, setRole, setVisible, sortedDistributors, parentDistributors,
    selectedParentDistributor, setSelectedParentDistributor, childrenDistributors,
    selectedChildDistributor, addNewDistributor, saveUser, distRowActions, sortBy, sortState, showConfirmUserDeactivation, password, setPassword,
    confirmPassword, setConfirmPassword, loading, includeFamily, setIncludeFamily
  } = useEditUser(user, setUser, setUsers, users);


  useEffect(() => {
    if (user) {
      setVisible(true);
    } else {
      if (visible !== null) {
        setVisible(false);
      }
    }
    // eslint-disable-next-line
  }, [user]);


  return (
    <Col xs={isMobile ? 12 : 6} style={{ zIndex: 1040, boxShadow: '7px 1px 20px 0px rgba(0,0,0,0.7)', transform: 'translateX(100%)' }}
      className={`top-0 right-0 p-0 m-0 bg-white h-screen position-fixed ${visible ? 'edit-user-slide-out-visible' : (visible === null ? '' : 'edit-user-slide-out')}`}>
      <Row className='py-3 border-bottom m-0 d-flex justify-content-between position-relative'>
        <Col className='d-flex align-items-center font-weight-bold'>
          <IoMdClose className='clickable' size={25} onClick={() => setUser(false)} />
          <div className='ml-2'>Edit User:</div>
          <div className='ml-2'>{name}</div>
        </Col>
        <Col className='position-absolute w-100 d-flex justify-content-center'>
          {loading ? <Spinner /> : false}
        </Col>
        <Col xs={3} className='w-25'>
          <Button disabled={loading} onClick={saveUser} block color='beplMagenta' className='py-2 button-label'>Save</Button>
        </Col>
      </Row>

      <Row className='mt-4 px-3'>
        <Col xs={6}>
          <div className='text-size-14 pl-1 pb-1'>Email Address</div>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
          <div className='text-size-14 pt-1 text-gray'>This email addess will be used to login with</div>
        </Col>
        <Col xs={6}>
          <div className='text-size-14 pl-1 pb-1'>Select Role</div>
          <Select
            styles={selectStyles}
            value={role}
            onChange={setRole}
            options={roles} />
        </Col>
      </Row>
      <Row className='mt-4 px-3'>
        <Col xs={6}>
          <div className='text-size-14 pl-1 pb-1'>Password</div>
          <Input value={password} onChange={(e) => setPassword(e.target.value)} />
          <div className='text-size-14 pt-1 text-gray'>This is the password the user will use to login</div>

        </Col>
        <Col xs={6}>
          <div className='text-size-14 pl-1 pb-1'>Confirm Password</div>
          <Input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </Col>
      </Row>

      <Row className={`m-0 px-3 mt-4 d-flex justify-content-between ${isMobile ? 'flex-column' : ''}`}>
        <Col xs={isMobile ? 12 : 5} className='m-0 p-0 text-size-24 font-weight-bold text-left'>Distributor Houses</Col>
        <Col xs={isMobile ? 12 : 5} className='m-0 p-0 position-relative'>

          <MultiDimensionDropdown
            firstList={parentDistributors}
            secondList={childrenDistributors}
            setSelectedFromFirstList={setSelectedParentDistributor}
            addItemFromSecondList={addNewDistributor}
            selectedFromFirstList={selectedParentDistributor}
            selectedFromSecondList={selectedChildDistributor}
            withMulti={true}
            Option={Option}

          />
        </Col>
      </Row>
      <Row className='m-0 mt-2 d-flex justify-content-end px-2'>
        Include family
        <CustomInput id={`include-family`} type="checkbox" className="ml-2" value={includeFamily} onChange={() => setIncludeFamily(includeFamily ? false : true)} />
      </Row>
      <Col className='m-0 px-3 mt-2'>
        <SortableTableHeader colorClass="bg-grayNew" sortBy={sortBy} sortState={sortState} columns={[
          {
            label: 'Distributor House',
            value: 'name',
            size: 6
          },
        ]} />
        <ScrollableList maxHeight="calc(100vh - 520px)" Row={TableRow} list={sortedDistributors} keyPath={['name', 'value']} actions={distRowActions} />

      </Col>

      <Row className='px-3 py-2 bg-grayNew m-0 position-absolute w-100 border-top' style={{ bottom: 0 }}>
        <Row className='m-0'>
          <Col xs={7} className='p-0 d-flex'>
            <FaUserSlash size={40} className='text-red' />
            <Col className='p-0 text-size-12 ml-3'>
              <Col className='p-0'>Deactivate User Account</Col>
              If you deactivate this account, this person will lose access to this Distributor Portal and will be signed out of their account on all devices.
            </Col>
          </Col>
          <Col xs={5} className='p-0 d-flex justify-content-end'>
            <Button onClick={showConfirmUserDeactivation} className={`${isMobile ? 'align-self-baseline' : ''} py-2 button-label`} color={'red'}>Deactivate User</Button>
          </Col>
        </Row>
      </Row>
    </Col>
  );
};


export default EditUserSlideOut;