import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import invoiceApi from "../../../api/invoiceApi";
import { setUploadedInvoiceImages, setUploadedInvoicesData , setSortBy} from "../../../reducers/InvoiceScanningReducer/InvoiceScanningActions";
import { setDetailSortBy } from "../../../reducers/IntelReducer/IntelActions";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _arrayBufferToBase64 from "../../../sharedComponents/Helper/arrayBufferToBase64";
import numeral from "numeral";
import fileDownload from "js-file-download";
import stringify from "csv-stringify";

const useInvoice = {
  useUploadedInvoice: (invoiceId) => {
    const [error, setError] = useState();
    const [imagesLoadError, setImagesLoadError] = useState('');
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [invoiceInfo, setInvoiceInfo] = useState({});
    const [invoiceImageObjs, setInvoiceImageObjs] = useState([]);
    const invoiceImages = useSelector(({ invoices }) => invoices.uploadedInvoiceImages);
    const history = useHistory();
    const dispatch = useDispatch();

    const deleteInvoiceImage = async (invoiceId, imageId) => {
      try {
        await invoiceApi.deleteImage(null, invoiceId, imageId);
        const filteredImages = invoiceImages.filter(image => {
          return image.attachment_id !== imageId;
        })
        dispatch(setUploadedInvoiceImages(filteredImages));
        setImagesLoaded(true);
      } catch (err) {
        setError('Could Not Delete Image.');
        setImagesLoaded(true);
      }
    }
    
    const getInvoice = async (id) => {
      try {
        let response = await invoiceApi.get(null, id);
        setInvoiceInfo(response.data);
        setInvoiceImageObjs(response.data.images);
      } catch (err) {
        setError('Failed To Fetch Invoice');
      }
    }

    const getInvoiceImages = async () => {
      let promises = invoiceInfo.images.map(image => {
        return invoiceApi.getImages({ responseType: 'arraybuffer' }, null, image.attachment_id);
      });
      Promise.all(promises).then(res => {
        let images = res.map((data, ind) => {
          const imageObj = JSON.parse(JSON.stringify(invoiceImageObjs[ind]));

          const base64string = _arrayBufferToBase64(data.data);
          imageObj.src = `data:image/png;base64,${base64string}`;

          return imageObj;
        });
        dispatch(setUploadedInvoiceImages(images));
        setImagesLoaded(true);
      }).catch(err => {
        setImagesLoaded(true);
        setImagesLoadError('Failed To Load Images')
      })
    }

    useEffect(() => {
      getInvoice(invoiceId);
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (invoiceInfo.images?.length > 0) {
        setImagesLoaded(false);
        getInvoiceImages();
      }
      else {
        dispatch(setUploadedInvoiceImages([]));
        setImagesLoaded(true);
      }
      // eslint-disable-next-line
    }, [invoiceImageObjs]);

    const breadCrumbs = () => {
      if (invoiceId) {
        return (
          <div className='d-flex flex-wrap font-weight-bold align-items-center'>
            {invoiceInfo.description ?
              <>
                <div onClick={() => history.push('/invoice/scanning')} className="clickable" >
                  {'Invoices'}
                </div>
                <div className="clickable d-flex" >
                  <div className='text-sm px-1 text-beplBlue'>{` > `}</div>
                  <div className={'text-beplMagenta'}>{invoiceInfo.description}</div>
                </div>
              </> : false}
          </div>
        )
      }
    }

    return {
      breadCrumbs,
      getInvoiceImages,
      getInvoice,
      error,
      invoiceInfo,
      setInvoiceInfo,
      deleteInvoiceImage,
      imagesLoadError,
      imagesLoaded
    }
  },
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useUploadedInvoiceItems: (id) => {
    const dispatch = useDispatch();
    const isInternalAdmin = useSelector(({ user }) => user.isInternalAdmin);
    const sortState = useSelector(({ invoices }) => invoices.uploadedInvoiceItemsSort);
    const [error, setError] = useState(null);
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [invoiceInfo, setInvoiceInfo] = useState({});
    const [itemSearch, setItemSearch] = useState('');
    const history = useHistory();
  
    const sortBy = (key) => {
      let order = key === sortState.sortBy ? !sortState.sortDesc : true;
      order = (key === 'merchant' && key !== sortState.sortBy) ? !order : order;
      dispatch(setSortBy('uploadedInvoiceItemsSort', key, order));
    };
  
    const getItems = async () => {
      let response = await invoiceApi.get(null, id);
      setInvoiceInfo(response.data);
      if (response.data && response.data.contents && response.data.contents.items) {
        setInvoiceItems(response.data.contents.items);
      } else {
        setError('No Items');
      }
    }
    useEffect(() => {
      try {
        getItems()
      } catch (err) {
        setError('Failed To Fetch Invoice.');
      }
      // eslint-disable-next-line
    }, []);
  
    const breadCrumbs = () => {
      if (id) {
        return (
          <div className='d-flex flex-wrap font-weight-bold align-items-center'>
            {id && invoiceInfo.description ?
              <>
                <div onClick={() => history.push('/invoice/scanning')} className="clickable" >
                  {'Invoices'}
                </div>
                <div className="clickable d-flex" >
                  <div className='text-sm px-1 text-beplBlue'>{` > `}</div>
                  <div onClick={() => history.push('/invoice/' + id)}>{invoiceInfo.description}</div>
                </div>
                <div className="clickable d-flex" >
                  <div className='text-sm px-1 text-beplBlue'>{` > `}</div>
                  <div className={'text-beplMagenta'}>Items</div>
                </div>
              </> : false}
          </div>
        )
      }
    }

    return {
      breadCrumbs,
      sortBy,
      sortState,
      error,
      setError,
      isInternalAdmin,
      itemSearch,
      setItemSearch,
      history,
      invoiceItems,
      setInvoiceItems,
      invoiceInfo,
    }

  },
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useInvoiceScanning: () => {
    const dispatch = useDispatch();
    // const uploadFiles = useSelector(({invoices}) => invoices.invoiceUploadFiles);
    const [error, setError] = useState(null);
    const sortState = useSelector(({ intel }) => intel.detailOptions.uploadedInvoicesSort);
    const uploadedInvoicesData = useSelector(({invoices}) => invoices.uploadedInvoicesData);
    const [invoicesLoaded, setInvoicesLoaded] = useState(false);
    const [invoiceSearch, setInvoiceSearch] = useState('');
  
    const sortBy = (key) => {
      let order = key === sortState.sortBy ? !sortState.sortDesc : true;
      order = (key === 'merchant' && key !== sortState.sortBy) ? !order : order;
      dispatch(setDetailSortBy('uploadedInvoicesSort', key, order));
    };
  
    const getUploadedInvoices = async () => {
      try {
        const invoices = await invoiceApi.get();
        dispatch(setUploadedInvoicesData(invoices.data));
        setInvoicesLoaded(true);
      } catch (err) {
        setInvoicesLoaded(true);
        setError('Failed To Fetch Your Invoices.');
      }
    }
  
    useEffect(() => {
      getUploadedInvoices();
    // eslint-disable-next-line
    }, []);
  
    const deleteUploadedInvoice = async (id) => {
      try {
        await invoiceApi.delete(id);
        const filtered = uploadedInvoicesData.filter(invoice => {
          return invoice.attachment_grouping_id !== id;
        });
        dispatch(setUploadedInvoicesData(filtered));
  
      } catch (err) {
        console.log(err)
      }
    }
  
    return {
      sortBy,
      sortState,
      uploadedInvoicesData,
      deleteUploadedInvoice,
      invoiceSearch,
      setInvoiceSearch,
      error,
      invoicesLoaded
    }
  
  },

useCsvExport: (invoiceItems) => {
    const getItems = async (id) => {
      let response = await invoiceApi.get(null, id);
      if (response.data && response.data.contents && response.data.contents.items) {
        return response.data.contents.items;
      }
    }

    let prepareInvoiceItemsList = (data) => {
      let csvData = [];
      csvData.push([
        'item description', 'brand', 'packsize', 'totalPrice', 'quantity', 'itemCode', 'uom'
      ])
      data.forEach(({description, brand, pack, totalPrice, quantity, itemCode, uom}) => {
        csvData.push([
          description, brand, pack, numeral(totalPrice).format('0,0.00'), quantity, itemCode, uom
        ])
      });
      return csvData;
    }
   
    const exportCsv = (data, fileName) => {
      let preCsvArray = prepareInvoiceItemsList(data);
      if (preCsvArray.length > 0) {
        stringify(preCsvArray, (err, output) => {
          fileDownload(output, fileName);
        });
      }
    }
  
    const fetchAndExportInvoiceItemsList = async (id) => {
      const items = await getItems(id);
      exportCsv(items, 'invoiceItems.csv');
    }

    const exportInvoiceItemsList = () => {
      let data = invoiceItems;
      if(data.length > 0) {
        exportCsv(data, 'invoiceItems.csv');
      }
    }
  
    return {
      exportInvoiceItemsList,
      fetchAndExportInvoiceItemsList
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // return {
  //   useUploadedInvoice,
  //   useUploadedInvoiceItems,
  //   useInvoiceScanning,
  //   useCsvExport
  // }
}


export default useInvoice;