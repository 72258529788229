import React from 'react';
import { Row, Container } from 'reactstrap';
import useIntelLineChart from './useIntelLineChart';
import { isMobile } from 'react-device-detect';
import IntelLineChartLoadMore from './Subcomponents/IntelLineChartLoadMore';

const IntelGraph = () => {
  const { intelMap, loadMoreLineChart, showMore } = useIntelLineChart.useApi();

  return (
    <Container fluid className={isMobile ? 'p-0 mt-3' : ''}>
      <Row className="d-flex flex-wrap">
        {intelMap}
      </Row>
      { showMore && <IntelLineChartLoadMore onClick={loadMoreLineChart} /> }
    </Container>
  );
};
  
export default IntelGraph;  