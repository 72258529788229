import Axios from 'axios';

const url = '/rebate-checks';

const rebateChecksApi = {
  getCheck: (params) => {
    return Axios.post(`${url}/check`, params)
  },
  getChecks: (params) => {
    return Axios.post(`${url}/checks`, params)
  },
  getCheckPic: (params) => {
    return Axios.post(`${url}/check-pic`, params)
  },
  payNoPaySum: (params)=> {
    return Axios.post(`${url}/paynopay-summary`, params,  { responseType: 'blob' })
  },
  checkDetails: (params) => {
    return Axios.post(`${url}/check-details`, params, { responseType: 'blob' });
  },
  bulkDataExport: (params) => {
    return Axios.post(`${url}/bulk-export`, { ...params }, { responseType: 'arraybuffer' })
  },
  bulkDataPrint: (params) => {
    return Axios.post(`${url}/bulk-print`, { ...params }, { responseType: 'blob' })
  },
  exportDataNotOnPortal: (params) => {
    return Axios.post(`${url}/export-data-not-shown`, params, { responseType: 'blob' })
  },
  updateDistributorsToSalesRep: (body) => {
    return Axios.post(`${url}/update-dsr`, body)
  },
  getDistributorSalesReps: () => {
    return Axios.get(`${url}/dsr`)
  },
  getDistributorsPerSalesRep: (params) => {
    return Axios.get(`${url}/dsr/distributors`, { params })
  },
  deleteDistributorsSalesRep: (params) => {
    return Axios.delete(`${url}/dsr`, { params })
  }
 }

export default rebateChecksApi;
