import React from 'react';
import { Row, Col } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { colors } from '../../../config/constants';

const RebatesEmpty = ({reason}) => {
  return (
    <Row style={{position:'relative'}}>
      <Col xs={12} className='text-center p-5'>
        <BsSearch color={colors.beplMagenta}  size={45} />
        <div className='mt-3 main-header text-center'>{reason}</div>
      </Col>
    </Row>
  )
}

export default RebatesEmpty;
