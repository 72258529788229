import React from 'react';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { CardBody, Row, Col } from 'reactstrap';
import { If, Then, Else } from 'react-if';
import { useSelector } from 'react-redux';

const CompetitiveInsightItemSortHeader = ({ sortState, sortBy }) => {
  const merchantPurchasesTypeView = useSelector(({intel}) => intel.merchantPurchasesTypeView);
  const sortableColumn = (shortName, longName, xs) => {
    return (
      <Col onClick={() => sortBy(shortName)}
        className={'clickable' + (sortState.sortBy === shortName ? ' text-primary' : '')}
        xs={xs}
      >
        {longName}
        <If condition={sortState.sortBy === shortName && !sortState.sortDesc}>
          <Then>
            <IoMdArrowRoundUp className='ml-2' />
          </Then>
          <Else>
            <IoMdArrowRoundDown className='ml-2' />
          </Else>
        </If>
      </Col>
    );
  }

  return (
    <CardBody style={{ top: '0px', zIndex: 50 }} className='py-2 bg-white border-top border-left border-right position-sticky'>
      <Row className='text-muted text-size-10 line-height-11 text-uppercase'>
        {sortableColumn('description', 'Item Description', 2)}
        {sortableColumn('manufacturer', 'Manufacturer', merchantPurchasesTypeView.value === 'competition' ? 1 : 2)}
        {sortableColumn('packsize', 'Packsize', 1)}
        {sortableColumn('startGuidanceRange', 'Market Index', 1)}
        {sortableColumn('rebateValue', 'Rebate', 1)}
        {sortableColumn('deviationValue', 'Deviation', 1)}
        {sortableColumn('quantity', 'Quantity', 1)}
        {sortableColumn('uom', 'UOM', 1)}
        {sortableColumn('purchase', 'Volume Est.', 2)}
        {merchantPurchasesTypeView.value === 'competition' ? <Col xs={1}>SIMILAR ITEMS</Col> : false}
      </Row>
    </CardBody>
  );
};

export default CompetitiveInsightItemSortHeader;