import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import filterArrayByKey from '../../../../sharedComponents/Helper/filterArrayByKey';
import Select from 'react-select';
import useUsersRoles from './useUsersRoles';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { selectStyles } from '../../../../config/constants';
import InviteUserToLocationsList from './InviteUserToLocationsList';
import InvitedUserToLocationsList from './InvitedUserToLocationsList';
import { editUsersRole } from '../../../../reducers/UserReducer/UserActions';
import InputSearch from '../../../../sharedComponents/InputSearch/InputSearch';
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { userRoles } from '../../../../config/constants';

const InviteUsersRoles = () => {
  const props = useSelector(({ user }) => user.editUsersRoleProps);
  const { getUsers } = useUsersRoles.useApi();
  props.getUsers = getUsers;
  const { contactName, email, rol, validate } = useUsersRoles.useUserForm(props.isEdit, props.user);
  const { userSave, checkInviteAll, checkLocationAll, uncheckLocationAll, checkAll, uncheckAll, onCheckAllowIntel, onCheckAllowPaymentInfo, checkIntel, checkPaymentInfo, searchInviteLocations, searchInvitedLocations, selectRol, ...usr } = useUsersRoles.useUser(props);

  const locationChecked = useSelector(({ user }) => user.locationChecked);
  const locationList = useSelector(({ user }) => user.locationList);
  const inviteAll = useSelector(({ user }) => user.inviteAll);
  const isInternalAdmin = useSelector(({ user }) => user.isInternalAdmin);

  const dispatch = useDispatch();
  const keysSearchInviteList = [{ key: 'label', search: searchInviteLocations.value }];
  const keysSearchInvitedList = [{ key: 'label', search: searchInvitedLocations.value }];
  const checkedLocations = props.locations.filter(o => locationChecked.indexOf(o.id) > -1)
  let filteredInviteLocationList = filterArrayByKey(props.locations, keysSearchInviteList);
  let filteredInvitedLocationList = filterArrayByKey(checkedLocations, keysSearchInvitedList);

  const userParams = {
    id: props.user.id,
    isEdit: props.isEdit,
    contactName: contactName.value,
    email: email.value,
    rol: rol.value.label,
    checkIntel,
    displayInvoice: !!props.user.displayInvoice,
    displayPaymentInfo: !!checkPaymentInfo,
    isMultiHouse: !!props.user.isMultiHouse,
    validate,
    isMobile
  }

  const options = [...userRoles];

  // Only BEPL distributor admins should see this option.
  if (isInternalAdmin) {
    options.push({ value: 7, label: 'Admin' });
  }

  const [selectAllTooltip, showSelectAllTooltip] = useState('d-none');
  const [inviteAllTooltip, showInviteAllTooltip] = useState('d-none');

  const userSaveOnClick = () => {
    userSave(userParams, inviteAll ? [] : locationChecked);
    dispatch(editUsersRole(false));
  }

  return (
    <Container fluid className={'px-4 h-100'}>
      <Row>
        <Col xs={4}>
          <Form className={'px-4'}>
            <FormGroup >
              <Label className='label-text-header text-size-13 line-height-14'>Name</Label>
              <Input {...contactName} />
            </FormGroup>
            <FormGroup>
              <Label className='label-text-header text-size-13 line-height-14'>Email</Label>
              <Input  {...email} disabled={props.isEdit} />
            </FormGroup>
            <FormGroup>
              <Label className='label-text-header text-size-13 line-height-14'>Role</Label>
              <Select options={options} onChange={rol.onChange} value={rol.value || ''} styles={selectStyles} />
            </FormGroup>
            <FormGroup>
              <Row className='text-size-14 line-height-16'>
                <Col xs={12} className='pt-3 d-flex align-items-center'>
                  <CustomInput id={'allow-intel'} label={'Allow User to See Intel'} type='checkbox' checked={checkIntel} onChange={() => onCheckAllowIntel(checkIntel)} />
                </Col>
              </Row>
              <Row className='text-size-14 line-height-16'>
                <Col xs={12} className='pt-3 d-flex align-items-center'>
                  <CustomInput id={'allow-payment-info'} label={'Allow User to See Payment Information'} type='checkbox' checked={checkPaymentInfo} onChange={() => onCheckAllowPaymentInfo(checkPaymentInfo)} />
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </Col>
        <Col xs={8}>
          <Row>
            <Col xs={6}>
              <Row className='text-size-14 line-height-16'>
                <Col xs={12}>
                  Available Locations
                </Col>

                <Col xs={6} className='pt-3 d-flex'>
                  <Button className='button-label' style={{ width: '120px' }} size='sm' color='beplMagenta' onClick={() => checkLocationAll(checkAll, locationList)}>Select All</Button>
                  <div style={{ marginTop: '7px' }}>
                    <AiOutlineQuestionCircle onMouseEnter={() => showSelectAllTooltip('d-block')} onMouseLeave={() => showSelectAllTooltip('d-none')} className="ml-2" color="gray" size="15px" />
                    <div style={{ zIndex: '50', minWidth: '250px' }} className={`${selectAllTooltip} border rounded mt-1 ml-3 bg-white position-absolute p-2 pt-3`}>
                      This selects all locations only for this edit/invitation. User will not be able to view new locations added to this distributor
                      after this edit/invitation.
                    </div>
                  </div>
                </Col>

                <Col xs={6} className='pt-3 d-flex align-items-center'>
                  <CustomInput id={'invite-all'} label={'Invite To All'} type='checkbox' checked={inviteAll} onChange={() => checkInviteAll()} />
                  <div style={{ marginTop: '-2px' }}>
                    <AiOutlineQuestionCircle onMouseEnter={() => showInviteAllTooltip('d-block')} onMouseLeave={() => showInviteAllTooltip('d-none')}
                      className="ml-2" color="gray" size="15px" />
                    <div style={{ zIndex: '50', minWidth: '250px' }} className={`${inviteAllTooltip} border rounded mt-1 ml-3 bg-white position-absolute p-2`}>
                      If "Invite to All Locations" is selected this account will automatically be invited to view any new locations added to this distributor
                      after this edit/invitation.
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className='pt-3'>
                <Col>
                  <InputSearch {...searchInviteLocations} />
                </Col>
              </Row>
              <FormGroup className='px-3 pt-0 mt-2'>
                <Row>
                  <InviteUserToLocationsList options={filteredInviteLocationList} {...usr} />
                </Row>
              </FormGroup>
            </Col>
            <Col xs={6}>
              <Row className='text-size-14 line-height-16 d-flex'>
                <Col xs={12}>
                  Selected Locations
                </Col>

              </Row>
              <Row className='text-size-14 line-height-16 pt-3 pl-3'>
                <Button className='button-label' style={{ width: '120px' }} size='sm' color='beplMagenta' onClick={() => uncheckLocationAll(uncheckAll, locationList)}>Unselect All</Button>
              </Row>

              <Row className='pt-3'>
                <Col>
                  <InputSearch {...searchInvitedLocations} />
                </Col>
              </Row>
              <FormGroup className='px-3 pt-0 mt-2'>
                <Row>
                  <InvitedUserToLocationsList options={filteredInvitedLocationList} {...usr} />
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>

      {!props.isEdit ?
        <Row className="d-flex justify-content-end px-4 mb-4 mt-2">
          <Button className='p-2 button-label mr-2' size='sm' color='beplMagenta' onClick={() => userSaveOnClick(userParams, locationChecked)}>Invite</Button>
          <Button className='py-2 button-label' size='sm' color='beplMagenta' onClick={() => dispatch(editUsersRole(false))}>Cancel</Button>
        </Row> :
        <Row className="d-flex justify-content-end p-2 pb-3">
          <Button className='py-2 button-label mr-2' size='sm' color='beplMagenta' onClick={() => userSaveOnClick(userParams, locationChecked)}>Save</Button>
          <Button className='py-2 button-label' size='sm' color='beplMagenta' onClick={() => dispatch(editUsersRole(false))}>Cancel</Button>
        </Row>}

    </Container>

  )
}

export default InviteUsersRoles;
