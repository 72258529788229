import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { spinner } from '../../../reducers/UIReducer/UIActions';
import { setMerchants, setDateRange as setIntelDateRange, clearMerchants, setOrderHistoryCalendarDate,
  clearMerchantsData, setSearch, clearSearch, setMerchantData, applySearch, setListSortByDesc, 
  setDetailSortBy, clearCompetitiveOrderHistoryPurchases, clearOrderHistoryMonths } from '../../../reducers/IntelReducer/IntelActions';
import merchantApi from '../../../api/merchantApi';
import { showModal } from '../../../reducers/ModalsReducer/ModalsActions';
import AlertModal from '../../../sharedComponents/AlertModal/AlertModal';
import SortByModal from '../Intel/Modals/SortByModal';
import { cancelIntelApi, intelApi }from '../../../api/intelApi';
import userApi from '../../../api/userApi';
import { resetImpersonate, setUserData } from '../../../reducers/UserReducer/UserActions';
import logger from '../../../sharedComponents/Logger/LogglyLogger';

const useTop = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const fromDate = useSelector(({intel}) => intel.fromDate);
  const toDate = useSelector(({intel}) => intel.toDate);
  const search = useSelector(({intel}) => intel.search);
  const sortState = useSelector(({intel}) => intel.listOptions);
  const sortStateDetail = useSelector(({intel}) => intel.detailOptions);
  const merchants = useSelector(({intel}) => intel.merchants);
  const merchantsLoaded = useSelector(({intel}) => intel.merchantsLoaded);
  const activeDistributor = useSelector(({user}) => user.activeDistributor);
  const activeDistributorApply = useSelector(({user}) => user.activeDistributorApply);
  const allLoaded = useSelector(({intel}) => intel.allLoaded);
  const [viewType, setViewType] = useState({ label: 'View: List', value: 'list' });
  const [graphOrChartSortType, setGraphOrChartSortType] = useState({ value: 'totalVolume', label: 'Sort By: Total Volume' })

  const onChange = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const clearValue = () => {
    dispatch(clearSearch());
  };
  
  const goToGraph = () => {
    if (pathname !== '/intel/graph') {
      history.push('/intel/graph');
    }
  };

  const goToLineChart = () => {
    if (pathname !== '/intel/linechart') {
      history.push('/intel/linechart');
    }
  };

  const goToList = () => {
    if (pathname !== '/intel/list') {
      history.push('/intel/list');
    }
  };

  const goToCompetitivePurchases = () => {
    const id = pathname.split('/')[3];
    history.push(`/intel/detail/${id}/competitive`);
  };

  const goToDistributorPurchases = () => {
    const id = pathname.split('/')[3];
    history.push(`/intel/detail/${id}/distributor`);
  };

  // Send intel data to the console (for admin use only)
  const goToDebugDetails = () => {
    const id = pathname.split('/')[3];
    history.push(`/debug_details/${id}`);
  }

  const onApplySearch = () => {
    // need to clear this data onnly when date picker is used
    if (activeDistributor.id !== activeDistributorApply.id) {
      dispatch(spinner.add());
      userApi.activeDistributor(activeDistributor.id).post().then(({data}) => {
        dispatch(spinner.subtract());
        dispatch(applySearch());
        const { channelPartner } = data;
        dispatch(setUserData({channelPartner}));
      }).catch(err => {
        dispatch(spinner.subtract());
        console.error(err);
      });
    } else {
      dispatch(applySearch());
    }
  };

  // Reload intel when the distributor is changed (multi house users only)
  useEffect(() => {
    // activeDistributorApply is null on initial load
    if (activeDistributorApply) {
      onApplySearch();
    }

  // eslint-disable-next-line
  }, [activeDistributor]);

  const goBackToMerchants = () => {
    if (viewType.value === 'list') {
      goToList()
    } else if (viewType.value === 'graph') {
      goToGraph();
    } else {
      goToLineChart()
    }
  }
  useEffect(() => {
    if (viewType.value === 'list') {
      goToList()
    } else if (viewType.value === 'graph') {
      goToGraph();
    } else {
      goToLineChart()
    }
  // eslint-disable-next-line
  }, [viewType]);

  useEffect(() => {
    if (graphOrChartSortType.value === 'dcn') {
      dispatch(setListSortByDesc('dcn', false));
    } else if (graphOrChartSortType.value === 'totalVolume') {
      dispatch(setListSortByDesc('totalVolume', true));
    } else if (graphOrChartSortType.value === 'competitorVolume') {
      dispatch(setListSortByDesc('competitorVolume', true));
    } else {
      dispatch(setListSortByDesc('merchant', false));
    }
  // eslint-disable-next-line
  }, [graphOrChartSortType]);

  const insideGraph = pathname === '/intel/graph';
  const insideList = pathname === '/intel/list';
  const insideDetails = pathname.substring(0,13) === '/intel/detail';
  const insideCompetitive = pathname.substr(-11) === 'competitive';

  const listSortOptions = [
    {
      name: 'Customer Name (A-Z)',
      isActive: sortState.sortBy === 'merchant' && sortState.sortDesc,
      click: () => {
        dispatch(setListSortByDesc('merchant', true))
      }
    },
    {
      name: 'Customer Name (Z-A)',
      isActive: sortState.sortBy === 'merchant' && !sortState.sortDesc,
      click: () => {
        dispatch(setListSortByDesc('merchant', false))
      }
    },
    {
      name: 'Total Volume (Low to High)',
      isActive: sortState.sortBy === 'totalVolume' && !sortState.sortDesc,
      click: () => {
        dispatch(setListSortByDesc('totalVolume', false))
      }
    },
    {
      name: 'Total Volume (High to Low)',
      isActive: sortState.sortBy === 'totalVolume' && sortState.sortDesc,
      click: () => {
        dispatch(setListSortByDesc('totalVolume', true))
      }
    },
    {
      name: 'Competitor Volume (Low to High)',
      isActive: sortState.sortBy === 'competitorVolume' && !sortState.sortDesc,
      click: () => {
        dispatch(setListSortByDesc('competitorVolume', false))
      }
    },
    {
      name: 'Competitor Volume (High to Low)',
      isActive: sortState.sortBy === 'competitorVolume' && !sortState.sortDesc,
      click: () => {
        dispatch(setListSortByDesc('competitorVolume', true))
      }
    }
  ];

  const view = insideCompetitive ? 'competitiveSort' : 'distributorSort'

  const competitiveSortOptions = [
    {
      name: 'Price Guidance (Low to High)',
      isActive: sortStateDetail[view].sortBy === 'price' && !sortStateDetail[view].sortDesc,
      click: () => {
        dispatch(setDetailSortBy(view, 'price', false));
      }
    },
    {
      name: 'Price Guidance (High to Low)',
      isActive: sortStateDetail[view].sortBy === 'price' && sortStateDetail[view].sortDesc,
      click: () => {
        dispatch(setDetailSortBy(view, 'price', true));
      }
    },
    {
      name: 'Unit Price Guidance (Low to High)',
      isActive: sortStateDetail[view].sortBy === 'unitPrice' && !sortStateDetail[view].sortDesc,
      click: () => {
        dispatch(setDetailSortBy(view, 'unitPrice', false));
      }
    },
    {
      name: 'Unit Price Guidance (High to Low)',
      isActive: sortStateDetail[view].sortBy === 'unitPrice' && sortStateDetail[view].sortDesc,
      click: () => {
        dispatch(setDetailSortBy(view, 'unitPrice', true));
      }
    }
  ];

  const detailsSortOptions = [
    {
      name: 'Rebate (Low to High)',
      isActive: sortStateDetail[view].sortBy === 'rebate' && !sortStateDetail[view].sortDesc,
      click: () => {
        dispatch(setDetailSortBy(view, 'rebate', false));
      }
    },
    {
      name: 'Rebate (High to Low)',
      isActive: sortStateDetail[view].sortBy === 'rebate' && sortStateDetail[view].sortDesc,
      click: () => {
        dispatch(setDetailSortBy(view, 'rebate', true));
      }
    },
    {
      name: 'Rebate Valid Until (Low to High)',
      isActive: sortStateDetail[view].sortBy === 'rebateExpireDate' && !sortStateDetail[view].sortDesc,
      click: () => {
        dispatch(setDetailSortBy(view, 'rebateExpireDate', false));
      }
    },
    {
      name: 'Rebate Valid Until (High to Low)',
      isActive: sortStateDetail[view].sortBy === 'rebateExpireDate' && sortStateDetail[view].sortDesc,
      click: () => {
        dispatch(setDetailSortBy(view, 'rebateExpireDate', true));
      }
    },
    {
      name: 'Quantity (Low to High)',
      isActive: sortStateDetail[view].sortBy === 'quantity' && !sortStateDetail[view].sortDesc,
      click: () => {
        dispatch(setDetailSortBy(view, 'quantity', false));
      }
    },
    {
      name: 'Quantity (High to Low)',
      isActive: sortStateDetail[view].sortBy === 'quantity' && sortStateDetail[view].sortDesc,
      click: () => {
        dispatch(setDetailSortBy(view, 'quantity', true));
      }
    }
  ];

  const sortBy = () => {
    const modalProps = {
      title : 'Sort By',
      options: insideDetails ? ( insideCompetitive ? [...competitiveSortOptions, ...detailsSortOptions] : detailsSortOptions ) : listSortOptions
    };
    dispatch(showModal(SortByModal, modalProps ));
  };

  const idSelected =  insideDetails ? pathname.split('/')[3] : null;
  const merchantSelected = insideDetails && merchantsLoaded ? merchants.filter(m => m.id === idSelected)[0] : null;
  const merchantName = merchantSelected ? merchantSelected.merchant : '';

  const setDateRange = (d) => {
    cancelIntelApi()
    dispatch(clearCompetitiveOrderHistoryPurchases());
    dispatch(clearOrderHistoryMonths());
    dispatch(setOrderHistoryCalendarDate(d.fromDate));
    dispatch(setIntelDateRange(d));
  }
  return {
    search: {
      onChange: onChange,
      value: search,
      clearValue: clearValue,
      onEnter: onApplySearch
    },
    goToGraph, goToList, goToLineChart,
    goToCompetitivePurchases, goToDistributorPurchases,
    insideGraph, insideList, insideDetails, insideCompetitive,
    merchantName,
    setDateRange,
    toDate,
    fromDate,
    sortBy,
    onApplySearch,
    allLoaded,
    goToDebugDetails,
    goBackToMerchants,
    viewType,
    graphOrChartSortType,
    setGraphOrChartSortType,
    setViewType
  };
};

const useGetEverything = () => {
  const dispatch = useDispatch();
  const fromDate = useSelector(({intel}) => intel.fromDateApply);
  const toDate = useSelector(({intel}) => intel.toDateApply);
  const activeDistributor = useSelector(({user}) => user.activeDistributorApply);

  const getEverything = (merchants) => {
    let s = 0;
    const every = 250;
    const maxS = Math.ceil(merchants.length / every);
    const initReqTs = new Date();

    const loadInParts = () => {
      const params = {
        startDate: moment(fromDate).format('YYYY-MM-DD'),
        endDate: moment(toDate).format('YYYY-MM-DD'),
        merchants: merchants.slice(s*every, s*every+every)
      };
      intelApi.post(params).then(({data}) => {
        dispatch(setMerchantData(data, activeDistributor.id, !(s+1 < maxS)));
      
        s = s+1;
        if (s < maxS) {
          loadInParts();
        } else {
          const finalResTs = new Date();
          logger.push({
            tag: process.env.REACT_APP_LOGGLY_TAG,
            requestName: 'Savings Estimator Timing - Intel',
            timer: finalResTs - initReqTs,
            reqTimestamp: initReqTs,
            resTimestamp: finalResTs,
            endpoint: 'intel'
          });
        }
      }).catch(err => {
        console.error(err);
      });
    };
    loadInParts();
  };

  return getEverything;
};

const useIntelState = () => {
  const dispatch = useDispatch();
  const activeDistributorApply = useSelector(({user}) => user.activeDistributorApply);
  const fromDate = useSelector(({intel}) => intel.fromDateApply);
  const toDate = useSelector(({intel}) => intel.toDateApply);
  const merchants = useSelector(({intel}) => intel.merchantsList);
  const intelVisible = useSelector(({intel}) => intel.intelVisible);
  const dashboardLoading = useSelector(({dashboard}) => dashboard.isLoading);

  const getEverything = useGetEverything();

  const updateNeeded = useRef();

  useEffect(() => {
    if (activeDistributorApply) {
      dispatch(clearMerchants());
      // Load Merchants
      dispatch(spinner.add(false));
      const reqTs = new Date()
      merchantApi.get().then(({data}) => {
        const resTs = new Date();
        logger.push({
          tag: process.env.REACT_APP_LOGGLY_TAG,
          requestName: 'merchants',
          time: resTs - reqTs,
          reqTimestamp: reqTs,
          resTimestamp: resTs
        });
        dispatch(spinner.subtract());
        dispatch(setMerchants(data, activeDistributorApply.id));
        // set these merchants as the default merchants separately,
        // so we can restore them quickly with admin view changes
        dispatch(resetImpersonate(data));
      }).catch(err => {
        dispatch(spinner.subtract());
        console.error(err);
        dispatch(showModal(AlertModal, {title: "We couldn't load the merchant list for this distributor. Please try refreshing the page."}));
      });
    }
  }, [dispatch, activeDistributorApply]);

  useEffect(() => {
    // Marks Intel data as stale, but won't cause an API call just yet
    updateNeeded.current = true;
  }, [fromDate, toDate, merchants, activeDistributorApply]);

  useEffect(() => {
    // Conditional intel loading. Only load intel if:
    // 1.) Dashboard is not loading
    // 2.) Intel is being viewed
    // 3.) Intel data is stale (or hasn't loaded yet this session)
    if (intelVisible && updateNeeded.current && !dashboardLoading) {
      const fromDateMoment = moment(fromDate);
      const toDateMoment = moment(toDate);

      const merchantParams = merchants?.map(m => ({id: m.id, merchant: m.merchant}));

      // Only load intel if date range is less than one year.
      // useDashboard will alert the user if this is not the case.
      if (toDateMoment.diff(fromDateMoment, 'days') < 366) {
        if (merchantParams.length > 0) {
          dispatch(clearMerchantsData());
          getEverything(merchantParams);
          updateNeeded.current = false;
        } 
      }
    }

    // eslint-disable-next-line
  }, [fromDate, toDate, merchants, intelVisible, dashboardLoading]);
};

export default { 
  useTop, useIntelState
};
