const filterArrayByKey = (array, keys) => {
  return array.filter(a => {
    let foundAtLeastOneKey = false;
      for (let i = 0; i < keys.length; i++) {
        // dentro del for
        const k = keys[i];
        if (Array.isArray(k.search)) {
          if (k.search.includes(a[k.key])) {
            foundAtLeastOneKey = true;
          }
        } else {
          if (a[k.key].toLowerCase().search(k.search.toLowerCase()) !== -1) {
            foundAtLeastOneKey = true;
          }
        }
      }
      return foundAtLeastOneKey
  });
};

export default filterArrayByKey;