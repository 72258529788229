import React from 'react';
import { Form, FormGroup, Label, Button, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { selectStyles } from '../../config/constants';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const AdminLoginForm = ({ selectProps, onLogin, onLogout }) => {

  return (
    <Form className="text-size-14" onSubmit={onLogin}>
      <FormGroup>
        <Label className='label-text-header' for='user'>User</Label>
        <Select name='user' styles={selectStyles} {...selectProps} />
      </FormGroup>
      <Row>
        <Col>
          <Button outline onClick={onLogout} block color='beplMagenta' className='py-2 button-label'>Log Out</Button>
        </Col>
        <Col>
          <Button disabled={selectProps.value === null} type='submit' block color='beplMagenta' className='py-2 button-label'>Log In</Button>
        </Col>
      </Row>
      <Row className='m-0 mt-3'>
        <Link className="w-100" to="/admin-dashboard"><Button block color='beplMagenta' className='py-2 button-label'>Admin Dashboard</Button></Link>
      </Row>
    </Form>
  );
};

export default AdminLoginForm;