import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Dashboard from './Dashboard/Dashboard';
import Intel from '../AppMain/Intel/Intel';
import Rebates from '../AppMain/Rebates/Rebates';
import CompetitiveInsight from './CompetitiveInsight/CompetitiveInsight';
import Settings from './Settings/Settings';
import Logout from './Logout/Logout';
import DebugDetails from './Intel/DebugDetails/DebugDetails';
import Invoice from './Invoice/Invoice';
import RebateChecks from './RebateChecks/RebateChecksRouter';
// import Opportunities from './Opportunities/Opportunities';
import { isMobile } from 'react-device-detect';
import Faq from './Faq/Faq';

const AppMainRouter = () => {
  const displayIntel = useSelector(({ user }) => user.displayIntel);
  const isInternalAdmin = useSelector(({ user }) => user.isInternalAdmin);
  const displayInvoice = useSelector(({ user }) => user.displayInvoice);
  const menuExpanded = useSelector(({ui}) => ui.menuExpanded);

  return (
    <div className=" m-0" style={{ transition: menuExpanded ? 'width .3s ease-out .2s' : 'width .3s ease-out', width: isMobile ? '100%' :
     menuExpanded ?  'calc(100% - 250px)' : 'calc(100% - 55px)', paddingTop: isMobile ? '80px' : '0px'}}>
      <Switch>
        <Route exact path='/dashboard' component={Dashboard} />
        {/* <Route exact path='/opportunities' component={Opportunities} /> */}
        {displayIntel && <Route path='/intel' component={Intel} />}
        <Route path='/rebates' component={Rebates} />
        <Route path='/paymentinformation' component={RebateChecks} />
        <Route path='/competitiveinsights' component={CompetitiveInsight} />
        {(isInternalAdmin || displayInvoice) && <Route path='/invoice' component={Invoice} />}
        <Route exact path='/settings' component={Settings} />
        <Route exact path='/faq' component={Faq} />
        <Route exact path='/logout' component={Logout} />
        <Route exact path='/debug_details/:id' component={DebugDetails} />
        <Route>
          <Redirect to='/dashboard' />
        </Route>
      </Switch>
    </div>

  );
};

export default AppMainRouter;