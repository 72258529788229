import React from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col, CardBody, Spinner } from 'reactstrap';

const LoadingRow = () => {
  if (isMobile) {
    return (
      <CardBody className='border-bottom p-3'>
        <Row className='pb-3'>
          <Col className='text-capitalize font-weight-bold text-center'>
            <Spinner color='primary' />
          </Col>
        </Row>
      </CardBody>
    );
  }

  return (
    <CardBody className='border-top'>
      <Row className='align-items-center'>
        <Col className='text-capitalize text-center text-gray'>
          <Spinner color='primary' />
        </Col>
      </Row>
    </CardBody>
  )
};

export default LoadingRow;